import { Injectable, Input } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from '@angular/router';
import { AuthService, UserService } from 'src/swagger-typescript';
import { PathService } from './path.service';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInService {
  /** default login status as BehaviorSubject */
  public loggedInSubject = new BehaviorSubject<boolean>(false);
  /** Observable for login status to subscribe */
  public loggedIn$ = this.loggedInSubject.asObservable();
  /** logged in bool for getLoggedIn method to subscribe loggedIn Observable */
  public isLoggedIn: boolean = false;
  /** role of the logged in user */
  public role: string | undefined = '';

  /** used as return value for observable */
  @Input() error: HttpErrorResponse | undefined = undefined;
  /** Observable Item sources as BehaviorSubject of error */
  private _errorSource = new BehaviorSubject(this.error);
  /** Observable Item stream which converts _errorSource to an Observable (which other Components can subscribe to) */
  errorItem$ = this._errorSource.asObservable();

  /** session timeout to set idle time of 60 minutes */
  private sessionTimeout: number = 3600000; // 60 minutes

  /** timer to calculate expire time */
  private timer!: ReturnType<typeof setTimeout>;
  /** Subject for session which is about to expire */
  private sessionAboutToExpire!: ReturnType<typeof setTimeout>;;
  /** Oberservable to subscribe expired session */
  public sessionAboutToExpire$ = new Subject<void>();

  /** check if app is started */
  public appStarted: boolean = false;

  /** to check every 15 minutes if user is active */
  public lastUserAction: number = Date.now();

  constructor(private generalService: GeneralService, private router: Router, private authService: AuthService, private userService: UserService) { }

  public getLoggedIn(): boolean {
    this.loggedIn$.subscribe((loggedIn: boolean) => {
      this.isLoggedIn = loggedIn;
    });
    return this.isLoggedIn;
  }

  public getAppStarted(): boolean {
    return this.appStarted;
  }

  public setAppStarted(appStarted: boolean) {
    this.appStarted = appStarted;
  }

  public getRole(): string | undefined {
    return this.role;
  }

  public setRole(role: string | undefined) {
    this.role = role;
  }

  // prform logout and clear logged in user, appStarted and session timer
  public logout() {
    this.authService.v1AuthLogoutPost().subscribe({
      next: (v) => { },
      error: (e) => { },
      complete: () => {
        this.generalService.setSodiaphID('');
        this.router.navigate(['']);
      }
    });
    clearTimeout(this.sessionAboutToExpire);
    setTimeout(() => {
      clearTimeout(this.timer);
      this.loggedInSubject.next(false);
      this.setAppStarted(false);
    }, 100); // wait 100ms before clearing timer and emitting login
    this.generalService.setUser('');
  }

  public setError(error: HttpErrorResponse) {
    this.error = error;
    this._errorSource.next(this.error);
  }

  // start session to auto logout after 60 minutes
  public startSessionTimer() {
    this.timer = setTimeout(() => {
      this.sessionAboutToExpire = setTimeout(() => {
        this.sessionAboutToExpire$.next();
        setTimeout(() => {
          this.logout();
        }, 5000); // logout after 5 seconds
      }, 10000); // alert 10 seconds before session expires
    }, this.sessionTimeout - 10000);
  }

  // reset session 
  public resetSessionTimer() {
    clearTimeout(this.timer);
    clearTimeout(this.sessionAboutToExpire);
    this.startSessionTimer();
  }

}