import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { MathJaxService } from '../math-jax.service';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CustomTooltipComponent } from '../custom-tooltip/custom-tooltip.component';

@Component({
  selector: 'app-movable-dialog',
  templateUrl: './movable-dialog.component.html',
  styleUrls: ['./movable-dialog.component.css']
})
export class MovableDialogComponent {
  private overlayRef!: OverlayRef;

  @ViewChild('infoButton', { static: false, read: ElementRef })
  infoButton!: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<MovableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private overlay: Overlay
  ) { }

  closeDialog(): void {
    this.dialogRef.close();
  }

  startDrag(event: MouseEvent): void {
    const dialog = this.dialogRef;
    const startX = event.clientX;
    const startY = event.clientY;
    const rect = dialog.componentInstance.dialogRef['_containerInstance']['_elementRef'].nativeElement.getBoundingClientRect();
    const startLeft = rect.left;
    const startTop = rect.top;

    const onMouseMove = (moveEvent: MouseEvent) => {
      const newLeft = startLeft + moveEvent.clientX - startX;
      const newTop = startTop + moveEvent.clientY - startY;
      dialog.updatePosition({ top: `${newTop}px`, left: `${newLeft}px` });
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  showTooltip() {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(this.infoButton)
      .withPositions([{
        originX: 'end',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }]);

    this.overlayRef = this.overlay.create({ positionStrategy });

    const tooltipPortal = new ComponentPortal(CustomTooltipComponent);
    const tooltipRef = this.overlayRef.attach(tooltipPortal);
    tooltipRef.instance.mathString = this.data.tooltip;
  }

  hideTooltip() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}