/**
 * Sodiaph Backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DiagnosisCreateRequest { 
    code?: string;
    type?: DiagnosisCreateRequest.TypeEnum;
    isMissingInDischargeLetter?: boolean;
}
export namespace DiagnosisCreateRequest {
    export type TypeEnum = 'ADMISSION' | 'MAIN' | 'SIDE';
    export const TypeEnum = {
        ADMISSION: 'ADMISSION' as TypeEnum,
        MAIN: 'MAIN' as TypeEnum,
        SIDE: 'SIDE' as TypeEnum
    };
}