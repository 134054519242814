import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../general.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';

import { PageEvent } from '@angular/material/paginator';
import { MandantDTO, MandantService, UserService } from 'src/swagger-typescript';

@Component({
  selector: 'app-user-organization',
  templateUrl: './user-organization.component.html',
  styleUrls: ['./user-organization.component.css']
})
export class UserOrganizationComponent implements OnInit {

  mandanten = [] as Array <MandantDTO>;

  displayedColumns: string[] = ['name', 'expires', 'created'];
  dataSource!: MatTableDataSource<MandantDTO>;
  pipe = new DatePipe('de-DE');
  username: string | undefined = '';
  role: string | undefined = '';
  id: string | undefined = '';

  @ViewChild(MatSort)
  sort: MatSort = new MatSort;

  constructor(private mandantService: MandantService, private userService: UserService, public router: Router, private route: ActivatedRoute, private generalService: GeneralService, private liveAnnouncer: LiveAnnouncer) {
  }

  ngOnInit(): void {
    this.generalService.setTitle('Benutzerorganisationen verwalten');
    this.generalService.setPathButtonVisible(false);

    this.userService.v1UserOwnGet().subscribe({
      next: (v) => {
        this.username = v.username;
        this.role = v.role;
      },
      error: (e) => { console.error(e) },
      complete: () => { }
    });

    this.dataSource = new MatTableDataSource<MandantDTO>([]);
    this.mandantService.v1MandantGet().subscribe({
      next: (v) => {
        this.mandanten = v;
        this.dataSource.data = this.mandanten;
        this.dataSource.sort = this.sort;
      },
      error: (e) => { console.error(e) },
      complete: () => { }
    });

  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this.liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this.liveAnnouncer.announce('Sorting cleared');
    }
  }

  onPageChange(event: PageEvent) {
    const startIndex = event.pageIndex * event.pageSize;
    const endIndex = startIndex + event.pageSize;
    this.dataSource.data = this.mandanten.slice(startIndex, endIndex);
  }

  loadMandant(mandant: any) {
    this.generalService.isNewProfileSubject.next(false);
    const data = {
      id: mandant.id,
      created: mandant.created, expires: mandant.expires, name: mandant.name, accountPrefix: mandant.accountPrefix,
      street: mandant.street, streetNumber: mandant.streetNumber, zip: mandant.zip, city: mandant.city, legalForm: mandant.legalForm,
      state: mandant.state, userCount: mandant.userCount
    };
    this.generalService.setData(data);
    this.router.navigate(['create-user-account']);
  }

  onNewProfileClick(): void {
    this.generalService.isNewProfileSubject.next(true);
  }

}