<div style="display: flex;">
        <mat-card class="custom-outline-card" style="flex: 1; margin: 5%; margin-top: 2%; max-width: 400px;">
                <div class="custom-outline-label">Datenanalysen</div>
                <mat-card-content style="display: flex; flex-direction: column;">
                        <mat-label>Analyse-Auswahl</mat-label>
                        <mat-form-field style="margin-bottom: 5%;">
                                <mat-select [(ngModel)]="selectedAnalysis">
                                        <mat-option
                                                value="target_interoperability_index_vs_origin_interoperability_index"
                                                matTooltip="Streudiagramm zur Gesamtbetrachtung von Datenqualität und Datenübertragung">Digitalisierungsgrad
                                                Ursprung-Ziel</mat-option>

                                        <mat-option value="target_interoperability_index"
                                                matTooltip="Verteilung als Boxplot zur Gesamtbetrachtung von Datenqualität und Datenübertragung">Digitalisierungsgrad
                                                Ziel</mat-option>

                                        <mat-option value="origin_interoperability_index"
                                                matTooltip="Verteilung als Boxplot zur Gesamtbetrachtung von Datenqualität und Datenübertragung">Digitalisierungsgrad
                                                Ursprung</mat-option>

                                        <mat-option value="transformation_index"
                                                matTooltip="Streudiagramm zur durchschnittlichen Veränderung der Datenqualität">Durchschnittliche
                                                Datentransformation Ursprung-Ziel</mat-option>

                                        <mat-option value="transformation_score"
                                                matTooltip="Dichteverteilung der einzelnen Werte zur Veränderung der Datenqualität">Einzelaktivität
                                                Datentransformation Ursprung-Ziel</mat-option>

                                        <mat-option value="target_quality_index_vs_origin_quality_index"
                                                matTooltip="Streudiagramm zur durchschnittlichen Strukturiertheit der Daten">Durchschnittliche
                                                Datenqualität Ursprung-Ziel</mat-option>

                                        <mat-option value="origin_quality_index_vs_transmission_index"
                                                matTooltip="Streudiagramm zur durchschnittlichen Strukturiertheit der Daten und der Art des Datentransfers">Durchschnittliche
                                                Datenqualität
                                                Ursprung-Datenübertragung</mat-option>

                                        <mat-option value="transmission_index"
                                                matTooltip="Verteilung als Boxplot zur Art des Datentransfers">Durchschnittliche
                                                Datenübertragung</mat-option>

                                        <mat-option value="DATENÜBERTRAGUNG"
                                                matTooltip="Säulendiagramm zur Häufigkeit der Arten des Datentransfers">Einzelaktivität
                                                Datenübertragung</mat-option>

                                        <mat-option value="gender"
                                                matTooltip="Säulendiagramm zur Häufigkeit der Geschlechter unter den Patient*innen">Geschlecht</mat-option>

                                        <mat-option value="age_group"
                                                matTooltip="Säulendiagramm zur Häufigkeit von Altersgruppen unter den Patient*innen">Altersgruppe</mat-option>
                                </mat-select>
                        </mat-form-field>

                        <mat-label>Klinik</mat-label>
                        <mat-form-field class="example-chip-list">
                                <mat-chip-grid #chipGrid aria-label="Clinic selection">
                                        <mat-chip-row *ngFor="let clinic of clinics;" (removed)="remove(clinic)">
                                                {{clinic}}
                                                <button matChipRemove [attr.aria-label]="'remove ' + clinic">
                                                        <mat-icon>cancel</mat-icon>
                                                </button>
                                        </mat-chip-row>
                                </mat-chip-grid>
                                <input #clinicInput [formControl]="clinicCtrl" [matChipInputFor]="chipGrid"
                                        [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        (matChipInputTokenEnd)="add($event)" />
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                        <mat-option *ngFor="let clinic of filteredCLinics$ | async;"
                                                [value]="clinic">{{clinic}}</mat-option>
                                </mat-autocomplete>
                        </mat-form-field>

                        <mat-radio-group [(ngModel)]="aggregate" (change)="onRadioChange($event)">
                                <mat-radio-button
                                        matTooltip="Daten derselben Klinik werden zusammengefasst oder gleich eingefärbt"
                                        value="Klinik" [hidden]="isDATENUEBERTRAGUNG()">Unterscheidung nach
                                        Klinik</mat-radio-button>
                        </mat-radio-group>

                        <mat-label>Standort</mat-label>
                        <mat-form-field>
                                <mat-select (ngModelChange)="onLocationSelected()" [(ngModel)]="selectedLocations"
                                        multiple>
                                        <mat-option (click)="toggleSelectAll()" [value]="'selectAll'">
                                                {{ selectAllLocaltionsLabel }}
                                        </mat-option>
                                        <mat-option *ngFor="let location_name of location_names"
                                                [value]="location_name">
                                                {{location_name}}
                                        </mat-option>
                                </mat-select>
                        </mat-form-field>

                        <mat-radio-group [(ngModel)]="aggregate" (change)="onRadioChange($event)">
                                <mat-radio-button
                                        matTooltip="Daten desselben Standorts werden zusammengefasst oder gleich eingefärbt"
                                        value="primary_location" [hidden]="isDATENUEBERTRAGUNG()">Unterscheidung nach
                                        Standort</mat-radio-button>
                        </mat-radio-group>

                        <mat-label>Stationstyp</mat-label>
                        <mat-form-field>
                                <mat-select (ngModelChange)="onUnitTypeSelected()" [(ngModel)]="selectedUnitTypes"
                                        multiple>
                                        <mat-option (click)="toggleSelectAllUnitTypes()" [value]="'selectAllUnitTypes'">
                                                {{ selectAllUnitTypesLabel }}
                                        </mat-option>
                                        <mat-option *ngFor="let unit_type of unit_types" [value]="unit_type">
                                                {{unit_type}}
                                        </mat-option>
                                </mat-select>
                        </mat-form-field>

                        <mat-radio-group [(ngModel)]="aggregate" (change)="onRadioChange($event)">
                                <mat-radio-button
                                        matTooltip="Daten desselben Stationstyps werden zusammengefasst oder gleich eingefärbt"
                                        value="primary_unit">Unterscheidung nach
                                        Stationstyp</mat-radio-button>
                        </mat-radio-group>

                        <mat-label>ICD-10 Codes</mat-label>
                        <mat-form-field>
                                <mat-select [(ngModel)]="selectedCodes" multiple>
                                        <mat-option (click)="toggleSelectAllCodes()" [value]="'selectAllCodes'">
                                                {{ selectAllCodesLabel }}
                                        </mat-option>
                                        <mat-option *ngFor="let icd_code of icd_codes" [value]="icd_code">
                                                {{icd_code}}
                                        </mat-option>
                                </mat-select>
                        </mat-form-field>
                        <mat-radio-group [(ngModel)]="aggregate" (change)="onRadioChange($event)">
                                <mat-radio-button
                                        matTooltip="Daten mit derselben Haupt- oder Aufnahmediagnose werden zusammengefasst oder gleich eingefärbt"
                                        value="Hauptdiagnose" [hidden]="isDATENUEBERTRAGUNG()">Unterscheidung
                                        nach ICD-10
                                        Codes</mat-radio-button>
                        </mat-radio-group>

                        <mat-radio-group [(ngModel)]="aggregate" (change)="onRadioChange($event)">
                                <mat-radio-button
                                        matTooltip="Daten zu Patient*innen desselben Geschlechts werden zusammengefasst oder gleich eingefärbt"
                                        value="gender"
                                        [hidden]="isGenderSelected() || isDATENUEBERTRAGUNG()">Unterscheidung nach
                                        Geschlecht</mat-radio-button>
                        </mat-radio-group>

                        <mat-radio-group [(ngModel)]="aggregate" (change)="onRadioChange($event)">
                                <mat-radio-button
                                        matTooltip="Daten zu Patient*innen derselben Altergruppe werden zusammengefasst oder gleich eingefärbt"
                                        value="age_group"
                                        [hidden]="isAgeSelected() || isDATENUEBERTRAGUNG()">Unterscheidung nach
                                        Altersgruppe</mat-radio-button>
                        </mat-radio-group>

                </mat-card-content>
                <mat-card-actions>
                        <button class="button_individual"
                                style="margin-left: 3%; margin-bottom: 4%; font-size: x-small; width: 30%; padding: 2%;"
                                mat-button (click)="resetFilters()">FILTER RESET</button>
                        <button class="button_individual"
                                style="margin-left: 35%; font-size: x-small; width: 30%; padding: 2%; margin-bottom: 4%;"
                                mat-button (click)="addAnalysis();">ANALYSE AUSFÜHREN</button>
                </mat-card-actions>
        </mat-card>

        <mat-card id="summary-card" style="flex: 1; margin-top: 2%; margin-right: 5%; margin-bottom: 5%;">
                <mat-card-content id="summary-card-content" style="overflow-y: auto; overflow-x: auto; color: #6b8294;">
                        <div *ngFor="let analysis of analyses; let i = index">
                                <div (click)="onAnalysisClick(i)">
                                        <div style="font-weight: bold;">
                                                Analyse {{ i + 1 }} - {{ getAnalysisName(analysis.selectedAnalysis) }}
                                        </div>
                                        Kliniken: {{ analysis.clinics }} <br>
                                        Standorte: {{ analysis.selectedLocations }} <br>
                                        ICD-10 Codes: {{ analysis.selectedCodes }} <br>
                                        Stationstyp: {{ analysis.selectedUnitTypes }} <br>
                                        Farbliche Hervorhebung nach: {{getHighlightingName(analysis.aggregate)}}<br><br>
                                </div>
                                <button style="background-color: #6b8294; font-size: x-small; width: 15%; padding: 2%; margin-left: 85%;"
                                        mat-button (click)="downloadSpec(i)">DOWNLOAD SPEC</button>
                                <hr>
                        </div>
                </mat-card-content>
        </mat-card>

</div>