/**
 * Sodiaph Backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Mandant } from './mandant';

export interface User { 
    id?: string;
    created?: string;
    username?: string;
    password?: string;
    role?: User.RoleEnum;
    mandant?: Mandant;
}
export namespace User {
    export type RoleEnum = 'mandant' | 'admin' | 'steward';
    export const RoleEnum = {
        Mandant: 'mandant' as RoleEnum,
        Admin: 'admin' as RoleEnum,
        Steward: 'steward' as RoleEnum
    };
}