import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../general.service';
import { EcmDocumentGroup, EcmDocumentSource, EcmDocumentType, EcmDocumentTypeDTO, EcmEntry, EcmService, PatientArchiveCreateRequest, PatientArchiveDTO, PatientArchiveService, PatientArchiveUpdateRequest, PatientPathDTO, PatientPathService, TreatmentUnitDTO } from 'src/swagger-typescript';
import { PathService } from '../path.service';
import { DatePipe } from '@angular/common';
import { FormControl, NgForm } from '@angular/forms';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { Observable, map, startWith } from 'rxjs';

@Component({
  selector: 'app-new-patient-archive-document',
  templateUrl: './new-patient-archive-document.component.html',
  styleUrls: ['./new-patient-archive-document.component.css']
})
export class NewPatientArchiveDocumentComponent {

  /** ecmDocumentType mat-autocomplete */
  public ecmDocumentTypeCtrl = new FormControl();
  public ecmDocumentTypefilteredOptions!: Observable<EcmDocumentType[]>;
  public ecmDocumentTypeName: string | undefined = '';

  /** ecmDocumentSource mat-autocomplete */
  public ecmDocumentSourceCtrl = new FormControl();
  public ecmDocumentSourcefilteredOptions!: Observable<EcmDocumentSource[]>;
  public ecmDocumentSourceName: string | undefined = '';

  /** date pipe to format date */
  pipe = new DatePipe('de-DE');

  public sfids: string[] = [];

  /** empty ecmEntry object */
  public ecmEntry: PatientArchiveDTO;

  /** EcmEntry to share EcmEntry between patient-archive and new-patient-archive-document */
  public data: PatientArchiveDTO;

  private ecmEntryId: string = '';

  /** data for dropdowns */
  public ecmDocumentGroups = [] as Array<EcmDocumentGroup>;
  public ecmDocumentTypes = [] as Array<EcmDocumentTypeDTO>;
  public ecmDocumentSources = [] as Array<EcmDocumentSource>;

  isPrinted = [true, false]

  @ViewChild('documentForm')
  documentForm!: NgForm;

  showPopup: boolean = false;
  popupText: string = '';

  /** array of PatientPathDTO to share path */
  private dataPath: PatientPathDTO[] = [];

  /** empty path object */
  public path: any = {};

  private patientPathId: string = '';

  public isOtherDocumentType: boolean = false;
  public isDocumentTypeLoaded: boolean = false;

  public datesForSFID: string[] = [];

  public latestDate: string = '';

  public admissionStart: Date = new Date();
  public admissionEnd: Date = new Date();

  /** array of TreatmentUnitDTO, used to show treatment unit admission dates */
  public pathUnitsDTO: TreatmentUnitDTO[] = [];

  constructor(private patientArchiveService: PatientArchiveService,
    private ownPathService: PathService,
    private patientPathService: PatientPathService,
    private ecmService: EcmService,
    public router: Router,
    private generalService: GeneralService, private elementRef: ElementRef) {

    this.ecmEntry = {
      id: '',
      ecmDocumentTypeId: '',
      ecmDocumentTypeAddition: '',
      ecmDocumentGroupId: '',
      ecmDocumentSourceId: '',
      SFID: '',
      isNotArchived: false,
      archiveDate: '',
      isArchiveDateMissing: false,
      isArchiveDateBeforeAdmission: false,
      numPages: 1,
      isPrinted: false,
      ecmDocumentTypeName: '',
    };
    this.data = Object.assign(this.ecmEntry);
  }

  ngOnInit(): void {

    this.ecmDocumentTypefilteredOptions = this.ecmDocumentTypeCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterEcmDocumentType(value || '')),
    );

    this.ecmDocumentSourcefilteredOptions = this.ecmDocumentSourceCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterecmDocumentSource(value || '')),
    );

    this.generalService.setTitle('Dokument anlegen');
    this.ecmDocumentSources = [];

    // load existing sfids
    this.ownPathService.getSFIDs().forEach(sfid => {
      this.sfids.push(sfid)
    });

    this.ecmService.v1EcmEcmDocumentGroupGet().subscribe(data => {
      this.ecmDocumentGroups = data;
    });

    this.ecmService.v1EcmEcmDocumentSourceGet().subscribe(data => {
      this.ecmDocumentSources = data;
    });

    // load data if path on table was clicked
    this.dataPath = this.ownPathService.getPath();
    if (this.dataPath) {
      this.path = this.dataPath;
      if (this.path.id) {
        this.patientPathId = this.path.id;
        // once the patient path is set and data is loaded, load the treatment unit data
        this.loadTreatmentUnitData();
        ('path id: ' + this.patientPathId);
      }
    }

    // set sfid that was selected
    this.ownPathService.selectedOption$.subscribe(option => {
      if (option == "") {
        this.ecmEntry.SFID = this.sfids[0];
      } else {
        this.ecmEntry.SFID = option;
      }
    });

    // get all dates for this sfid
    this.datesForSFID = this.ownPathService.getDatesForSpecificSFID();
    if (this.datesForSFID.length > 0) {
      // Find the latest date from the array
      this.latestDate = this.datesForSFID.reduce((latest, current) => (current > latest ? current : latest));
      this.ecmEntry.archiveDate = this.pipe.transform(this.latestDate, 'yyyy-MM-dd') || '';
    }

    //check if add new document button was clicked
    if (this.ownPathService.getisNewDocument()) {
      return;
    }

    // load data if document on table was clicked
    this.data = this.ownPathService.getEcmEntry();
    if (this.data) {
      this.ecmEntry = this.data;
      this.ecmEntry.archiveDate = this.pipe.transform(this.ecmEntry.archiveDate, 'yyyy-MM-dd') || '';
      if (this.ecmEntry.id) {
        this.ecmEntryId = this.ecmEntry.id;

        //set document name
        this.ecmDocumentTypeName = this.ecmEntry.ecmDocumentTypeName;
        this.ecmDocumentTypeCtrl.setValue(this.ecmDocumentTypeName);

        //set his document source
        this.ecmService.v1EcmEcmDocumentSourceGet().subscribe({
          next: (v) => {
            const ecmDocumentSourceId = this.ecmEntry.ecmDocumentSourceId;
            const ecmDocumentSource = v.find(ecmDocSource =>
              ecmDocSource.id == ecmDocumentSourceId
            );
            if (ecmDocumentSource) {
              this.ecmDocumentSourceName = ecmDocumentSource.name;
              this.ecmDocumentSourceCtrl.setValue(this.ecmDocumentSourceName);
            } else {
              // Handle the case where no matching ecmDocumentSource is found
              console.error(`No matching ecmDocumentSource found for id: ${ecmDocumentSourceId}`);
            }

          },
          error: (e) => { console.error(e) },
          complete: () => { }
        });

      }

      if (this.ecmEntry.ecmDocumentTypeAddition) {
        this.isOtherDocumentType = true;
      }

      // 404 not found clicking on edit pencil
      if (!this.ecmEntry.ecmDocumentGroupId) {
        return;
      }

      this.ecmService.v1EcmEcmDocumentTypeFilterByEcmDocumentGroupEcmDocumentGroupIdGet(this.ecmEntry.ecmDocumentGroupId || '').subscribe(data => {
        this.ecmDocumentTypes = data;
      });
    }
  }

  ngOnDestroy() {
    // reset archive dates
    this.ownPathService.resetDatesForSpecificSFID();
  }

  private _filterEcmDocumentType(value: string): EcmDocumentType[] {
    const filterValue = value.toLowerCase();
    return this.ecmDocumentTypes.filter(ecmDocumentType => ecmDocumentType.name?.toLowerCase().includes(filterValue));
  }

  private _filterecmDocumentSource(value: string): EcmDocumentSource[] {
    const filterValue = value.toLowerCase();
    return this.ecmDocumentSources.filter(ecmDocumentSource => ecmDocumentSource.name?.toLowerCase().includes(filterValue));
  }

  loadTreatmentUnitData() {
    this.patientPathService.v1PatientPathPatientPathIdTreatmentUnitGet(this.patientPathId).subscribe((data: TreatmentUnitDTO[]) => {
      this.pathUnitsDTO = data;
      this.setAdmissionDateRange();
    });
  }

  onEcmDocumentGroupIdSelected() {
    this.ecmDocumentTypeName = '';
    this.ecmDocumentTypeCtrl.setValue(this.ecmDocumentTypeName);
    this.ecmEntry.ecmDocumentTypeId = '';

    this.ecmEntry.ecmDocumentTypeAddition = '';
    this.isOtherDocumentType = false;
    this.ecmDocumentTypes = [];
    this.ecmService.v1EcmEcmDocumentTypeFilterByEcmDocumentGroupEcmDocumentGroupIdGet(this.ecmEntry.ecmDocumentGroupId || '').subscribe(data => {
      this.ecmDocumentTypes = data;
      this.isDocumentTypeLoaded = true;
    });
  }

  checkOtherDocumentType() {
    if (this.isDocumentTypeLoaded || this.ecmEntry) {
      this.isOtherDocumentType = false;

      // create Object ecmDocumentType
      const ecmDocumentTypeForm = this.ecmDocumentTypeCtrl.value;
      const ecmDocumentType = this.ecmDocumentTypes.find(option => option.name === ecmDocumentTypeForm);
      const selectedDocumentType = ecmDocumentType;

      const desiredDocumentTypeName = 'anderer Dokumententyp';
      if (selectedDocumentType?.name === desiredDocumentTypeName) {
        this.isOtherDocumentType = true;
      } else {
        this.ecmEntry.ecmDocumentTypeAddition = '';
        this.isOtherDocumentType = false;
      }
    }
  }

  saveDocumentAndNavToOverview() {
    // update document
    if (this.ecmEntry && !this.ownPathService.getisNewDocument()) {

      const sfidForm = this.documentForm.value.SFID;

      // create Object ecmDocumentType
      const ecmDocumentTypeForm = this.ecmDocumentTypeCtrl.value;
      const ecmDocumentType = this.ecmDocumentTypes.find(option => option.name === ecmDocumentTypeForm);
      const ecmDocumentTypeId = ecmDocumentType?.id;

      const ecmDocumentTypeAdditionForm = this.documentForm.value.ecmDocumentTypeAddition;

      // create Object ecmDocumentSource
      const ecmDocumentSourceForm = this.ecmDocumentSourceCtrl.value;
      const ecmDocumentSource = this.ecmDocumentSources.find(option => option.name === ecmDocumentSourceForm);
      const ecmDocumentSourceId = ecmDocumentSource?.id;

      const isNotArchivedForm = this.documentForm.value.isNotArchived;
      const archiveDateForm = this.documentForm.value.archiveDate;
      const isArchiveDateMissingForm = this.documentForm.value.isArchiveDateMissing;
      const isArchiveDateBeforeAdmissionForm = this.documentForm.value.isArchiveDateBeforeAdmission;
      const numPagesForm = this.documentForm.value.numPages;
      const isPrintedForm = this.documentForm.value.isPrinted;

      const request: PatientArchiveUpdateRequest = {
        SFID: sfidForm,
        ecmDocumentTypeId: isNotArchivedForm ? undefined : ecmDocumentTypeId,
        ecmDocumentTypeAddition: ecmDocumentTypeAdditionForm,
        ecmDocumentSourceId: isNotArchivedForm ? undefined : ecmDocumentSourceId,
        isNotArchived: isNotArchivedForm,
        archiveDate: isNotArchivedForm || isArchiveDateMissingForm || isArchiveDateBeforeAdmissionForm ? null : archiveDateForm,
        isArchiveDateMissing: isArchiveDateMissingForm,
        isArchiveDateBeforeAdmission: isArchiveDateBeforeAdmissionForm,
        numPages: isNotArchivedForm ? null : numPagesForm,
        isPrinted: isNotArchivedForm ? null : isPrintedForm,
      }

      this.patientArchiveService.v1PatientPathPatientPathIdPatientArchivePatientArchiveIdPut(request, this.patientPathId, this.ecmEntryId).subscribe({
        next: (v) => { },
        error: (e) => {
          let errorMessage = 'An error occurred.';
          if (e.error && e.error.errors && e.error.errors.length > 0) {
            errorMessage = e.error.errors[0].message;
          }
          this.popupText = 'Error: ' + e.status + ' ' + errorMessage;
          this.showPopup = true;
          console.error("v1PatientPathPatientPathIdPatientArchivePatientArchiveIdPut " + e)
        },
        complete: () => { this.router.navigate(['./patient-archive']); }
      });

      /*****************************************************POST******************************************************************/

    } else {

      const sfidForm = this.documentForm.value.SFID;

      // create Object ecmDocumentType
      const ecmDocumentTypeForm = this.ecmDocumentTypeCtrl.value;
      const ecmDocumentType = this.ecmDocumentTypes.find(option => option.name === ecmDocumentTypeForm);
      const ecmDocumentTypeId = ecmDocumentType?.id;

      const ecmDocumentTypeAdditionForm = this.documentForm.value.ecmDocumentTypeAddition;

      // create Object ecmDocumentSource
      const ecmDocumentSourceForm = this.ecmDocumentSourceCtrl.value;
      const ecmDocumentSource = this.ecmDocumentSources.find(option => option.name === ecmDocumentSourceForm);
      const ecmDocumentSourceId = ecmDocumentSource?.id;

      const isNotArchivedForm = this.documentForm.value.isNotArchived;
      const archiveDateForm = this.documentForm.value.archiveDate;
      const isArchiveDateMissingForm = this.documentForm.value.isArchiveDateMissing;
      const isArchiveDateBeforeAdmissionForm = this.documentForm.value.isArchiveDateBeforeAdmission;
      const numPagesForm = this.documentForm.value.numPages;
      const isPrintedForm = this.documentForm.value.isPrinted;

      const request: PatientArchiveCreateRequest = {
        SFID: sfidForm,
        ecmDocumentTypeId: isNotArchivedForm ? undefined : ecmDocumentTypeId,
        ecmDocumentTypeAddition: ecmDocumentTypeAdditionForm,
        ecmDocumentSourceId: isNotArchivedForm ? undefined : ecmDocumentSourceId,
        isNotArchived: isNotArchivedForm,
        archiveDate: isNotArchivedForm || isArchiveDateMissingForm || isArchiveDateBeforeAdmissionForm ? null : archiveDateForm,
        isArchiveDateMissing: isArchiveDateMissingForm,
        isArchiveDateBeforeAdmission: isArchiveDateBeforeAdmissionForm,
        numPages: isNotArchivedForm ? null : numPagesForm,
        isPrinted: isNotArchivedForm ? null : isPrintedForm,
      }

      this.patientArchiveService.v1PatientPathPatientPathIdPatientArchivePost(request, this.patientPathId).subscribe({
        next: (v) => {
        },
        error: (e) => {
          let errorMessage = 'An error occurred.';
          if (e.error && e.error.errors && e.error.errors.length > 0) {
            errorMessage = e.error.errors[0].message;
          }
          this.popupText = 'Error: ' + e.status + ' ' + errorMessage;
          this.showPopup = true;
          console.error("v1PatientPathPatientPathIdPatientArchivePost " + e)
        },
        complete: () => {
          this.router.navigate(['./patient-archive']);
        }
      });

    }

  }

  saveDocumentAndCreateNewDocument() {

    // update document
    if (this.ecmEntry && !this.ownPathService.getisNewDocument()) {

      const sfidForm = this.documentForm.value.SFID;

      // create Object ecmDocumentType
      const ecmDocumentTypeForm = this.ecmDocumentTypeCtrl.value;
      const ecmDocumentType = this.ecmDocumentTypes.find(option => option.name === ecmDocumentTypeForm);
      const ecmDocumentTypeId = ecmDocumentType?.id;

      const ecmDocumentTypeAdditionForm = this.documentForm.value.ecmDocumentTypeAddition;

      // create Object ecmDocumentSource
      const ecmDocumentSourceForm = this.ecmDocumentSourceCtrl.value;
      const ecmDocumentSource = this.ecmDocumentSources.find(option => option.name === ecmDocumentSourceForm);
      const ecmDocumentSourceId = ecmDocumentSource?.id;

      const isNotArchivedForm = this.documentForm.value.isNotArchived;
      const archiveDateForm = this.documentForm.value.archiveDate;
      const isArchiveDateMissingForm = this.documentForm.value.isArchiveDateMissing;
      const isArchiveDateBeforeAdmissionForm = this.documentForm.value.isArchiveDateBeforeAdmission;
      const numPagesForm = this.documentForm.value.numPages;
      const isPrintedForm = this.documentForm.value.isPrinted;

      const request: PatientArchiveUpdateRequest = {
        SFID: sfidForm,
        ecmDocumentTypeId: isNotArchivedForm ? undefined : ecmDocumentTypeId,
        ecmDocumentTypeAddition: ecmDocumentTypeAdditionForm,
        ecmDocumentSourceId: isNotArchivedForm ? undefined : ecmDocumentSourceId,
        isNotArchived: isNotArchivedForm,
        archiveDate: isNotArchivedForm || isArchiveDateMissingForm || isArchiveDateBeforeAdmissionForm ? null : archiveDateForm,
        isArchiveDateMissing: isArchiveDateMissingForm,
        isArchiveDateBeforeAdmission: isArchiveDateBeforeAdmissionForm,
        numPages: isNotArchivedForm ? null : numPagesForm,
        isPrinted: isNotArchivedForm ? null : isPrintedForm,
      }

      this.patientArchiveService.v1PatientPathPatientPathIdPatientArchivePatientArchiveIdPut(request, this.patientPathId, this.ecmEntryId).subscribe({
        next: (v) => { },
        error: (e) => {
          let errorMessage = 'An error occurred.';
          if (e.error && e.error.errors && e.error.errors.length > 0) {
            errorMessage = e.error.errors[0].message;
          }
          this.popupText = 'Error: ' + e.status + ' ' + errorMessage;
          this.showPopup = true;
          console.error("v1PatientPathPatientPathIdPatientArchivePatientArchiveIdPut " + e)
        },
        complete: () => {
          this.ownPathService.isNewDocumentSubject.next(true);

          this.ecmDocumentTypeName = '';
          this.ecmDocumentTypeCtrl.setValue(this.ecmDocumentTypeName);

          this.ecmDocumentSourceName = '';
          this.ecmDocumentSourceCtrl.setValue(this.ecmDocumentSourceName);

          this.ecmEntry = {
            id: '',
            ecmDocumentTypeId: '',
            ecmDocumentTypeAddition: '',
            ecmDocumentGroupId: '',
            ecmDocumentSourceId: '',
            SFID: this.ecmEntry.SFID,
            isNotArchived: false,
            archiveDate: this.pipe.transform(this.ecmEntry.archiveDate, 'yyyy-MM-dd') || '',
            isArchiveDateMissing: false,
            isArchiveDateBeforeAdmission: false,
            numPages: 1,
            isPrinted: false,
            ecmDocumentTypeName: '',
          };
          this.router.navigate(['./new-patient-archive-document']);
        }
      });

      /*****************************************************POST******************************************************************/

    } else {

      const sfidForm = this.documentForm.value.SFID;

      // create Object ecmDocumentType
      const ecmDocumentTypeForm = this.ecmDocumentTypeCtrl.value;
      const ecmDocumentType = this.ecmDocumentTypes.find(option => option.name === ecmDocumentTypeForm);
      const ecmDocumentTypeId = ecmDocumentType?.id;

      const ecmDocumentTypeAdditionForm = this.documentForm.value.ecmDocumentTypeAddition;

      // create Object ecmDocumentSource
      const ecmDocumentSourceForm = this.ecmDocumentSourceCtrl.value;
      const ecmDocumentSource = this.ecmDocumentSources.find(option => option.name === ecmDocumentSourceForm);
      const ecmDocumentSourceId = ecmDocumentSource?.id; const isNotArchivedForm = this.documentForm.value.isNotArchived;

      const archiveDateForm = this.documentForm.value.archiveDate;
      const isArchiveDateMissingForm = this.documentForm.value.isArchiveDateMissing;
      const isArchiveDateBeforeAdmissionForm = this.documentForm.value.isArchiveDateBeforeAdmission;
      const numPagesForm = this.documentForm.value.numPages;
      const isPrintedForm = this.documentForm.value.isPrinted;

      const request: PatientArchiveCreateRequest = {
        SFID: sfidForm,
        ecmDocumentTypeId: isNotArchivedForm ? undefined : ecmDocumentTypeId,
        ecmDocumentTypeAddition: ecmDocumentTypeAdditionForm,
        ecmDocumentSourceId: isNotArchivedForm ? undefined : ecmDocumentSourceId,
        isNotArchived: isNotArchivedForm,
        archiveDate: isNotArchivedForm || isArchiveDateMissingForm || isArchiveDateBeforeAdmissionForm ? null : archiveDateForm,
        isArchiveDateMissing: isArchiveDateMissingForm,
        isArchiveDateBeforeAdmission: isArchiveDateBeforeAdmissionForm,
        numPages: isNotArchivedForm ? null : numPagesForm,
        isPrinted: isNotArchivedForm ? null : isPrintedForm,
      }

      this.patientArchiveService.v1PatientPathPatientPathIdPatientArchivePost(request, this.patientPathId).subscribe({
        next: (v) => { },
        error: (e) => {
          let errorMessage = 'An error occurred.';
          if (e.error && e.error.errors && e.error.errors.length > 0) {
            errorMessage = e.error.errors[0].message;
          }
          this.popupText = 'Error: ' + e.status + ' ' + errorMessage;
          this.showPopup = true;
          console.error("v1PatientPathPatientPathIdPatientArchivePost " + e)
        },
        complete: () => {
          this.ownPathService.isNewDocumentSubject.next(true);

          this.ecmDocumentTypeName = '';
          this.ecmDocumentTypeCtrl.setValue(this.ecmDocumentTypeName);

          this.ecmDocumentSourceName = '';
          this.ecmDocumentSourceCtrl.setValue(this.ecmDocumentSourceName);

          this.ecmEntry = {
            id: '',
            ecmDocumentTypeId: '',
            ecmDocumentTypeAddition: '',
            ecmDocumentGroupId: '',
            ecmDocumentSourceId: '',
            SFID: this.ecmEntry.SFID,
            isNotArchived: false,
            archiveDate: this.pipe.transform(this.ecmEntry.archiveDate, 'yyyy-MM-dd') || '',
            isArchiveDateMissing: false,
            isArchiveDateBeforeAdmission: false,
            numPages: 1,
            isPrinted: false,
            ecmDocumentTypeName: '',
          };
          this.router.navigate(['./new-patient-archive-document']);
        }
      });
    }
  }

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string) {
    this.popupText = "";
    this.showPopup = false;
  }

  getCurrentDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  /** set start and end date of the admission in order to display them in the datepicker */
  setAdmissionDateRange() {
    // set starting date
    this.admissionStart = new Date(this.path.admissionDate);
    this.admissionEnd = new Date(this.path.admissionDate);
    this.admissionStart.setHours(0, 0, 0, 0);
    this.admissionEnd.setHours(0, 0, 0, 0);

    // go through all admission dates and select the range in which threatment occured
    for (let item of this.pathUnitsDTO) {
      var start = new Date(item.start!);
      var end = new Date(item.end!);
      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);

      if (start <= this.admissionStart) {
        this.admissionStart = start;
      }
      if (end >= this.admissionEnd) {
        this.admissionEnd = end;
      }
    }

  }

  /** disable dates in the datepicker which are before the first admission date
   * or after the current date
   */
  dateFilter = (d: Date | null): boolean => {
    var admissionDate = new Date(this.path.admissionDate);
    admissionDate.setHours(0, 0, 0, 0);
    if (d != null)
      d.setHours(0, 0, 0, 0);

    if (d != null && ((d.getTime() < new Date(admissionDate).getTime()) || (d.getTime() > new Date(this.getCurrentDate()).getTime())))
      return false;
    else
      return true;
  };

  /** assign each date in the datepicker a custom class, when they are in 
   * between the start and end of the admission in order to highlight them */
  dateClass: MatCalendarCellClassFunction<Date> = (date, view) => {
    // Only highligh dates inside the month view.
    if (view === 'month') {
      var start = new Date(this.admissionStart);
      var end = new Date(this.admissionEnd);
      var compDate = new Date(date);
      compDate.setHours(0, 0, 0, 0);
      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);

      // Highlight admission dates
      if (compDate >= start && compDate <= end)
        return 'custom-date-class';
      else
        return '';
    }

    return '';
  };

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
