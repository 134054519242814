import { Component, Input, ViewChild } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import { catchError } from "rxjs/operators";
import { AnalysisService } from "src/swagger-typescript";

@Component({
  selector: 'app-boxplot-horizontal',
  templateUrl: './boxplot-horizontal.component.html',
  styleUrls: ['./boxplot-horizontal.component.css']
})
export class BoxplotHorizontalComponent {

  @Input() client: string = '';
  @Input() mandantId: string[] = [];
  @Input() dataType: string = '';
  @Input() aggregate: string = '';
  @Input() icdCode: string[] = [];
  @Input() locationName: string[] = [];
  @Input() unitType: string[] = [];

  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions: any;

  constructor(public analysisService: AnalysisService) {
    this.chartOptions = {
      series: [],
      chart: {
        height: 400,
        type: "boxPlot"
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "60%",
        },
        boxPlot: {
          colors: {
            upper: "#e9ecef",
            lower: "#f8f9fa"
          }
        }
      },
      stroke: {
        colors: ["#6c757d"]
      },
      xaxis: {
        min: 0,
        max: 60  // Adjusted to fit the range of transmission indices
      }
    };
  }

  ngOnInit(): void {
    const filter = {
      icd_code: this.icdCode,
      location_name: this.locationName,
      unit_type: this.unitType
    };

    this.analysisService.v1AnalysisGetDataPost({
      "client": this.client,
      "mandantId": this.mandantId,
      "data_type": this.dataType,
      "aggregate": this.aggregate,
      "filter": filter
    }).pipe(
      catchError(error => {
        console.error("Error fetching data:", error);
        return [];
      })
    ).subscribe((targetData) => {
      this.handleDataForBoxPlot(targetData);
    });
  }

  handleDataForBoxPlot(targetData: any): void {
    // Parse JSON data
    const parsedTargetData = JSON.parse(targetData);
    const firstKeyTarget = Object.keys(parsedTargetData)[0];
    const target_index = parsedTargetData[firstKeyTarget];

    // Check if parsedTargetData has the expected structure
    if (!target_index) {
      console.error("Parsed data doesn't have the expected structure.");
      return;
    }

    // Initialize an array to hold all series data
    const seriesData: { x: string; y: number[] }[] = [];

    // Translation map for categories
    const categoryTranslations: { [key: string]: string } = {
      target_interoperability_index: "Digitalisierungsgrad Ziel",
      origin_interoperability_index: "Digitalisierungsgrad Ursprung",
      transmission_index: "Datenübertragung",
      transformation_index: "Datentransformation"
    };

    // Loop through categories
    Object.keys(target_index).forEach(category => {
      // Get the values for the category
      const values = target_index[category];
      // Calculate the box plot statistics
      values.sort((a: number, b: number) => a - b);
      const min = values[0];
      const max = values[values.length - 1];
      const q1 = this.calculateQuantile(values, 0.25);
      const median = this.calculateQuantile(values, 0.5);
      const q3 = this.calculateQuantile(values, 0.75);

      // Translate the category
      const translatedCategory = categoryTranslations[category] || category;

      // Add the category and box plot values to the series data
      seriesData.push({
        x: translatedCategory,
        y: [min, q1, median, q3, max]
      });
    });

    // Add the series data to the chart options
    this.chartOptions.series = [{
      type: 'boxPlot',
      data: seriesData
    }];
  }

  calculateQuantile(values: number[], quantile: number): number {
    const pos = (values.length - 1) * quantile;
    const base = Math.floor(pos);
    const rest = pos - base;
    if ((values[base + 1] !== undefined)) {
      return values[base] + rest * (values[base + 1] - values[base]);
    } else {
      return values[base];
    }
  }
}