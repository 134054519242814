<nav>
    <ul>
        <!-- <li><a (click)="router.navigate(['./path-view'])">PFADANSICHT</a></li> -->
        <li><a (click)="router.navigate(['./new-path'])">STAMMDATEN</a></li>
        <li><a (click)="router.navigate(['./entry-kis'])">EINTRAG KIS</a></li>
        <li><a style="background-color: #6b8294; width: 117%;" (click)="router.navigate(['./patient-archive'])">EINTRAG
                PATIENTENARCHIV</a></li>
    </ul>
</nav>

<form #documentForm="ngForm" name="documentForm">
    <div class="container">

        <!-- SF-ID -->
        <mat-form-field style="margin-bottom: 0%;" id="sf_id_form">
            <mat-label for="sf_id">Fall-ID</mat-label>
            <mat-select name="SFID" [(ngModel)]="ecmEntry.SFID" id="sf_id">
                <mat-option *ngFor="let sfid of sfids" [value]="sfid">{{sfid}}</mat-option>
            </mat-select>
        </mat-form-field>
        <!-- isNotArchived -->
        <mat-checkbox style="margin-bottom: 5%;" id="isNotArchived" #isNotArchivedCheckbox
            [(ngModel)]="ecmEntry.isNotArchived" name="isNotArchived" class="filled">Akte nicht
            archiviert</mat-checkbox>

        <div *ngIf="!ecmEntry.isNotArchived">

            <!-- ecmDocumentGroup -->
            <mat-form-field id="ecmDocumentGroup_form">
                <mat-label for="ecmDocumentGroup">Übergeordnete Dokumentengruppe</mat-label>
                <mat-select required="" name="ecmDocumentGroupId" [(ngModel)]="ecmEntry.ecmDocumentGroupId"
                    id="ecmDocumentGroup" (ngModelChange)="onEcmDocumentGroupIdSelected()">
                    <mat-option *ngFor="let ecmDocumentGroup of ecmDocumentGroups" [value]="ecmDocumentGroup.id">
                        {{ecmDocumentGroup.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- ecmDocumentType -->
            <mat-form-field id="ecmDocumentTypeId" class="example-full-width">
                <mat-label>Dokumentenname</mat-label>
                <input required (ngModelChange)="checkOtherDocumentType()" id="ecmDocumentTypeId" type="text"
                    placeholder="Dokumentenname" aria-label="ecmDocumentTypeId" matInput
                    [formControl]="ecmDocumentTypeCtrl" [matAutocomplete]="ecmDocumentTypeId"
                    [value]="ecmDocumentTypeName">
                <mat-autocomplete autoActiveFirstOption #ecmDocumentTypeId="matAutocomplete">
                    <mat-option *ngFor="let option of ecmDocumentTypefilteredOptions | async" [value]="option.name">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <!-- ecmDocumentTypeAddition -->
            <mat-form-field id="ecmDocumentTypeAddition_form">
                <mat-label for="ecmDocumentTypeAddition">Dokumentenname Ergänzung</mat-label>
                <input matInput [disabled]="!isOtherDocumentType" name="ecmDocumentTypeAddition"
                    [(ngModel)]="ecmEntry.ecmDocumentTypeAddition" placeholder="Bitte eintragen" type="text"
                    id="ecmDocumentTypeAddition">
            </mat-form-field>

            <!-- isPrinted -->
            <mat-form-field id="isPrinted_form">
                <mat-label for="isPrinted">Ausgedrucktes KIS Dokument?</mat-label>
                <mat-select required #isPrintedCheckbox name="isPrinted" [(ngModel)]="ecmEntry.isPrinted"
                    id="isPrinted">
                    <mat-option *ngFor="let isPrinted of isPrinted" [value]="isPrinted">{{isPrinted ? 'Ja' :
                        'Nein'}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- archiveDate-->
            <mat-form-field [class.disabled]="ecmEntry.isArchiveDateMissing || ecmEntry.isArchiveDateBeforeAdmission"
                id="archiveDate_form">
                <mat-label>Datum</mat-label>
                <input required (keydown.enter)="$event.preventDefault()" matInput [matDatepicker]="archiveDate"
                    [matDatepickerFilter]="dateFilter"
                    [disabled]="ecmEntry.isArchiveDateMissing ?? false || ecmEntry.isArchiveDateBeforeAdmission ?? false"
                    name="archiveDate" [(ngModel)]="ecmEntry.archiveDate" id="archiveDate">
                <mat-datepicker-toggle matIconSuffix [for]="archiveDate"></mat-datepicker-toggle>
                <mat-datepicker [dateClass]="dateClass" #archiveDate></mat-datepicker>
            </mat-form-field>

            <!-- ecmDocumentSource -->
            <mat-form-field id="ecmDocumentSource_form" class="example-full-width">
                <mat-label>Dokumentenherkunft</mat-label>
                <input required id="ecmDocumentSourceId" type="text" placeholder="Dokumentenherkunft"
                    aria-label="ecmDocumentSourceId" matInput [formControl]="ecmDocumentSourceCtrl"
                    [matAutocomplete]="ecmDocumentSourceId" [value]="ecmDocumentSourceName">
                <mat-autocomplete autoActiveFirstOption #ecmDocumentSourceId="matAutocomplete">
                    <mat-option *ngFor="let option of ecmDocumentSourcefilteredOptions | async" [value]="option.name">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <!-- numPages -->
            <mat-form-field style="width: 10%;" id="numPages_form">
                <mat-label for="numPages">Seiten/Anzahl (>=1)</mat-label>
                <input [min]="1" matInput name="numPages" [(ngModel)]="ecmEntry.numPages" type="number" id="numPages"
                    name="numPages">
            </mat-form-field> <br>

            <!-- isArchiveDateMissing -->
            <mat-checkbox id="isArchiveDateMissing" #isArchiveDateMissingCheckbox
                [(ngModel)]="ecmEntry.isArchiveDateMissing" name="isArchiveDateMissing" class="filled">Datum nicht
                vorhanden</mat-checkbox> <br>
            <!-- isArchiveDateBeforeAdmission -->
            <mat-checkbox id="isArchiveDateBeforeAdmission" #isArchiveDateBeforeAdmissionCheckbox
                [(ngModel)]="ecmEntry.isArchiveDateBeforeAdmission" name="isArchiveDateBeforeAdmission"
                class="filled">Datum liegt vor Klinikaufenthalt</mat-checkbox>

        </div>

        <div *ngIf="ecmEntry.isNotArchived">
            <!-- ecmDocumentGroup -->
            <mat-form-field style="opacity: 0.5;" disabled="disabled" id="ecmDocumentGroup_form">
                <mat-label for="ecmDocumentGroup">Übergeordnete Dokumentengruppe</mat-label>
                <mat-select name="ecmDocumentGroupId" [(ngModel)]="ecmEntry.ecmDocumentGroupId" id="ecmDocumentGroup"
                    (ngModelChange)="onEcmDocumentGroupIdSelected()">
                    <mat-option *ngFor="let ecmDocumentGroup of ecmDocumentGroups" [value]="ecmDocumentGroup.id">
                        {{ecmDocumentGroup.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- ecmDocumentType -->
            <mat-form-field style="opacity: 0.5;" disabled="disabled" id="ecmDocumentType_form">
                <mat-label for="ecmDocumentType">Dokumentenname</mat-label>
                <mat-select (selectionChange)="checkOtherDocumentType()" [(ngModel)]="ecmEntry.ecmDocumentTypeId"
                    id="ecmDocumentType" name="ecmDocumentTypeId">
                    <mat-option *ngFor="let ecmDocumentType of ecmDocumentTypes" [value]="ecmDocumentType.id">
                        {{ecmDocumentType.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- ecmDocumentTypeAddition -->
            <mat-form-field style="opacity: 0.5;" disabled="disabled" id="ecmDocumentTypeAddition_form">
                <mat-label for="ecmDocumentTypeAddition">Dokumentenname Ergänzung</mat-label>
                <input matInput [disabled]="!isOtherDocumentType" name="ecmDocumentTypeAddition"
                    [(ngModel)]="ecmEntry.ecmDocumentTypeAddition" placeholder="Bitte eintragen" type="text"
                    id="ecmDocumentTypeAddition">
            </mat-form-field>

            <!-- isPrinted -->
            <mat-form-field style="opacity: 0.5;" disabled="disabled" id="isPrinted_form">
                <mat-label for="isPrinted">Ausgedrucktes KIS Dokument?</mat-label>
                <mat-select #isPrintedCheckbox name="isPrinted" [(ngModel)]="ecmEntry.isPrinted" id="isPrinted">
                    <mat-option *ngFor="let isPrinted of isPrinted" [value]="isPrinted">{{isPrinted ? 'Ja' :
                        'Nein'}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- archiveDate-->
            <mat-form-field style="opacity: 0.5;" disabled="disabled"
                [class.disabled]="ecmEntry.isArchiveDateMissing || ecmEntry.isArchiveDateBeforeAdmission"
                id="archiveDate_form">
                <mat-label>Datum</mat-label>
                <input matInput [matDatepicker]="archiveDate" [matDatepickerFilter]="dateFilter"
                    [disabled]="ecmEntry.isArchiveDateMissing ?? false || ecmEntry.isArchiveDateBeforeAdmission ?? false"
                    name="archiveDate" [(ngModel)]="ecmEntry.archiveDate" id="archiveDate">
                <mat-datepicker-toggle matIconSuffix [for]="archiveDate"></mat-datepicker-toggle>
                <mat-datepicker [dateClass]="dateClass" #archiveDate></mat-datepicker>
            </mat-form-field>

            <!-- ecmDocumentSource -->
            <mat-form-field style="opacity: 0.5;" disabled="disabled" id="ecmDocumentSource_form">
                <mat-label for="ecmDocumentSource">Dokumentenherkunft</mat-label>
                <mat-select [(ngModel)]="ecmEntry.ecmDocumentSourceId" name="ecmDocumentSourceId"
                    id="ecmDocumentSources">
                    <mat-option *ngFor="let ecmDocumentSource of ecmDocumentSources" [value]="ecmDocumentSource.id">
                        {{ecmDocumentSource.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- numPages -->
            <mat-form-field disabled="disabled" style="width: 10%; opacity: 0.5;" id="numPages_form">
                <mat-label for="numPages">Seiten/Anzahl(>1)</mat-label>
                <input (keydown.enter)="$event.preventDefault()" matInput name="numPages"
                    [(ngModel)]="ecmEntry.numPages" placeholder="2" type="number" id="numPages" name="numPages">
            </mat-form-field> <br>

            <!-- isArchiveDateMissing -->
            <mat-checkbox style="opacity: 0.5;" disabled="disabled" id="isArchiveDateMissing"
                #isArchiveDateMissingCheckbox [(ngModel)]="ecmEntry.isArchiveDateMissing" name="isArchiveDateMissing"
                class="filled">Datum nicht vorhanden</mat-checkbox> <br>
            <!-- isArchiveDateBeforeAdmission -->
            <mat-checkbox style="opacity: 0.5;" disabled="disabled" id="isArchiveDateBeforeAdmission"
                #isArchiveDateBeforeAdmissionCheckbox [(ngModel)]="ecmEntry.isArchiveDateBeforeAdmission"
                name="isArchiveDateBeforeAdmission" class="filled">Datum liegt vor Klinikaufenthalt</mat-checkbox>
        </div>

    </div>
    <div class="button-group">
        <button (click)="router.navigate(['./patient-archive'])" style="color: white; background-color: #6b8294;"
            mat-flat-button>ABBRECHEN</button>
        <button (click)="saveDocumentAndNavToOverview()" style="color: white; background-color: #6b8294;"
            mat-flat-button>SPEICHERN + <br> ZUR
            ÜBERSICHT</button>
        <button (click)="saveDocumentAndCreateNewDocument(); scrollToTop();"
            style="color: white; background-color: #6b8294;" mat-flat-button>SPEICHERN
            + NÄCHSTER
            EINTRAG</button>
    </div>
</form>

<app-popup class="popup" *ngIf="showPopup == true" (answerEvent)="answerEvent($event)" [popupText]="popupText"
    [buttonLeftText]="''" [buttonRightText]="''" [buttonCenterText]="'OK'"> </app-popup>