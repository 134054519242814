<div class="container">
    <mat-card class="adminCard">
        <mat-card-content>
            <mat-icon class="material-symbols-rounded">account_circle</mat-icon>
            <span style="margin-left: 10%;">{{username}}</span><br>
            <span id="role" style="font-size: 80%; margin-left: 24%;">{{role}}</span>
            <mat-divider></mat-divider>
            <p>Profilname</p>
            <mat-card style="color: #6b8294; margin-top: 2%; border: 1px solid #6b8294;" class="profileName">
                <p style="color: #6b8294; margin-left: 5%;">{{username}}</p>
            </mat-card>
        </mat-card-content>
    </mat-card>

    <button (click)="onNewProfileClick()" style="color: white;" mat-button
        (click)="router.navigate(['./create-user-account'])" id="createUser">
        <mat-icon style="margin-right: 5%;" class="material-symbols-rounded">add_circle</mat-icon>
        Profil anlegen
    </button>
    <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort
            (matSortChange)="announceSortChange($event)" matSortActive="name" matSortDirection="asc"
            matSortDisableClear>
            <ng-container matColumnDef="name">
                <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef mat-sort-header="name"
                    sortActionDescription="Sort by name">ACCOUNTNAME</th>
                <td mat-cell *matCellDef="let element" (click)="loadMandant(element)">{{element.name}}</td>
            </ng-container>
            <ng-container matColumnDef="expires">
                <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef mat-sort-header="expires"
                    sortActionDescription="Sort by expires">ABLAUFDATUM</th>
                <td mat-cell *matCellDef="let element">{{this.pipe.transform(element.expires, 'dd.MM.yyyy', 'GMT')}}</td>
            </ng-container>
            <ng-container matColumnDef="created">
                <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef mat-sort-header="created"
                    sortActionDescription="Sort by created">ERSTELLT AM</th>
                <td mat-cell *matCellDef="let element">{{this.pipe.transform(element.created, 'dd.MM.yyyy', 'GMT')}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <mat-paginator [length]="mandanten.length" [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]"
        (page)="onPageChange($event)"></mat-paginator>

</div>