<div class="container">
    <p>Das <br>
        <br>
        Fraunhofer-Institut für<br>
        Experimentelles Software Engineering IESE<br>
        Fraunhofer-Platz 1<br>
        67663 Kaiserslautern,<br>
        <br>
        das<br>
        <br>
        Fraunhofer-Institut für<br>
        Zelltherapie und Immunologie IZI<br>
        Perlickstraße 1<br>
        04103 Leipzig<br>
        <br>
        und das<br>
        <br>
        Fraunhofer-Institut für<br>
        Produktionstechnik und Automatisierung IPA<br>
        Nobelstraße 12<br>
        70569 Stuttgart<br>
        <br>
        sind rechtlich nicht selbstständige Einrichtungen der<br>
        <br>
        Fraunhofer-Gesellschaft<br>
        zur Förderung der angewandten Forschung e.V.<br>
        Hansastraße 27 c<br>
        80686 München<br>
        Internet: <a style="color: white;" href="www.fraunhofer.de">Fraunhofer.de</a><br>
        E-Mail: <a style="color: white;" href="info@zv.fraunhofer.de">info@zv.fraunhofer.de</a><br>
    </p>

    <h3>Fachverantwortliche Person für den Betrieb von sodiaph.de:</h3>

    <p>
        Michael Ochs, Fraunhofer IESE<br>
        E-Mail: michael.ochs@iese.fraunhofer.de<br>
        <br>
        Umsatzsteuer-Identifikationsnummer gemäß § 27 a<br>
        Umsatzsteuergesetz: DE 129515865<br>
        <br>
        Registergericht<br>
        Amtsgericht München<br>
        Eingetragener Verein<br>
        Register-Nr. VR 4461<br>
    </p>

    <h3>Vorstand der Fraunhofer-Gesellschaft zur Förderung der angewandten Forschung e.V.</h3>

    <p>
        Prof. Dr.-Ing. Holger Hanselka | Präsident<br>
        Prof. Dr. Axel Müller-Groeling | Mitglied des Vorstands<br>
        Ass. jur. Elisabeth Ewen | Mitglied des Vorstands<br>
        Dr. Sandra Krey | Mitglied des Vorstands<br>
    </p>

    <h3>Nutzungsrechte</h3>
    <p>© Fraunhofer-Gesellschaft e.V., München<br><br>
        Alle Rechte vorbehalten.<br>
        Die Urheberrechte dieser Webseite liegen vollständig bei der Fraunhofer-
        Gesellschaft.<br><br>
        Ein Download oder Ausdruck dieser Veröffentlichungen ist ausschließlich für den
        persönlichen Gebrauch gestattet. Alle darüber hinaus gehenden Verwendungen,
        insbesondere die kommerzielle Nutzung und Verbreitung, sind grundsätzlich nicht
        gestattet und bedürfen der schriftlichen Genehmigung.<br><br>
        Ein Download oder Ausdruck ist darüber hinaus lediglich zum Zweck der
        Berichterstattung über die Fraunhofer-Gesellschaft und ihrer Institute nach
        Maßgabe untenstehender Nutzungsbedingungen gestattet:<br><br>
        Grafische Veränderungen an Bildmotiven - außer zum Freistellen des
        Hauptmotivs - sind nicht gestattet. Es ist stets die Quellenangabe und
        Übersendung von zwei kostenlosen Belegexemplaren an die oben genannte
        Adresse erforderlich. Die Verwendung ist honorarfrei.</p>
    <h3>Haftungshinweis</h3>
    <p>Wir übernehmen keine Haftung für die Inhalte externer Links. Für den Inhalt der
        verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.<br><br>
        Wir sind bemüht, das Webangebot stets aktuell und inhaltlich richtig sowie
        vollständig anzubieten. Dennoch ist das Auftreten von Fehlern nicht völlig
        auszuschließen. Das Fraunhofer-Institut bzw. die Fraunhofer-Gesellschaft
        übernimmt keine Haftung für die Aktualität, die inhaltliche Richtigkeit sowie für
        die Vollständigkeit der in ihrem Webangebot eingestellten Informationen. Dies
        bezieht sich auf eventuelle Schäden materieller oder ideeller Art Dritter, die
        durch die Nutzung dieses Webangebotes verursacht wurden.<br><br>
        Geschützte Marken und Namen, Bilder und Texte werden auf unseren Seiten in
        der Regel nicht als solche kenntlich gemacht. Das Fehlen einer solchen
        Kennzeichnung bedeutet jedoch nicht, dass es sich um einen freien Namen, ein
        freies Bild oder einen freien Text im Sinne des Markenzeichenrechts handelt.</p>
</div>