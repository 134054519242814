/**
 * Sodiaph Backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DiagnosisCreateRequest } from './diagnosisCreateRequest';

export interface PatientPathUpdateRequest { 
    gender?: PatientPathUpdateRequest.GenderEnum;
    age?: number;
    admissionDate?: string;
    admissionTypeId?: string;
    diagnoses?: Array<DiagnosisCreateRequest>;
}
export namespace PatientPathUpdateRequest {
    export type GenderEnum = 'm' | 'w' | 'd';
    export const GenderEnum = {
        M: 'm' as GenderEnum,
        W: 'w' as GenderEnum,
        D: 'd' as GenderEnum
    };
}