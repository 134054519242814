import { Component, Input, ViewChild, OnInit } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { AnalysisService } from "src/swagger-typescript";

@Component({
  selector: 'app-stacked-column-chart-age-group',
  templateUrl: './stacked-column-chart-age-group.component.html',
  styleUrls: ['./stacked-column-chart-age-group.component.css']
})
export class StackedColumnChartAgeGroupComponent implements OnInit {

  @Input() client: string = '';
  @Input() mandantId: string[] = [];
  @Input() dataType: string = '';
  @Input() aggregate: string = '';
  @Input() icdCode: string[] = [];
  @Input() locationName: string[] = [];
  @Input() unitType: string[] = [];

  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions: any;

  constructor(public analysisService: AnalysisService) {
    this.chartOptions = {
      series: [],
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
        type: "category",
        categories: ["18-24", "31-40", "41-50", "51-60", "61-64", "65+"],
        title: {
          text: "Altersgruppe"
        },
      },
      legend: {
        position: "right",
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    };
  }

  ngOnInit(): void {
    const filter = {
      icd_code: this.icdCode,
      location_name: this.locationName,
      unit_type: this.unitType
    };

    this.analysisService.v1AnalysisGetDataPost({
      "client": this.client,
      "mandantId": this.mandantId,
      "data_type": this.dataType,
      "aggregate": this.aggregate,
      "filter": filter
    }).pipe(
      catchError(error => {
        console.error("Error fetching data:", error);
        return of([]);
      })
    ).subscribe((targetData) => {
      this.handleDataForHistogram(targetData);
    });
  }

  handleDataForHistogram(targetData: any): void {
    // Parse JSON data
    const parsedTargetData = JSON.parse(targetData);
    // Initialize variables to hold the series and categories
    let series: any[] = [];
    const categories = ["18-24", "31-40", "41-50", "51-60", "61-64", "65+"];

    // Check if parsedTargetData is in the simple format
    if (typeof parsedTargetData["18-24"] === 'number') {

      // Simple format with direct counts for age groups
      const data = categories.map(category => parsedTargetData[category] || 0);
      series.push({
        name: 'Counts',
        data: data,
        color: '#6b8294',
      });

    } else {

      // Complex format with nested aggregate
      const seriesData: { [key: string]: number[] } = {};
      categories.forEach(category => {
        const ageGroupData = parsedTargetData[category] || {};
        Object.keys(ageGroupData).forEach(aggregate => {
          if (!seriesData[aggregate]) {
            seriesData[aggregate] = new Array(categories.length).fill(0);
          }
          const index = categories.indexOf(category);
          seriesData[aggregate][index] = ageGroupData[aggregate];
        });
      });

      // Convert seriesData object to an array for ApexCharts
      series = Object.keys(seriesData).map(aggregate => ({
        name: aggregate,
        data: seriesData[aggregate]
      }));
    }

    // Update the chart options
    this.chartOptions.series = series;
    this.chartOptions.xaxis.categories = categories;

    // Update the chart to reflect new data
    if (this.chart) {
      this.chart.updateOptions(this.chartOptions);
    }
  }

}