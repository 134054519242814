import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../general.service';
import { AuthService, UserLoginRequest, UserService } from 'src/swagger-typescript';
import { LoggedInService } from '../logged-in.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})

export class AuthComponent implements OnInit {

  showPopup = false;
  popupText = '';

  constructor(private generalService: GeneralService,
    public router: Router,
    private authService: AuthService,
    private userService: UserService,
    private loggedInService: LoggedInService) {
  }

  ngOnInit(): void {
    this.generalService.setTitle('Login');
    this.generalService.setPathButtonVisible(true);
  }

  /** Send Login request on clicking submit button for the login form */
  onSubmit(form: NgForm) {
    // user has to enter username and password to submit
    if (!form.valid) {
      return;
    }

    // create Object from username and password
    const usernameLogin = form.value.username;
    const passwordLogin = form.value.password;
    const request = { username: usernameLogin, password: passwordLogin } as UserLoginRequest;

    /** Send login request to server - when authorized navigate to the appropriate starting 
     *  page, depending on the role of the user 
     * */
    this.authService.v1AuthLoginPost(request).subscribe({
      next: (v) => { },
      error: (e) => {
        let errorMessage = 'An error occurred.';
        if (e.error && e.error.errors && e.error.errors.length > 0) {
          errorMessage = e.error.errors[0].message;
        }
        this.popupText = 'Error: ' + e.status + ' ' + errorMessage;
        this.showPopup = true;
      },
      complete: () => {

        // request role of user who is logged in
        this.userService.v1UserOwnGet().subscribe({
          next: (v) => {
            // set internal role of the user for later use
            this.loggedInService.setRole(v.role);
            if (v.role == 'admin') {
              this.router.navigate(['./user-organization'])
            }
            if (v.role == 'mandant' || v.role == 'steward') {
              this.router.navigate(['./paths-overview'])
            }
          },
          error: (e) => console.error("v1UserOwnGet - " + e),
          complete: () => {
          }
        });
        this.loggedInService.loggedInSubject.next(true);
        // reset timer to 60 minutes
        this.loggedInService.resetSessionTimer();
      }
    });
  }

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string) {
    this.popupText = "";
    this.showPopup = false;
  }
}