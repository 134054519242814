import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PathService } from '../path.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {
  /** show freetext field */
  @Input() showFreetextField: boolean = false
  /** freetext value */
  @Input() freetextValue: string[] = [];
  /** question which is displayed set by the parent component */
  @Input() popupText: string = "";
  /** select options which are displayed as a select box */
  @Input() selectOptions: string[] = [];
  /** left button text which is displayed set by the parent component */
  @Input() buttonLeftText: string = "";
  /** right button text which is displayed set by the parent component */
  @Input() buttonRightText: string = "";
  /** center button text which is displayed set by the parent component */
  @Input() buttonCenterText: string = "";
  /** event Object which emits the question answer to the parent component, when it's fired */
  @Output() answerEvent = new EventEmitter<string>();
  /** event obejct which emits the buttonClicked event */
  @Output() buttonLeftClicked = new EventEmitter();
  @Output() buttonCenterClicked = new EventEmitter();
  @Output() buttonRightClicked = new EventEmitter();
  @Output() optionSelected: EventEmitter<string> = new EventEmitter<string>();

  /** consturctor */
  constructor() { }


  /**********************************************************************************************/

  /** 
   * Lifecycle hook ngOnInit is triggered when the component is initialized.
   */
  ngOnInit(): void {
  }


  /**********************************************************************************************/

  /** event which emits the Event Object to the parent component, when it's fired (not used atm)  
   * 
   * @param {string} answer answer which should be emitted 
   */
  emitAnswer(answer: string) {
    this.answerEvent.emit(answer);
  }

  buttonLClicked() {
    this.buttonLeftClicked.emit();
  }

  buttonCClicked() {
    this.buttonCenterClicked.emit();
  }

  buttonRClicked() {
    this.buttonRightClicked.emit();
  }

  onOptionSelected(option: string): void {
    this.optionSelected.emit(option);
  }

}
