import { Component, OnInit, ViewChild } from '@angular/core';
import { GeneralService } from '../general.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { EcmDocumentTypeDTO, EcmService, PatientArchiveDTO, PatientArchiveService, PatientPathDTO, PatientPathService, TreatmentUnitDTO } from 'src/swagger-typescript';
import { PathService } from '../path.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-patient-archive',
  templateUrl: './patient-archive.component.html',
  styleUrls: ['./patient-archive.component.css']
})

export class PatientArchiveComponent implements OnInit {

  documents = [] as Array<PatientArchiveDTO>;

  /** array of TreatmentUnitDTO to render treatment units */
  public pathUnitsDTO: TreatmentUnitDTO[] = [];

  displayedColumns: string[] = ['ecmDocumentTypeName',
    'SFID',
    'archiveDate', 'delete'];

  dataSource!: MatTableDataSource<PatientArchiveDTO>;

  pipe = new DatePipe('de-DE');

  /** id from path to create activity for*/
  private patientPathId: string = '';

  /** array of PatientPathDTO to share path between path overview an new path component */
  private data: PatientPathDTO[] = [];

  /** empty path object to save path by id */
  public path: any = {};

  @ViewChild(MatSort, { static: false })
  sort: MatSort = new MatSort

  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;

  /** used to indicate if popup is shown */
  public showPopup: boolean = false;
  public showPopupDelete: boolean = false;

  /** text which is shown on popup */
  public popupText: string = "";

  elementToDelete = '';

  public sfids: string[] = [];

  public ecmDocumentTypes = [] as Array<EcmDocumentTypeDTO>;

  public documentTypeName: string | undefined = '';
  documentTypeId: string | undefined = '';
  documentTypeMap: { [id: string]: string } = {};

  constructor(private patientPathService: PatientPathService, private ecmService: EcmService, public ownPathService: PathService, private patientArchiveService: PatientArchiveService, private generalService: GeneralService, public router: Router) {
  }

  ngOnInit(): void {
    this.generalService.setTitle('Dokument anlegen')
    this.generalService.setPathButtonVisible(false);

    //check if add new path button was clicked
    if (this.ownPathService.getisNewPath()) {
      return;
    }

    // load data if path on table was clicked
    this.data = this.ownPathService.getPath();
    if (this.data) {
      this.path = this.data;
      this.generalService.setSodiaphID(this.path.SID);
      this.path.admissionDate = this.pipe.transform(this.path.admissionDate, 'yyyy-MM-dd');
      if (this.path.id) {
        this.patientPathId = this.path.id;
      }

      // load existing sfids
      this.ownPathService.getSFIDs().forEach(sfid => {
        this.sfids.push(sfid)
      });
    }

    // load ecm document types 
    this.ecmService.v1EcmEcmDocumentTypeGet().subscribe({
      next: (v) => {
        this.ecmDocumentTypes = v;

        // Fetch documents once document types are available
        this.dataSource = new MatTableDataSource<PatientArchiveDTO>([]);
        this.patientArchiveService.v1PatientPathPatientPathIdPatientArchiveGet(this.patientPathId).subscribe({
          next: (v) => {
            this.documents = v;

            this.documents.forEach(document => {
              const documentType = this.ecmDocumentTypes.find(type => type.id === document.ecmDocumentTypeId);
              if (documentType) {
                document.ecmDocumentTypeName = documentType.name;
              }
            });

            this.dataSource.data = this.documents;
            this.dataSource.sort = this.sort;

            // Update the paginator
            if (this.documents.length != 0 && this.paginator != undefined) {
              this.dataSource.paginator = this.paginator;
              this.paginator.length = this.documents.length;
              this.paginator.pageIndex = 0;
              this.paginator.pageSize = 15;
              this.paginator.page.emit();
            }
          },
          error: (e) => { console.error(e) },
          complete: () => { }
        });
      },
      error: (e) => { console.error(e) },
      complete: () => { }
    });

    this.patientPathService.v1PatientPathPatientPathIdTreatmentUnitGet(this.patientPathId).subscribe((data: TreatmentUnitDTO[]) => {
      this.pathUnitsDTO = data;
    });

  }

  onNewDocumentClick(): void {
    this.ownPathService.setSelectedOption("");
    this.ownPathService.isNewDocumentSubject.next(true);
    this.popupText = "Bitte wählen sie die Fall-ID aus, für die Sie die folgenden Dokumente anlegen möchten.";
    this.showPopup = true;
  }

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string) {
    this.popupText = "";
    this.showPopup = false;
    this.showPopupDelete = false;
  }

  navToCreateDocument() {
    this.router.navigate(['./new-patient-archive-document']);
  }

  loadDocument(ecmEntry: PatientArchiveDTO) {
    this.sfids = []
    this.ownPathService.isNewDocumentSubject.next(false);
    this.ownPathService.setEcmEntry(ecmEntry);
    this.router.navigate(['./new-patient-archive-document']);
  }

  deleteRow(ecmEntryId: string) {
    this.patientArchiveService.v1PatientPathPatientPathIdPatientArchivePatientArchiveIdDelete(this.patientPathId, ecmEntryId).subscribe({
      next: (v) => {
        this.patientArchiveService.v1PatientPathPatientPathIdPatientArchiveGet(this.patientPathId).subscribe({
          next: (v) => {
            this.documents = v;
            this.documents.forEach(document => {
              const documentType = this.ecmDocumentTypes.find(type => type.id === document.ecmDocumentTypeId);
              if (documentType) {
                document.ecmDocumentTypeName = documentType.name;
              }
            });
            this.dataSource.data = this.documents;
            this.dataSource.sort = this.sort;
          },
          error: (e) => { console.error(e) },
          complete: () => { }
        });
      },
      error: (e) => { console.error('v1PatientPathPatientPathIdPatientArchivePatientArchiveIdDelete ' + e) },
      complete: () => { }
    });
  }

  deleteDocument() {
    this.deleteRow(this.elementToDelete);
  }

  onOptionSelected(option: string): void {

    this.ownPathService.setDatesForSpecificSFID('');
    this.ownPathService.setSelectedOption(option);

    var earliestDate: string | number | null = null;

    this.pathUnitsDTO.forEach(pathUnit => {
      if (pathUnit.SFID === option) {
        const startDate = pathUnit.start || '';

        // Set the earliestDate if it's null or the current date is earlier
        if (earliestDate === null || startDate < earliestDate) {
          earliestDate = startDate;
        }
      }
    });

    if (earliestDate !== null) {
      this.ownPathService.setDatesForSpecificSFID(earliestDate);
    }

    this.documents.forEach(document => {
      if (document.SFID === option) {
        this.ownPathService.setDatesForSpecificSFID(document.archiveDate);
      }
    });
  }

}