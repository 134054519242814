import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-tooltip-overlay',
  template: `<div class="tooltip-content">
               <app-math-jax-paragraph [mathString]="mathString"></app-math-jax-paragraph>
             </div>`,
  styles: [`
    .tooltip-content {
      padding: 8px;
      background-color: white;
      border: 1px solid #ddd;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
  `]
})
export class CustomTooltipComponent {
  @Input() mathString: string = '';
}