import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../general.service';
import { PathService } from '../path.service';
import { PatientPath, PatientPathService, UnitDTO, TreatmentUnitDTO, UnitService, HisEntry } from 'src/swagger-typescript';

interface Activity {
  id: string;
  idPath: string;
  name: string;
  date: Date;
}

interface PathView {
  stationName: string;
  startDate: string;
  endDate: string;
  activities: {
    activityDate: Date;
    activityName: string;
  }[];
}

@Component({
  selector: 'app-path-view',
  templateUrl: './path-view.component.html',
  styleUrls: ['./path-view.component.css']
})

export class PathViewComponent implements OnInit {

  // pathView: any[] = [{stationName: "1", activities: [{activityDate: "1", activityName: "1"}]}];
  public pathView: PathView[] = [];
  public patientPath: PatientPath;
  public treatmentUnits: Array<TreatmentUnitDTO> = [];
  public units: Array<UnitDTO> = [];
  public hisEntries: Array<HisEntry> = [];

  constructor(private ownPathService: PathService, public router: Router, private generalService: GeneralService, private pathService: PathService,
    private patientPathService: PatientPathService, private unitService: UnitService) {
    this.patientPath = {};
  }

  ngOnInit(): void {
    this.generalService.setTitle('Patientenpfad')

    //check if add new path button was clicked
    if (this.ownPathService.getisNewPath()) {
      return;
    }
    this.patientPath = this.pathService.getPath()

    // return if no patientpath is selected
    if (this.patientPath == undefined)
      return;

    // treatment stations for selected patient path
    this.patientPathService.v1PatientPathPatientPathIdTreatmentUnitGet(this.patientPath.id!).subscribe({
      next: (v) => {
        this.treatmentUnits = v;

        // get list of units
        this.unitService.v1UnitGet().subscribe({
          next: (v2) => {
            this.units = v2;

            // fill arrays with requested data in order to show a full view of the patient path 
            this.fillTreatmentArray();

            // TODO add activity request
            this.patientPathService.v1PatientPathPatientPathIdHisEntryGet(this.patientPath.id!).subscribe({
              next: (v3) => {
                this.hisEntries = v3;

                // fill pathview with unitlist
                this.fillUnitsArray();
              },
              error: (e3) => { console.error(e3) },
              complete: () => { }
            });
          },
          error: (e2) => { console.error(e2) },
          complete: () => { }
        });
      },
      error: (e) => { console.error(e) },
      complete: () => { }
    });
  }

  /**
   * LifecycleHook - unsub from all subscriptions
   */
  ngOnDestroy() {
  }


  /**
   * fill pathview with unitlist - and check which dates correspond to which unit layovers
   */
  private fillUnitsArray() {
    for (let path of this.pathView) {
      for (let entry of this.hisEntries) {
        const startDate: Date = new Date(path.startDate)
        const endDate: Date = new Date(path.endDate)
        const entryDate: Date = new Date(entry.date!);
        if (entryDate.getTime() >= startDate.getTime() && entryDate.getTime() <= endDate.getTime()) {
          const date: Date = new Date(entry.date!);
          path.activities.push({ activityDate: date, activityName: entry.hisDocument!.name! });
        }
      }
    }

  }

  /**
   * fill arrays with requested data in order to show a full view of the patient path 
   */
  private fillTreatmentArray() {
    for (let stn of this.treatmentUnits) {
      for (let q = 0; q < this.units.length; q++) {
        if (stn.unitId == this.units[q].id) {
          this.pathView.push({ stationName: this.units[q].name!, startDate: stn.start!, endDate: stn.end!, activities: [] })
        }
      }
    }
  }

  private fillHisEntryArray() {

  }

}
