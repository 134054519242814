<p style="margin-left: 5%;">Haben Sie Fragen zum Projekt oder Webtool, wollen uns Feedback geben oder mit uns über
    weitere Themen
    sprechen?
    Dann kontaktieren Sie uns gerne!<br></p>
<div class="container">

    <div id="left-column">
        <h3>Fraunhofer-Institut für Zelltherapie und Immunologie IZI</h3>
        <p>
            Perlickstraße 1<br>
            04103 Leipzig</p>

        <h3>Fraunhofer-Institut für Experimentelles Software Engineering IESE</h3>
        <p>
            Fraunhofer-Platz 1<br>
            67663 Kaiserslautern</p>


        <h3>Fraunhofer-Institut für Produktionstechnik und Automatisierung IPA</h3>
        <p>
            Nobelstraße 12<br>
            70569 Stuttgart</p>
    </div>

    <div id="middle-column">
        <img class="logo" src="assets\Logo_IZI_weiss.svg"> <br>
        <img class="logo" src="assets\Logo_IESE_weiss.svg"> <br>
        <img class="logo" src="assets\Logo_IPA_weiss.svg"> <br>
    </div>

    <div id="right-column">
        <h3>Inhaltlicher Support:</h3>
        <p>
            <strong>Stefanie Lehmann</strong><br>
            <strong>Nadine Schlichting</strong><br>
            <strong>Andreas Oberbach</strong><br>
            Email: stefanie.lehmann@izi.fraunhofer.de<br>
        </p>
        <h3>Technischer Support:</h3>
        <p>
            <strong>Michael Ochs</strong><br>
            Email: michael.ochs@iese.fraunhofer.de<br>
        </p>
    </div>
</div>