import { style } from "@angular/animations";
import { Component, Input, ViewChild, OnInit } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import { catchError } from "rxjs/operators";
import { AnalysisService } from "src/swagger-typescript";

@Component({
  selector: 'app-histogram',
  templateUrl: './histogram.component.html',
  styleUrls: ['./histogram.component.css']
})
export class HistogramComponent implements OnInit {

  @Input() client: string = '';
  @Input() mandantId: string[] = [];
  @Input() dataType: string = '';
  @Input() aggregate: string = '';
  @Input() icdCode: string[] = [];
  @Input() locationName: string[] = [];
  @Input() unitType: string[] = [];

  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions: any;

  constructor(public analysisService: AnalysisService) {
    this.chartOptions = {
      series: [],
      chart: {
        height: 400,
        type: "bar"
      },
      plotOptions: {
        bar: {
          barHeight: "60%",
        }
      },
      xaxis: {
        categories: ["manuell", "teilautomatisiert", "vollautomatisiert"],
        title: {
          text: "Datenübertragungsart"
        },
      },
      yaxis: {
        labels: {
          formatter: function (val: string) {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          }
        },
        title: {
          text: "Anzahl Übertragungsaktivität",
        },
      },
      dataLabels: {
        formatter: function (val: string) {
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
        enabled: true,
        style: {
          colors: ['#000000'],
        }
      }
    };
  }

  ngOnInit(): void {
    const filter = {
      icd_code: this.icdCode,
      location_name: this.locationName,
      unit_type: this.unitType
    };

    this.analysisService.v1AnalysisGetDataPost({
      "client": this.client,
      "mandantId": this.mandantId,
      "data_type": this.dataType,
      "aggregate": this.aggregate,
      "filter": filter
    }).pipe(
      catchError(error => {
        console.error("Error fetching data:", error);
        return [];
      })
    ).subscribe((targetData) => {
      this.handleDataForHistogram(targetData);
    });
  }

  handleDataForHistogram(targetData: any): void {
    // Parse JSON data
    const parsedTargetData = JSON.parse(targetData);

    // Check if parsedTargetData has the expected structure
    if (!parsedTargetData || typeof parsedTargetData !== 'object') {
      console.error("Parsed data doesn't have the expected structure.");
      return;
    }

    // Extract categories and values for the histogram
    const categories = Object.keys(parsedTargetData);
    const values = Object.values(parsedTargetData);

    // Set the categories and series data for the histogram
    this.chartOptions.series = [{
      name: categories,
      data: values,
      color: '#6b8294',
    }];

    // Update the chart to reflect new data
    if (this.chart) {
      this.chart.updateOptions(this.chartOptions);
    }
  }
}