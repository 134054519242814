import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'src/swagger-typescript';
import { LoggedInService } from './logged-in.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private userService: UserService, private loggedInService: LoggedInService) { }

  /** Used to check if a user is authorized to use a specific route - return true, if he is authorized  */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const expectedRole = route.data['expectedRole'];
    if (this.loggedInService.getRole() == expectedRole || this.isAuthenticated()) {
      return true;
    }
    return this.router.createUrlTree(['']);
  }

  /** Check if user is logged in */
  public isAuthenticated(): boolean {
    if (this.loggedInService.getLoggedIn()) {
      return true;
    }
    return false;
  }

}