import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  public title = new BehaviorSubject('Title');
  public username = new BehaviorSubject('');
  public role = new BehaviorSubject('');
  public sodiaphID = new BehaviorSubject('');
  public admissionDate = new BehaviorSubject('');

  public pathButtonVisible = new BehaviorSubject(true);

  /** indicate wheter the user clicked on add new profile */
  public isNewProfileSubject = new BehaviorSubject<boolean>(false);
  isNewProfile$ = this.isNewProfileSubject.asObservable();
  public newProfile: boolean = false;

  constructor() { }

  setTitle(title: string) {
    this.title.next(title);
  }

  setUser(username: string) {
    this.username.next(username);
  }

  getUser() {
    return this.username;
  }

  setRole(role: string) {
    this.role.next(role);
  }

  getRole() {
    return this.role;
  }

  setSodiaphID(sodiaphID: string) {
    this.sodiaphID.next(sodiaphID);
  }

  setAdmissionDate(admissionDate: string) {
    this.admissionDate.next(admissionDate);
  }

  setPathButtonVisible(pathButtonVisible: boolean) {
    this.pathButtonVisible.next(pathButtonVisible);
  }

  private data: any;

  setData(data: any) {
    this.data = data;
  }

  getData() {
    return this.data;
  }

  public getIsNewProfile(): boolean {
    this.isNewProfile$.subscribe((newProfile: boolean) => {
      this.newProfile = newProfile;
    });
    return this.newProfile;
  }

}