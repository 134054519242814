import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { GeneralService } from '../general.service'
import {
  DataQuality,
  DataQualityService,
  DataTransfer,
  DataTransferService,
  Diagnostic,
  DiagnosticDTO,
  DiagnosticService,
  Faculty,
  FacultyService,
  HisDocument,
  HisDocumentFilter,
  HisDocumentFilterDTO,
  HisDocumentSection,
  HisDocumentSectionInfo,
  HisDocumentSectionInfoCreateRequest,
  HisEntry,
  HisEntryCreateRequest,
  HisEntryUpdateRequest,
  HisService,
  InformationCarrier,
  InformationCarrierService,
  PatientPathDTO,
  PatientPathService
} from 'src/swagger-typescript'
import { PathService } from '../path.service'
import { FormControl, NgForm } from '@angular/forms'
import { DatePipe } from '@angular/common'
import { Observable, map, startWith } from 'rxjs'

@Component({
  selector: 'app-create-activity',
  templateUrl: './create-activity.component.html',
  styleUrls: ['./create-activity.component.css']
})
export class CreateActivityComponent implements OnInit {

  /** facultyStartId mat-autocomplete */
  public facultyStartIdCtrl = new FormControl();
  public facultyStartIdfilteredOptions!: Observable<Faculty[]>;
  public facultyStartName: string | undefined = '';

  /** facultyTargetId mat-autocomplete */
  public facultyTargetIdCtrl = new FormControl();
  public facultyTargetIdfilteredOptions!: Observable<Faculty[]>;
  public facultyTargetName: string | undefined = '';

  /** hisDocumentName mat-autocomplete */
  public hisDocumentCtrl = new FormControl();
  public hisDocumentfilteredOptions!: Observable<Faculty[]>;
  public hisDocumentName: string | undefined = '';

  public hisDocumentId: string = '';

  /** informationCarrier Start mat-autocomplete */
  public informationCarrierNames: Array<string> = [];
  // Selected information carrier
  public selectedInformationCarrier: InformationCarrier | undefined;

  /** data for dropdowns */
  public faculties = [] as Array<Faculty>;
  public hisDocumentFilters = [] as Array<HisDocumentFilterDTO>;
  public hisDocumentNames = [] as Array<HisDocument>;
  public diagnostics = [] as Array<DiagnosticDTO>;
  public informationCarriers = [] as Array<InformationCarrier>;
  public dataQualities = [] as Array<DataQuality>;
  public dataTransfers = [] as Array<DataTransfer>;

  public sections = [] as Array<HisDocumentSection>;

  /** sections create request */
  public sectionInfoCreateRequest: Array<HisDocumentSectionInfoCreateRequest> = [];

  /** empty hisEntry object */
  public hisEntry: HisEntry;

  public selectedDiagnosticId: string = '';

  /** empty sections object */
  public loadedSections: any = {};

  /** empty path object to save path by id */
  public path: any = {};

  /** HisEntry to share hisEntry between entry kis and create activity */
  private data: HisEntry;

  /** array of PatientPathDTO to share path between path views and his views */
  private dataPath: PatientPathDTO[] = [];

  private patientPathId: string = '';

  private hisEntryId: string = '';

  /** date pipe to format date */
  pipe = new DatePipe('de-DE');

  @ViewChild('activityForm')
  activityForm!: NgForm;

  @ViewChild('isNotFilledCheckbox', { static: false })
  isNotFilledCheckbox!: ElementRef;

  showPopup: boolean = false;
  popupText: string = '';

  public hisEntryDates: string[] = [];

  repetitions: number = 1; // Default number of repetitions

  constructor(private diagnosticService: DiagnosticService, private dataTransferService: DataTransferService, private dataQualityService: DataQualityService, private informationCarrierService: InformationCarrierService, private patientPathService: PatientPathService, private hisService: HisService, public ownPathService: PathService, private facultyService: FacultyService, public router: Router, private generalService: GeneralService) {
    this.hisEntry = {
      id: '',
      hisDocument: {
        id: '',
        hisDocumentFilter: {
          id: ''
        }
      },
      facultyStart: {
        id: ''
      },
      facultyTarget: {
        id: ''
      },
      diagnostic: {
        id: ''
      },
      sections: [],
      date: ''
    };
    this.data = Object.assign(this.hisEntry);

  }

  ngOnInit(): void {

    this.facultyStartIdfilteredOptions = this.facultyStartIdCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    this.facultyTargetIdfilteredOptions = this.facultyTargetIdCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    this.hisDocumentfilteredOptions = this.hisDocumentCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterHisDocumentNames(value || '')),
    );

    this.hisEntry = {
      id: '',
      hisDocument: {
        id: '',
        hisDocumentFilter: {
          id: ''
        }
      },
      facultyStart: {
        id: ''
      },
      facultyTarget: {
        id: ''
      },
      diagnostic: {
        id: ''
      },
      sections: [],
      date: ''
    };
    this.data = Object.assign(this.hisEntry);

    this.generalService.setTitle('Aktivität anlegen');

    this.facultyService.v1FacultyGet().subscribe(data => {
      this.faculties = data;
    });

    this.hisService.v1HisFilterGet().subscribe(data => {
      this.hisDocumentFilters = data;
    });

    this.informationCarrierService.v1InformationCarrierGet().subscribe(data => {
      this.informationCarriers = data;
    });

    this.dataQualityService.v1DataQualityGet().subscribe(data => {
      this.dataQualities = data;
    });

    this.dataTransferService.v1DataTransferGet().subscribe(data => {
      this.dataTransfers = data;
    });

    // load data if path on table was clicked
    this.dataPath = this.ownPathService.getPath();
    if (this.dataPath) {
      this.path = this.dataPath;
      if (this.path.id) {
        this.patientPathId = this.path.id;
      }
    }

    this.hisEntryDates = this.ownPathService.gethisEntryDates();
    if (this.hisEntryDates.length > 0) {
      // Find the latest date from the array
      const latestDate = this.hisEntryDates.reduce((latest, current) => (current > latest ? current : latest));
      this.hisEntry.date = this.pipe.transform(latestDate, 'yyyy-MM-dd') || '';
    } else {
      this.generalService.admissionDate.subscribe(admissionDate => {
        this.hisEntry.date = admissionDate;
      });
    }

    //check if add new activity button was clicked
    if (this.ownPathService.getisNewActivity()) {
      return;
    }

    // load data if hisEntry on table was clicked
    this.data = this.ownPathService.getHisEntry();
    if (this.data) {
      this.hisEntry = this.data;
      this.hisEntry.date = this.pipe.transform(this.hisEntry.date, 'yyyy-MM-dd') || '';
      if (this.hisEntry.id) {
        this.hisEntryId = this.hisEntry.id;

        this.facultyService.v1FacultyGet().subscribe(data => {
          this.faculties = data;

          //set start faculty
          const facultyStart = this.faculties.find(option => option.id === this.hisEntry.facultyStart.id);
          this.facultyStartName = facultyStart?.name;
          this.facultyStartIdCtrl.setValue(this.facultyStartName);

          //set target faculty
          const facultyTarget = this.faculties.find(option => option.id === this.hisEntry.facultyTarget.id);
          this.facultyTargetName = facultyTarget?.name;
          this.facultyTargetIdCtrl.setValue(this.facultyTargetName);
        });

        //set his document name
        this.hisDocumentName = this.hisEntry.hisDocument.name;
        this.hisDocumentCtrl.setValue(this.hisDocumentName);
      }

      this.hisService.v1HisFilterIdDocumentsGet(this.hisEntry.hisDocument.hisDocumentFilter.id).subscribe(data => {
        this.hisDocumentNames = data;
      });

      this.diagnosticService.v1DiagnosticFilterByHisDocumentHisDocumentIdGet(this.hisEntry.hisDocument.id).subscribe(data => {
        this.diagnostics = data;
      });

      this.hisService.v1HisDocumentIdSectionsGet(this.hisEntry.hisDocument.id).subscribe(data => {
        this.sections = data;
        this.sections.forEach((section) => {
          const sectionInfo = this.hisEntry.sections?.find((sectionInfo: HisDocumentSectionInfo) => sectionInfo?.hisDocumentSection?.id === section.id);
          if (!!sectionInfo && sectionInfo.informationCarrier && sectionInfo.informationCarrier.name) {
            //set informationCarrierName
            this.informationCarrierNames.push(sectionInfo?.informationCarrier?.name);
            //this.informationCarrierIdCtrl.setValue(this.informationCarrierName);

            let tmp = {
              hisDocumentSectionId: sectionInfo.hisDocumentSection?.id,
              name: sectionInfo?.hisDocumentSection?.name,
              informationCarrierId: sectionInfo?.isNotFilled ? undefined : sectionInfo?.informationCarrier?.id,
              dataQualityStartId: sectionInfo?.isNotFilled ? undefined : sectionInfo?.dataQualityStart?.id,
              dataTransferId: sectionInfo?.isNotFilled ? undefined : sectionInfo?.dataTransfer?.id,
              isNotFilled: sectionInfo?.isNotFilled,
            };
            this.sectionInfoCreateRequest.push(tmp);
          }

        });
        this.activityForm.control.updateValueAndValidity();
      });

    }

  }

  private _filter(value: string): Faculty[] {
    const filterValue = value.toLowerCase();
    return this.faculties.filter(faculty => faculty.name?.toLowerCase().includes(filterValue));
  }

  private _filterHisDocumentNames(value: string): Faculty[] {
    const filterValue = value.toLowerCase();
    return this.hisDocumentNames.filter(hisDocumentName => hisDocumentName.name?.toLowerCase().includes(filterValue));
  }

  onInformationCarrierChange(selectedInformationCarrier: InformationCarrier, sectionInfoName: string | undefined): void {
    // Update the selected information carrier property
    this.selectedInformationCarrier = selectedInformationCarrier;
    for (let sectionInfo of this.sectionInfoCreateRequest) {
      if (sectionInfo.name === sectionInfoName) {
        sectionInfo.informationCarrierId = selectedInformationCarrier.id;
      }
    }
  }

  onHisDocumentFilterIdSelected() {
    // Reset the dependent dropdown options

    if (this.hisEntry && this.hisEntry.hisDocument) {
      //ts-ignore
      this.hisEntry.hisDocument.id = '';
    }
    this.hisDocumentName = '';
    this.hisDocumentCtrl.setValue(this.hisDocumentName)
    this.hisDocumentNames = [];
    this.sections = [];
    this.sectionInfoCreateRequest = [];
    this.diagnostics = [];

    this.hisService.v1HisFilterIdDocumentsGet(this.hisEntry?.hisDocument?.hisDocumentFilter?.id || '').subscribe(data => {
      this.hisDocumentNames = data;
    });

    this.hisDocumentfilteredOptions = this.hisDocumentCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterHisDocumentNames(value || '')),
    );
  }

  onHisDocumentIdSectionGet() {
    // Reset the dependent sections options
    this.sections = [];
    this.sectionInfoCreateRequest = [];
    this.diagnostics = [];

    // create Object hisDocumentId
    const hisDocumentNameForm = this.hisDocumentCtrl.value;
    const hisDocumentConst = this.hisDocumentNames.find(option => option.name === hisDocumentNameForm);
    const hisDocumentIdConst = hisDocumentConst?.id;

    // Only make the API request if hisDocumentIdConst is not null, undefined, or empty
    if (hisDocumentIdConst !== undefined && hisDocumentIdConst !== null && hisDocumentIdConst !== "") {

      this.hisDocumentId = hisDocumentIdConst;

      this.hisService.v1HisDocumentIdSectionsGet(this.hisDocumentId).subscribe(data => {
        this.sections = data;

        this.sections.forEach((section) => {

          // Create a section object with the extracted values
          let defaultSectionInfo = {
            hisDocumentSectionId: section.id,
            name: section.name,
            informationCarrierId: section.defaultInformationCarrier?.id,
            dataQualityStartId: section.defaultDataQualityStart?.id,
            dataTransferId: section.defaultDataTransfer?.id,
            isNotFilled: false,
          };

          // Push the section object to the sectionInfoCreateRequest array
          this.sectionInfoCreateRequest.push(defaultSectionInfo);
        });
      });

      this.diagnosticService.v1DiagnosticFilterByHisDocumentHisDocumentIdGet(this.hisDocumentId || '').subscribe(data => {
        this.diagnostics = data;
      });
    }
  }

  saveActivityAndNavToOverview() {

    // update activity
    if (this.hisEntry && !this.ownPathService.getisNewActivity()) {

      const dateForm = this.activityForm.value.date;

      // create Object facultyStart
      const facultyStartIdForm = this.facultyStartIdCtrl.value;
      const facultyStart = this.faculties.find(option => option.name === facultyStartIdForm);
      const facultyStartId = facultyStart?.id;

      // create Object facultyTarget
      const facultyTargetIdForm = this.facultyTargetIdCtrl.value;
      const facultyTarget = this.faculties.find(option => option.name === facultyTargetIdForm);
      const facultyTargetId = facultyTarget?.id;

      // create Object hisDocumentId
      const hisDocumentIdForm = this.hisDocumentCtrl.value;
      const hisDocument = this.hisDocumentNames.find(option => option.name === hisDocumentIdForm);
      const hisDocumentId = hisDocument?.id;

      const diagnosticIdForm = this.activityForm.value.diagnosticId || null;

      const request: HisEntryUpdateRequest = {
        date: dateForm,
        facultyStartId: facultyStartId,
        facultyTargetId: facultyTargetId,
        hisDocumentId: hisDocumentId,
        diagnosticId: diagnosticIdForm,
        sections: this.sectionInfoCreateRequest,
      }

      this.patientPathService.v1PatientPathPatientPathIdHisEntryHisEntryIdPut(request, this.patientPathId, this.hisEntryId).subscribe({
        next: (v) => { },
        error: (e) => {
          let errorMessage = 'An error occurred.';
          if (e.error && e.error.errors && e.error.errors.length > 0) {
            errorMessage = e.error.errors[0].message;
          }
          this.popupText = 'Error: ' + e.status + ' ' + errorMessage;
          this.showPopup = true;
          console.error("v1PatientPathPatientPathIdHisEntryHisEntryIdPut " + e)
        },
        complete: () => { this.router.navigate(['./entry-kis']); }
      });

      /*****************************************************POST******************************************************************/

    } else {

      // Loop through the specified number of repetitions
      for (let i = 0; i < this.repetitions; i++) {

        const dateForm = this.activityForm.value.date;

        // create Object facultyStart
        const facultyStartIdForm = this.facultyStartIdCtrl.value;
        const facultyStart = this.faculties.find(option => option.name === facultyStartIdForm);
        const facultyStartId = facultyStart?.id;

        // create Object facultyTarget
        const facultyTargetIdForm = this.facultyTargetIdCtrl.value;
        const facultyTarget = this.faculties.find(option => option.name === facultyTargetIdForm);
        const facultyTargetId = facultyTarget?.id;

        // create Object hisDocumentId
        const hisDocumentIdForm = this.hisDocumentCtrl.value;
        const hisDocument = this.hisDocumentNames.find(option => option.name === hisDocumentIdForm);
        const hisDocumentId = hisDocument?.id;

        const diagnosticIdForm = this.activityForm.value.diagnosticId || null;

        const request: HisEntryCreateRequest = {
          date: dateForm,
          facultyStartId: facultyStartId,
          facultyTargetId: facultyTargetId,
          hisDocumentId: hisDocumentId,
          diagnosticId: diagnosticIdForm,
          sections: this.sectionInfoCreateRequest,
        }

        this.patientPathService.v1PatientPathPatientPathIdHisEntryPost(request, this.patientPathId).subscribe({
          next: (v) => { },
          error: (e) => {
            let errorMessage = 'An error occurred.';
            if (e.error && e.error.errors && e.error.errors.length > 0) {
              errorMessage = e.error.errors[0].message;
            }
            this.popupText = 'Error: ' + e.status + ' ' + errorMessage;
            this.showPopup = true;
            console.error("v1PatientPathPatientPathIdHisEntryPost " + e)
          },
          complete: () => { this.router.navigate(['./entry-kis']); }
        });

      }

    }

  }

  saveActivityAndNavToNewActivity() {

    // update activity
    if (this.hisEntry && !this.ownPathService.getisNewActivity()) {

      const dateForm = this.activityForm.value.date;

      // create Object facultyStart
      const facultyStartIdForm = this.facultyStartIdCtrl.value;
      const facultyStart = this.faculties.find(option => option.name === facultyStartIdForm);
      const facultyStartId = facultyStart?.id;

      // create Object facultyTarget
      const facultyTargetIdForm = this.facultyTargetIdCtrl.value;
      const facultyTarget = this.faculties.find(option => option.name === facultyTargetIdForm);
      const facultyTargetId = facultyTarget?.id;

      // create Object hisDocumentId
      const hisDocumentIdForm = this.hisDocumentCtrl.value;
      const hisDocument = this.hisDocumentNames.find(option => option.name === hisDocumentIdForm);
      const hisDocumentId = hisDocument?.id;

      const diagnosticIdForm = this.activityForm.value.diagnosticId || null;

      const request: HisEntryUpdateRequest = {
        date: dateForm,
        facultyStartId: facultyStartId,
        facultyTargetId: facultyTargetId,
        hisDocumentId: hisDocumentId,
        diagnosticId: diagnosticIdForm,
        sections: this.sectionInfoCreateRequest,
      }

      this.patientPathService.v1PatientPathPatientPathIdHisEntryHisEntryIdPut(request, this.patientPathId, this.hisEntryId).subscribe({
        next: (v) => { },
        error: (e) => {
          let errorMessage = 'An error occurred.';
          if (e.error && e.error.errors && e.error.errors.length > 0) {
            errorMessage = e.error.errors[0].message;
          }
          this.popupText = 'Error: ' + e.status + ' ' + errorMessage;
          this.showPopup = true;
          console.error("v1PatientPathPatientPathIdHisEntryHisEntryIdPut " + e)
        },
        complete: () => {
          this.ownPathService.isNewActivitySubject.next(true);
          this.facultyStartName = '';
          this.facultyStartIdCtrl.setValue(this.facultyStartName);
          this.facultyTargetName = '';
          this.facultyTargetIdCtrl.setValue(this.facultyTargetName);
          this.hisEntry.hisDocument.hisDocumentFilter.id = ''
          this.hisDocumentName = '';
          this.hisDocumentCtrl.setValue(this.hisDocumentName)
          this.hisDocumentNames = [];
          this.sections = [];
          this.sectionInfoCreateRequest = [];
          this.diagnostics = [];
          this.router.navigate(['./create-activity']);
        }
      });

      /*****************************************************POST******************************************************************/

    } else {

      // Loop through the specified number of repetitions
      for (let i = 0; i < this.repetitions; i++) {

        const dateForm = this.activityForm.value.date;
        // create Object facultyStart
        const facultyStartIdForm = this.facultyStartIdCtrl.value;
        const facultyStart = this.faculties.find(option => option.name === facultyStartIdForm);
        const facultyStartId = facultyStart?.id;

        // create Object facultyTarget
        const facultyTargetIdForm = this.facultyTargetIdCtrl.value;
        const facultyTarget = this.faculties.find(option => option.name === facultyTargetIdForm);
        const facultyTargetId = facultyTarget?.id;

        // create Object hisDocumentId
        const hisDocumentIdForm = this.hisDocumentCtrl.value;
        const hisDocument = this.hisDocumentNames.find(option => option.name === hisDocumentIdForm);
        const hisDocumentId = hisDocument?.id;

        const diagnosticIdForm = this.activityForm.value.diagnosticId || null;

        const request: HisEntryCreateRequest = {
          date: dateForm,
          facultyStartId: facultyStartId,
          facultyTargetId: facultyTargetId,
          hisDocumentId: hisDocumentId,
          diagnosticId: diagnosticIdForm,
          sections: this.sectionInfoCreateRequest,
        }

        this.patientPathService.v1PatientPathPatientPathIdHisEntryPost(request, this.patientPathId).subscribe({
          next: (v) => { },
          error: (e) => {
            let errorMessage = 'An error occurred.';
            if (e.error && e.error.errors && e.error.errors.length > 0) {
              errorMessage = e.error.errors[0].message;
            }
            this.popupText = 'Error: ' + e.status + ' ' + errorMessage;
            this.showPopup = true;
            console.error("v1PatientPathPatientPathIdHisEntryPost " + e)
          },
          complete: () => {
            this.ownPathService.isNewActivitySubject.next(true);
            this.facultyStartName = '';
            this.facultyStartIdCtrl.setValue(this.facultyStartName);
            this.facultyTargetName = '';
            this.facultyTargetIdCtrl.setValue(this.facultyTargetName);
            this.hisEntry.hisDocument.hisDocumentFilter.id = ''
            this.hisDocumentName = '';
            this.hisDocumentCtrl.setValue(this.hisDocumentName)
            this.hisDocumentNames = [];
            this.sections = [];
            this.sectionInfoCreateRequest = [];
            this.diagnostics = [];
            this.repetitions = 1;
            this.router.navigate(['./create-activity']);
          }
        });
      }
    }
  }

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string) {
    this.popupText = "";
    this.showPopup = false;
  }

  getCurrentDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}
