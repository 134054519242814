<div id="wrapper">

  <div class="headerContent">
    <app-header></app-header>
  </div>

  <div class="routerContent">
    <router-outlet></router-outlet>
  </div>

  <footer>
    <div class="toolbar" role="banner">
      <span class="privacyLink" (click)="router.navigate(['./privacy'])">{{'Datenschutz'}}</span>
      <span class="imprintLink" (click)="router.navigate(['./imprint'])">{{'Impressum'}}</span>
      <span class="contactLink" (click)="router.navigate(['./contact'])">{{'Kontakt'}}</span>
      <span class="dataLink" (click)="sendEmail()">{{'Datenanfrage an SODIAPH'}}</span>
    </div>
  </footer>

  <app-popup class="popup" *ngIf="showPopup == true" (answerEvent)="answerEvent($event)" [popupText]="popupText"
    [buttonLeftText]="''" [buttonRightText]="''" [buttonCenterText]="'OK'"> </app-popup>

</div>