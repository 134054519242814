<mat-form-field id="info_form" class="example-full-width">
  <mat-label>Informationsträger (Start)</mat-label>
  <input id="informationCarrierId" type="text" placeholder="Informationsträger (Start)"
         aria-label="informationCarrierId" matInput [formControl]="informationCarrierCtrl" [matAutocomplete]="autoInformationCarrierId">
  <mat-autocomplete #autoInformationCarrierId="matAutocomplete" autoSelectActiveOption [displayWith]="displayFn" (optionSelected)="valueChange($event)">
    <mat-option *ngFor="let informationCarrierItem of informationCarrierFilteredOptions | async"
                [value]="informationCarrierItem">
      {{informationCarrierItem.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
