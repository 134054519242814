import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HisEntry, PatientArchiveDTO, PatientPath } from 'src/swagger-typescript';

@Injectable({
  providedIn: 'root'
})
export class PathService {

  /** indicate wheter the user clicked on add new path */
  public isNewPathSubject = new BehaviorSubject<boolean>(false);
  isNewPath$ = this.isNewPathSubject.asObservable();
  public newPath: boolean = false;

  /** indicate wheter the user clicked on add new activity */
  public isNewActivitySubject = new BehaviorSubject<boolean>(false);
  isNewActivity$ = this.isNewActivitySubject.asObservable();
  public newActivity: boolean = false;

  /** indicate wheter the user clicked on add new document in patient archive*/
  public isNewDocumentSubject = new BehaviorSubject<boolean>(false);
  isNewDocument$ = this.isNewDocumentSubject.asObservable();
  public newDocument: boolean = false;

  private selectedOptionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public selectedOption$: Observable<string> = this.selectedOptionSubject.asObservable();

  /** to share path data */
  private path: any;

  /** to share hisEntry data */
  private hisEntry: HisEntry = {
    id: '',
    hisDocument: {
      id: '',
      hisDocumentFilter: {
        id: ''
      }
    },
    diagnostic: {
      id: ''
    },
    facultyStart: {
      id: ''
    },
    facultyTarget: {
      id: ''
    },
    date: '01-01-1970',
    sections: []
  };

  /** to share ecmEntry data */
  ecmEntry: PatientArchiveDTO = {
    id: '',
    ecmDocumentTypeId: '',
    ecmDocumentTypeAddition: '',
    ecmDocumentGroupId: '',
    ecmDocumentSourceId: '',
    SFID: '',
    isNotArchived: false,
    archiveDate: '',
    isArchiveDateMissing: false,
    isArchiveDateBeforeAdmission: false,
    numPages: 0,
    isPrinted: false,
    ecmDocumentTypeName: '',
  };

  public sfids: string[] = [];
  public hisEntryDates: string[] = [];
  public patientArchiveDates: string [] = [];

  constructor() { }

  setSelectedOption(option: string): void {
    this.selectedOptionSubject.next(option);
  }

  public getisNewPath(): boolean {
    this.isNewPath$.subscribe((newPath: boolean) => {
      this.newPath = newPath;
    });
    return this.newPath;
  }

  public getisNewActivity(): boolean {
    this.isNewActivity$.subscribe((newActivity: boolean) => {
      this.newActivity = newActivity;
    });
    return this.newActivity;
  }

  public getisNewDocument(): boolean {
    this.isNewDocument$.subscribe((newDocument: boolean) => {
      this.newDocument = newDocument;
    });
    return this.newDocument;
  }

  setPath(path: PatientPath) {
    this.path = path;
  }

  getPath() {
    return this.path;
  }

  setHisEntry(hisEntry: HisEntry) {
    this.hisEntry = hisEntry;
  }

  getHisEntry() {
    return this.hisEntry;
  }

  setEcmEntry(ecmEntry: PatientArchiveDTO) {
    this.ecmEntry = ecmEntry;
  }

  getEcmEntry() {
    return this.ecmEntry;
  }

  setSFIDs(sfid: string) {
    this.sfids.push(sfid);
  }

  getSFIDs() {
    return this.sfids;
  }

  sethisEntryDates(hisEntryDate: string) {
    this.hisEntryDates.push(hisEntryDate);
  }

  gethisEntryDates() {
    return this.hisEntryDates;
  }

  setDatesForSpecificSFID(patientArchiveDate: string) {
    this.patientArchiveDates.push(patientArchiveDate);
  }

  resetDatesForSpecificSFID() {
    this.patientArchiveDates = [];
  }

  getDatesForSpecificSFID() {
    return this.patientArchiveDates;
  }
}