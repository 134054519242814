<div class="dialog-header" (mousedown)="startDrag($event)">
    <div style="display: flex; align-items: center;">
        <h2 style="margin-top: 4%;">{{ data.title }}</h2>
        <button #infoButton id="info" style="color: #6b8294; margin-left: 10px;" mat-icon-button
            (mouseenter)="showTooltip()" (mouseleave)="hideTooltip()">
            <mat-icon class="material-symbols-rounded">info</mat-icon>
        </button>
    </div>
    <button mat-icon-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="dialog-content">
    <div *ngIf="data.analysis === 'target_quality_index_vs_origin_quality_index'">
        <app-scatter-plot [icdCode]="data.filter.icd_code" [locationName]="data.filter.location_name"
            [unitType]="data.filter.unit_type" [client]="data.client" [mandantId]="data.mandantId"
            [dataType1]="data.target_quality_index_dataType" [aggregate]="data.aggregate"
            [dataType2]="data.origin_quality_index_dataType" [xAxisLabel]="data.xAxisLabel"
            [yAxisLabel]="data.yAxisLabel"></app-scatter-plot>
    </div>
    <div *ngIf="data.analysis === 'target_interoperability_index_vs_origin_interoperability_index'">
        <app-scatter-plot [icdCode]="data.filter.icd_code" [locationName]="data.filter.location_name"
            [unitType]="data.filter.unit_type" [client]="data.client" [mandantId]="data.mandantId"
            [dataType1]="data.target_interoperability_index_dataType" [aggregate]="data.aggregate"
            [dataType2]="data.origin_interoperability_index_dataType" [xAxisLabel]="data.xAxisLabel"
            [yAxisLabel]="data.yAxisLabel">
            ></app-scatter-plot>
    </div>
    <div *ngIf="data.analysis === 'origin_quality_index_vs_transmission_index'">
        <app-scatter-plot [icdCode]="data.filter.icd_code" [locationName]="data.filter.location_name"
            [unitType]="data.filter.unit_type" [client]="data.client" [mandantId]="data.mandantId"
            [dataType1]="data.origin_quality_index_dataType" [aggregate]="data.aggregate"
            [dataType2]="data.transmission_index_dataType" [xAxisLabel]="data.xAxisLabel"
            [yAxisLabel]="data.yAxisLabel"></app-scatter-plot>
    </div>
    <div *ngIf="data.analysis=== 'origin_quality_index_vs_transmission_index_with_narrow_ellipse'">
        <app-scatter-plot [icdCode]="data.filter.icd_code" [locationName]="data.filter.location_name"
            [unitType]="data.filter.unit_type" [client]="data.client" [mandantId]="data.mandantId"
            [dataType1]="data.origin_quality_index_dataType" [aggregate]="data.aggregate"
            [dataType2]="data.transmission_index_dataType" [xAxisLabel]="data.xAxisLabel"
            [yAxisLabel]="data.yAxisLabel"></app-scatter-plot>
    </div>
    <div *ngIf="data.analysis=== 'target_interoperability_index'">
        <app-boxplot-horizontal [icdCode]="data.filter.icd_code" [locationName]="data.filter.location_name"
            [unitType]="data.filter.unit_type" [client]="data.client" [mandantId]="data.mandantId"
            [dataType]="data.target_interoperability_index_dataType"
            [aggregate]="data.aggregate"></app-boxplot-horizontal>
    </div>
    <div *ngIf="data.analysis=== 'origin_interoperability_index'">
        <app-boxplot-horizontal [icdCode]="data.filter.icd_code" [locationName]="data.filter.location_name"
            [unitType]="data.filter.unit_type" [client]="data.client" [mandantId]="data.mandantId"
            [dataType]="data.origin_interoperability_index_dataType"
            [aggregate]="data.aggregate"></app-boxplot-horizontal>
    </div>
    <div *ngIf="data.analysis=== 'transmission_index'">
        <app-boxplot-horizontal [icdCode]="data.filter.icd_code" [locationName]="data.filter.location_name"
            [unitType]="data.filter.unit_type" [client]="data.client" [mandantId]="data.mandantId"
            [dataType]="data.transmission_index_dataType" [aggregate]="data.aggregate"></app-boxplot-horizontal>
    </div>
    <div *ngIf="data.analysis=== 'transformation_index'">
        <app-boxplot-horizontal [icdCode]="data.filter.icd_code" [locationName]="data.filter.location_name"
            [unitType]="data.filter.unit_type" [client]="data.client" [mandantId]="data.mandantId"
            [dataType]="data.transformation_index_dataType" [aggregate]="data.aggregate"></app-boxplot-horizontal>
    </div>
    <div *ngIf="data.analysis=== 'transformation_score'">
        <app-area-chart [icdCode]="data.filter.icd_code" [locationName]="data.filter.location_name"
            [unitType]="data.filter.unit_type" [client]="data.client" [mandantId]="data.mandantId"
            [dataType]="data.transformation_score_dataType" [aggregate]="data.aggregate"></app-area-chart>
    </div>
    <div *ngIf="data.analysis=== 'DATENÜBERTRAGUNG'">
        <app-histogram [icdCode]="data.filter.icd_code" [locationName]="data.filter.location_name"
            [unitType]="data.filter.unit_type" [client]="data.client" [mandantId]="data.mandantId"
            [dataType]="data.transmission_dataType" [aggregate]="data.aggregate"></app-histogram>
    </div>
    <div *ngIf="data.analysis=== 'gender'">
        <app-stacked-column-chart [icdCode]="data.filter.icd_code" [locationName]="data.filter.location_name"
            [unitType]="data.filter.unit_type" [client]="data.client" [mandantId]="data.mandantId"
            [dataType]="data.gender_dataType" [aggregate]="data.aggregate"></app-stacked-column-chart>
    </div>
    <div *ngIf="data.analysis=== 'age_group'">
        <app-stacked-column-chart-age-group [icdCode]="data.filter.icd_code" [locationName]="data.filter.location_name"
            [unitType]="data.filter.unit_type" [client]="data.client" [mandantId]="data.mandantId"
            [dataType]="data.age_group_dataType" [aggregate]="data.aggregate"></app-stacked-column-chart-age-group>
    </div>

</div>