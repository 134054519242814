<div class="container">
    <mat-grid-list cols="2" rowHeight="1:1">
        <mat-grid-tile><img class="logo" src="assets\Logo_SODIAPH_weiß.png"></mat-grid-tile>
        <mat-grid-tile>
            <mat-card class="loginCard">
                <mat-card-content class="loginContent">
                    <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)">
                        <mat-form-field class="username-form">
                            <mat-label for="username">Benutzername</mat-label>
                            <input id="username" ngModel matInput type="text" value="Benutzername" name="username"
                                required>
                        </mat-form-field>
                        <mat-form-field class="password-form">
                            <mat-label>Passwort</mat-label>
                            <input ngModel matInput type="password" value="Passwort" name="password" required>
                        </mat-form-field>
                        <button [disabled]="!authForm.valid" style="color: white; font-weight: lighter;" mat-button class="loginButton"
                            type="submit">ANMELDEN</button>
                    </form>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
</div>

<app-popup class="popup" *ngIf="showPopup == true" (answerEvent)="answerEvent($event)" [popupText]="popupText"
    [buttonLeftText]="''" [buttonRightText]="''" [buttonCenterText]="'OK'"> </app-popup>