/**
 * Sodiaph Backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DiagnosisDTO } from './diagnosisDTO';

export interface PatientPathDTO { 
    id?: string;
    created?: string;
    updated?: string;
    admission?: string;
    admissionTypeId?: string;
    SID?: string;
    age?: number;
    gender?: PatientPathDTO.GenderEnum;
    diagnoses?: Array<DiagnosisDTO>;
}
export namespace PatientPathDTO {
    export type GenderEnum = 'm' | 'w' | 'd';
    export const GenderEnum = {
        M: 'm' as GenderEnum,
        W: 'w' as GenderEnum,
        D: 'd' as GenderEnum
    };
}