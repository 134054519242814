<div class="container">
    <p>Im Rahmen der Nutzung dieses Tools werden personenbezogene Daten von
        Ihnen durch uns als den für die Datenverarbeitung Verantwortlichen verarbeitet
        und für die Dauer gespeichert, die zur Erfüllung der festgelegten Zwecke und
        gesetzlicher Verpflichtungen erforderlich ist. Im Folgenden informieren wir Sie
        darüber, um welche Daten es sich dabei handelt, auf welche Weise sie
        verarbeitet werden und welche Rechte Ihnen diesbezüglich zustehen.<br><br>
        Personenbezogene Daten sind gemäß Art. 4 Nr. 1 Datenschutzgrundverordnung
        (DSGVO) alle Informationen, die sich auf eine identifizierte oder identifizierbare
        natürliche Person beziehen.</p>
    <h3>Inhalt dieser Datenschutzinformation</h3>
    <p>1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des
        betrieblichen Datenschutzbeauftragten<br><br>
        2. Verarbeitung personenbezogener Daten und Zwecke der Verarbeitung
        2.a) Vor der Nutzung des Tools
        2.b) Während der Nutzung des Tools: Logfiles
        2.b) Während der Nutzung des Tools: Cookies<br><br>
        3. Weitergabe von personenbezogenen Daten an Dritte und Drittländer<br><br>
        4. Betroffenenrechte<br><br>
        5. Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO<br><br>
        6. Datensicherheit<br><br>
        7. Aktualität und Änderung dieser Datenschutzinformation</p>
    <h3>1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des betrieblichen
        Datenschutzbeauftragten</h3>
    <p>Diese Datenschutzinformation gilt für die Datenverarbeitung auf sodiaph.de:</p>
    <h3>Fraunhofer-Gesellschaft
        zur Förderung der angewandten Forschung e.V.</h3>
    <p>Hansastraße 27 c<br>
        80686 München<br><br>
        und deren Institute</p>
    <h3>Fraunhofer-Institut für
        Experimentelles Software Engineering IESE</h3>
    <p>
        Fraunhofer-Platz 1<br>
        E-Mail: info@iese.fraunhofer.de<br>
        Telefon: +49 631 6800-0</p>
    <h3>Fraunhofer-Institut für
        Zelltherapie und Immunologie IZI</h3>
    <p>Perlickstraße 1<br>
        04103 Leipzig<br>
        E-Mail: info@izi.fraunhofer.de<br>
        Telefon: +49 341 35536-1000</p>
    <h3>Fraunhofer-Institut für
        Produktionstechnik und Automatisierung IPA</h3>
    <p>Nobelstraße 12<br>
        70569 Stuttgart<br>
        E-Mail: info@zv.fraunhofer.de<br>
        Telefon: +49 711 970-1800<br><br>
        Der Datenschutzbeauftragte von Fraunhofer ist unter der oben genannten
        Anschrift, zu Händen des Datenschutzbeauftragten bzw. unter
        datenschutz@zv.fraunhofer.de erreichbar.<br><br>
        Sie können sich jederzeit bei Fragen zum Datenschutzrecht oder Ihren
        Betroffenenrechten direkt an unseren Datenschutzbeauftragten wenden.</p>
    <h3>2. Verarbeitung personenbezogener Daten und Zwecke der
        Verarbeitung</h3>
    <h3>a) Vor der Nutzung des Tools</h3>
    <p>Sie können dieses Tool verwenden, ohne Ihre Identität gegenüber dem
        Verantwortlichen preisgeben zu müssen. Dies wird über pseudonyme
        Funktionsaccounts zur Erfassung von Daten zu Behandlungspfaden für Ihren
        Mandanten (Klinik oder Versorgungseinrichtung) erreicht. Wir erzeugen auf
        Wunsch des Mandanten eine bestimmte Anzahl von pseudonymen
        Funktionsaccounts zur Datenerfassung, die wir an die Ansprechperson für das
        Projekt SODIAPH beim Mandanten weitergeben. Dort werden die
        Funktionsaccounts an diejenigen Personen (je Person ein Funktionsaccount)
        weitergegeben, die mit der Erfassung von Daten zu Behandlungspfaden betraut
        sind. Dabei nimmt der Mandant (die Ansprechperson des Mandanten) die
        Zuordnung der Funktionsaccounts zu natürlichen Personen vor und übermittelt
        diese nicht an den Verantwortlichen.<br><br>
        Die Anlage, Speicherung und Nutzung von pseudonymen Funktionsaccounts und
        Passwörtern dient dem Zweck nur berechtigten Personen eines Mandanten den
        Zugang und damit die Erfassung von Daten mit dem Tool zu ermöglichen.<br><br>
        Rechtsgrundlage hierfür ist das berechtigte Interesse [DSGVO Art. 6 (1) lit. f],
        dass nur von einer beschränkten Anzahl fachkundigen Personals des jeweiligen
        Mandanten Daten im Tool erfasst werden können. Dabei ist eine Identifikation der
        Personen nicht unmittelbar erforderlich, weshalb pseudonyme Funktionsaccounts
        eingesetzt werden.</p>
    <h3>b) Während der Nutzung des Tools: Logfiles (Login/Logout)</h3>
    <p>Sie können dieses Tools verwenden, ohne Angaben zu Ihrer Identität preisgeben
        zu müssen. Der auf Ihrem Endgerät eingesetzte Browser sendet eine Reihe von
        Informationen an den Server.<br><br>Diese sind beim Login:<br><br>
        §
        Name des pseudonymen Accounts<br>
        §
        Datum und Uhrzeit<br>
        §
        Browser Typ, Browser Version<br>
        §
        IP-Adresse im IPv4-Format,<br>
        §
        Betriebssystem-Version des zugreifenden Gerätes<br>
        §
        erfolgreiches Login/Fehler<br>
        §
        Fehlertyp (Accountname falsch oder Passwort falsch)<br><br>
        Diese sind beim Logout:<br><br>
        §
        Name des pseudonymen Accounts<br>
        §
        Autologout/user logout<br>
        §
        Datum und Uhrzeit<br>
        §
        Browser Typ, Browser Version<br>
        §
        IP-Adresse im IPv4-Format<br>
        §
        Betriebssystem-Version des zugreifenden Gerätes.<br><br>
        Die Protokollierung und Auswertung der Login/Logout Aktivitäten der
        pseudonymen Nutzer dient den Zwecken<br><br>
        1. der (späteren) Nachvollziehbarkeit von möglichen Angriffs- und
        Missbrauchsversuchen,<br>
        2. der Ermittlung von verwendeten Endgeräten und Browsern zur
        Optimierung der Benutzeroberfläche in zukünftigen Versionen des Tools.<br><br>
        Rechtsgrundlage für (1) ist berechtigtes Interesse des Verantwortlichen [DSGVO
        Art. 6 (1) lit. f]: wir wollen wissen, falls unser Dienst missbraucht wird, um im
        Bedarfsfall Gegenmaßnahmen einleiten zu können.<br><br>
        Rechtsgrundlage für (2) ist berechtigtes Interesse des Verantwortlichen [DSGVO
        Art. 6 (1) lit. f]: wir möchten im Laufe der Zeit erkennen, welche Browser und
        Endgeräte mehrheitlich von den Nutzern eingesetzt werden, um die
        Benutzerschnittstelle und damit die Bedienbarkeit (Usability und User
        Experience) des Tools fortlaufend optimieren zu können. Hierfür ist insbesondere
        eine Kenntnis der Browser, Endgeräte und Betriebssystem-Versionen erforderlich
        um über ggfs. auch endgerätespezifische Frontend Designs informiert
        entscheiden zu können.<br><br>
        Weiterhin erfassen wir, ob Ihnen die aktuelle Version dieser
        Datenschutzinformation zur Kenntnis gebracht wurde. Im Fall Ihres ersten Logins
        am Tool oder eines Logins am Tool nach Aktualisierung dieser
        Datenschutzinformation wird Ihnen der Text zur Kenntnisnahme in einem
        modalen Dialog eingeblendet, den sie nach Lesen der Datenschutzinformation
        mittels des Buttons „Zur Kenntnis genommen“ beenden können. Danach können
        sie mit der Erfassung der Daten beginnen oder daran weiterarbeiten. Wir
        speichern zu Ihrer aktuellen Kenntnisnahme Datum (Tag und Uhrzeit) beim
        Funktionsaccount ab. Diese Information wird aus rechtlichen Gründen nicht
        gelöscht. Der Zweck dieser Verarbeitung ist der Nachweis, dass Ihnen die
        Datenschutzinformation zur Kenntnis gebracht wurde und begründet sich in
        unserer gesetzlichen Verpflichtung [DSGVO Art. 6 (1) S. 1 lit. c], sie über
        Verarbeitung von personenbezogenen und personenbeziehbaren Daten, deren
        Kategorien und Rechtsgrundlagen zu informieren.</p>
    <h3>c) Während der Nutzung des Tools: Cookies (Erfassung von Daten zu
        Behandlungspfaden)</h3>
    <p>Wir setzen in unserem Tool Cookies ein. Hierbei handelt es sich um kleine
        Dateien, die automatisch erstellt werden und die auf Ihrem Endgerät (Laptop,
        Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unser Tool verwenden.
        Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren,
        Trojaner oder sonstige Schadsoftware.<br><br>
        In dem Cookie werden Informationen abgelegt, die zur Authentifizierung und
        Autorisierung Ihrer Berechtigung für Ihre Klinik bzw. Versorgungseinrichtung
        Daten zu Behandlungsprozessen zu erfassen, dienen. Dies bedeutet vor allem
        nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten.<br><br>
        Die Cookies werden für die Dauer Ihrer Arbeitssitzung mit dem Tool erstellt und
        haben ein Löschzeitpunkt von 60 min nach Ihrer letzten Aktivität am Tool.<br><br>
        Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur
        Wahrung unserer berechtigten Interessen nach [DSGVO Art. 6 (1) lit. f]
        erforderlich.</p>
    <h3>3. Weitergabe von personenbezogenen Daten an Dritte und Drittländer</h3>
    <p>Eine Übermittlung von personenbezogenen Daten an ein Drittland (außerhalb der
        EU) oder eine internationale Organisation ist ausgeschlossen.<br><br>
        Wir geben personenbezogene Daten nur an Dritte weiter, wenn für die
        Weitergabe nach [DSGVO Art. 6 (1) lit. C] eine gesetzliche Verpflichtung besteht.
        Dieser Fall kann eintreten, wenn nachweislich Angriffe oder Missbrauch mit Hilfe
        des ausgegebenen Funktionsaccounts stattgefunden haben oder stattfinden.</p>
    <h3>4. Betroffenenrechte</h3>
    <p>Sie haben das Recht:<br><br>
        1. gemäß DSGVO Art. 15 Auskunft über Ihre von uns verarbeiteten
        personenbezogenen Daten zu verlangen. Insbesondere können Sie
        Auskunft über die Verarbeitungszwecke, die Kategorie der
        personenbezogenen Daten, die Kategorien von Empfängern, gegenüber
        denen Ihre Daten offengelegt wurden oder werden, die geplante
        Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung,
        Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines
        Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns
        erhoben wurden, sowie über das Bestehen einer automatisierten
        Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen
        Informationen zu deren Einzelheiten verlangen;
        2. gemäß DSGVO Art. 16 unverzüglich die Berichtigung unrichtiger oder
        Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten
        zu verlangen;<br>
        3. gemäß DSGVO Art. 17 die Löschung Ihrer bei uns gespeicherten
        personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur
        Ausübung des Rechts auf freie Meinungsäußerung und Information, zur
        Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen
        Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen erforderlich ist;<br>
        4. gemäß DSGVO Art. 18 die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten
        von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber
        deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie
        jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen benötigen oder Sie gemäß DSGVO Art. 21 Widerspruch
        gegen die Verarbeitung eingelegt haben;<br>
        5. gemäß DSGVO Art. 20 Ihre personenbezogenen Daten, die Sie uns
        bereitgestellt haben, in einem strukturierten, gängigen und
        maschinenlesebaren Format zu erhalten oder die Übermittlung an einen
        anderen Verantwortlichen zu verlangen und<br>
        6. gemäß DSGVO Art. 77 sich bei einer Aufsichtsbehörde zu beschweren. In
        der Regel können Sie sich hierfür an die Aufsichtsbehörde ihres üblichen
        Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes
        wenden.<br><br>
        Für die Fälle 1 bis 5 wenden Sie sich bitte an Ihre Ansprechperson in Ihrem
        Hause. Diese wird Ihre Anfrage an uns an
        datenschutz@iese.fraunhofer.de
        unter Nennung des Funktionsaccounts weiterleiten. Wir werden das Ergebnis
        Ihrer Anfrage an Sie über Ihre Ansprechperson weiterleiten, von wo aus Sie das
        Ergebnis Ihrer Anfrage erhalten.</p>
    <h3>5. Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h3>
    <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation
        ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener
        Daten, die aufgrund von DSGVO Art 6 (1) lit. e (Datenverarbeitung im öffentlichen
        Interesse) und DSGVO Art. 6 (1) lit. f (Datenverarbeitung auf der Grundlage einer
        Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf
        diese Bestimmung gestütztes Profiling von DSGVO Art. 4 Nr. 4.<br><br>
        Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht
        mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für
        die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
        überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen. Gleichzeitig erfolgt die Sperrung des Ihnen
        zugewiesenen Funktionsaccounts und nach 30 Tagen die Löschung der Logfiles
        der Aktivitätsdaten.<br><br>
        Ihren Widerspruch können Sie ebenfalls über Ihre Ansprechperson in Ihrem Hause
        an uns übermitteln lassen.<br><br>
        Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt eine E-Mail
        unter Nennung des Funktionsaccounts an
        datenschutz@iese.fraunhofer.de
        durch die Ansprechperson in Ihrem Hause.</p>
    <h3>6. Datensicherheit</h3>
    <p>Alle von Ihnen persönlich übermittelten Daten werden mit dem allgemein
        üblichen und sicheren Standard TLS (Transport Layer Security) verschlüsselt
        übertragen. TLS ist ein sicherer und erprobter Standard, der z.B. auch beim
        Onlinebanking Verwendung findet. Sie erkennen eine sichere TLS-Verbindung
        unter anderem an dem angehängten s am http (also https://..) in der Adressleiste
        Ihres Browsers oder am Schloss-Symbol im unteren Bereich Ihres Browsers.<br><br>
        Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer
        Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche
        Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen
        den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen
        werden entsprechend der technologischen Entwicklung fortlaufend verbessert.</p>
    <h3>7. Aktualität und Änderung dieser Datenschutzinformation</h3>
    <p>Diese Datenschutzinformation ist aktuell gültig und hat den Stand Juni 2023;
        Version 1.<br><br>
        Durch die Weiterentwicklung unseres Tools oder aufgrund geänderter
        gesetzlicher bzw. behördlicher Vorgaben kann es notwendig werden, diese
        Datenschutzinformation zu ändern. Die jeweils aktuelle Datenschutzinformation
        kann jederzeit in der Fußzeile des Tools abgerufen und ausgedruckt werden.
        Ebenso wird Ihnen nach einer Aktualisierung der Datenschutzinformation die
        aktuelle Datenschutzinformation aktiv als modaler Dialog beim nächsten Login
        nach Aktualisierung eingeblendet.
    </p>
</div>