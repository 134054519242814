import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable({
  providedIn: 'root'
})
export class DateAdapterService extends NativeDateAdapter {

  override parse(value: any): Date | null {
    const dateArray = value.split('.');
    if (dateArray.length === 3) {
      const day = +dateArray[0];
      const month = +dateArray[1] - 1; // months are 0-based
      const year = +dateArray[2];
      return new Date(Date.UTC(year, month, day));
    }
    return super.parse(value);
  }
}