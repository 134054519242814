import { Component, Input, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import { catchError, forkJoin } from "rxjs";
import { DataService } from "src/python-services/data-service";
import { AnalysisService } from "src/swagger-typescript/api/analysis.service";

@Component({
  selector: 'app-scatter-plot',
  templateUrl: './scatter-plot.component.html',
  styleUrls: ['./scatter-plot.component.css']
})
export class ScatterPlotComponent {

  @ViewChild("chart")
  chart!: ChartComponent;
  public chartOptions: any;

  @Input() client: string = '';
  @Input() mandantId: string = '';
  @Input() clinic: string[] = [];
  @Input() dataType1: string = '';
  @Input() aggregate: string = '';
  @Input() dataType2: string = '';
  @Input() icdCode: string[] = [];
  @Input() locationName: string[] = [];
  @Input() unitType: string[] = [];
  @Input() xAxisLabel: string = '';
  @Input() yAxisLabel: string = '';
  keyOrigin: string = '';

  constructor(private analysisService: AnalysisService, private dataService: DataService) {
    this.chartOptions = {
      series: [],
      chart: {
        height: 450,
        type: 'scatter',
        zoom: {
          enabled: true,
          type: 'xy'
        }
      },
      xaxis: {
        min: 0,
        max: 100,
        tickAmount: 5,
        labels: {
          formatter: function (val: string) {
            return parseInt(val, 10).toString();
          }
        },
        title: {
          text: this.xAxisLabel
        }
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 5,
        labels: {
          formatter: function (val: string) {
            return parseInt(val, 10).toString();
          }
        },
        title: {
          text: this.yAxisLabel
        }
      },
      annotations: {
        ellipse: [
          {
            x1: -0.25,
            y1: 0.7,
            x2: 0.25,
            y2: 1.0,
            fillColor: '#B3EFFF',
            opacity: 0.2,
            borderColor: '#00E5FF',
            borderWidth: 1
          },
          {
            x1: -3,
            y1: 0,
            x2: -0.25,
            y2: 0.7,
            fillColor: '#FFD1B3',
            opacity: 0.2,
            borderColor: '#FF5733',
            borderWidth: 1
          }
        ]
      },
      colors: ['#008FFB'],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.5,
          gradientToColors: ['#FF5733'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      }
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["client"] || changes["mandantId"] || changes["dataType1"] || changes["dataType2"]) {
      this.loadDataAndRenderChart();
    }
  }

  loadDataAndRenderChart(): void {
    const filter = {
      icd_code: this.icdCode,
      location_name: this.locationName,
      unit_type: this.unitType
    };

    const filterString = filter;

    forkJoin([
      this.analysisService.v1AnalysisGetDataPost({
        client: this.client,
        mandantId: this.mandantId,
        data_type: this.dataType1,
        aggregate: this.aggregate,
        filter: filterString,
        create_network: 0
      }).pipe(
        catchError(error => {
          console.error("Error fetching target data:", error);
          return [];
        })
      ),
      this.analysisService.v1AnalysisGetDataPost({
        client: this.client,
        mandantId: this.mandantId,
        data_type: this.dataType2,
        aggregate: this.aggregate,
        filter: filterString,
        create_network: 0
      }).pipe(
        catchError(error => {
          console.error("Error fetching origin data:", error);
          return [];
        })
      )
    ]).subscribe(([targetData, originData]) => {
      try {
        this.handleData(targetData, originData);
      } catch (error) {
        console.error('Error parsing JSON data:', error);
      }
    });

    // Set axis labels
    this.chartOptions.xaxis.title.text = this.xAxisLabel;
    this.chartOptions.yaxis.title.text = this.yAxisLabel;

    if (this.chart) {
      this.chart.updateOptions(this.chartOptions);
    }
  }

  handleData(targetData: any, originData: any): void {
    const parsedTargetData = JSON.parse(targetData);
    const parsedOriginData = JSON.parse(originData);

    const firstKeyTarget = Object.keys(parsedTargetData)[0];
    const firstKeyOrigin = Object.keys(parsedOriginData)[0];

    const target_index = parsedTargetData[firstKeyTarget];
    const origin_index = parsedOriginData[firstKeyOrigin];
    Object.keys(origin_index).forEach(keyOrigin => { this.keyOrigin = keyOrigin; });

    Object.keys(target_index).forEach((key, index) => {
      if (Array.isArray(target_index[key])) {
        if (Array.isArray(origin_index[key])) {
          const data = target_index[key].map((val: any, index: string | number) => {
            if (origin_index[key].length > index) {
              return [origin_index[key][index], val];
            } else {
              console.error(`Index ${index} does not exist in origin_index[${key}]`);
              return null;
            }
          }).filter((item: any) => item !== null && item !== undefined);


          this.chartOptions.series.push({ name: key, data: data});
        } else {
          const data = target_index[key].map((val: any, index: string | number) => {
            if (origin_index[this.keyOrigin].length > index) {
              return [origin_index[this.keyOrigin][index], val];
            } else {
              return null;
            }
          }).filter((item: any) => item !== null && item !== undefined);

          this.chartOptions.series.push({ name: key, data: data });
        }
      } else {
        console.error(`Data for key ${key} in target_index is not an array.`);
      }
    });
  }
}