<div id="popupDiv">
  <div id="containerDiv" class="container">
    <div id="textDiv" class="text">{{popupText}}</div>

    <!-- dropdown -->
    <div *ngIf="selectOptions.length > 0">
      <mat-form-field style="margin-top: 60%;">
        <mat-label for="selectOption">Bitte auswählen</mat-label>
        <mat-select id="selectOption" #selectBox (selectionChange)="onOptionSelected(selectBox.value)">
          <mat-option *ngFor="let option of selectOptions" [value]="option">{{option}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Freetext input field -->
      <div *ngIf="showFreetextField">
        <mat-form-field>
          <mat-label for="freetextInput">Hinterlassen sie einen Kommentar</mat-label>
          <div id="comment">
            <textarea matInput id="freetextInput" [(ngModel)]="freetextValue"></textarea>
          </div>
        </mat-form-field>
      </div>

    <div *ngIf="buttonLeftText != ''" class="buttonLeft" (click)="buttonLClicked()" (click)="emitAnswer('left')"><span
        class="textSpan">{{buttonLeftText}}</span></div>
    <div *ngIf="buttonRightText != ''" class="buttonRight" (click)="buttonRClicked()" (click)="emitAnswer('right')">
      <span class="textSpan">{{buttonRightText}}</span>
    </div>
    <div *ngIf="buttonCenterText != ''" class="buttonCenter" (click)="buttonCClicked()" (click)="emitAnswer('center')">
      <span class="textSpan">{{buttonCenterText}}</span>
    </div>
  </div>
</div>