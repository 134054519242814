/**
 * Sodiaph Backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ICD } from './iCD';
import { PatientPath } from './patientPath';

export interface Diagnosis { 
    id?: string;
    icd?: ICD;
    patientPath?: PatientPath;
    diagnosisType?: Diagnosis.DiagnosisTypeEnum;
    isMissingInDischargeLetter?: boolean;
}
export namespace Diagnosis {
    export type DiagnosisTypeEnum = 'ADMISSION' | 'MAIN' | 'SIDE';
    export const DiagnosisTypeEnum = {
        ADMISSION: 'ADMISSION' as DiagnosisTypeEnum,
        MAIN: 'MAIN' as DiagnosisTypeEnum,
        SIDE: 'SIDE' as DiagnosisTypeEnum
    };
}