import { Component } from '@angular/core';
import { GeneralService } from '../general.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {

  constructor(private generalService: GeneralService) {

  }

  ngOnInit() {

    this.generalService.setTitle('Kontakt');

  }
}