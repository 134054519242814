import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GeneralService } from '../general.service';
import { HisEntry, HisEntryService, PatientPathDTO } from 'src/swagger-typescript';
import { PathService } from '../path.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-entry-kis',
  templateUrl: './entry-kis.component.html',
  styleUrls: ['./entry-kis.component.css']
})
export class EntryKISComponent implements OnInit {

  hisEntrys = [] as Array<HisEntry>;

  displayedColumns: string[] = ['hisDocument',
    'date',
    'facultyStart',
    'updated', 'delete'];

  dataSource!: MatTableDataSource<HisEntry>;

  pipe = new DatePipe('de-DE');

  /** id from path to create activity for*/
  private patientPathId: string = '';

  /** array of PatientPathDTO to share path between path overview an new path component */
  private data: PatientPathDTO[] = [];

  /** empty path object to save path by id */
  public path: any = {};

  @ViewChild(MatSort, { static: false })
  sort: MatSort = new MatSort

  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;

  showPopup = false;
  popupText = '';
  elementToDelete = '';

  constructor(public ownPathService: PathService, private hisEntryService: HisEntryService, public router: Router, private generalService: GeneralService) {
  }

  ngOnInit(): void {
    this.generalService.setTitle('Eintrag KIS')

    //check if add new path button was clicked
    if (this.ownPathService.getisNewPath()) {
      return;
    }

    // load data if path on table was clicked
    this.data = this.ownPathService.getPath();
    if (this.data) {
      this.path = this.data;
      this.generalService.setSodiaphID(this.path.SID);
      this.path.admissionDate = this.pipe.transform(this.path.admissionDate, 'yyyy-MM-dd');
      if (this.path.id) {
        this.patientPathId = this.path.id;
      }
    }

    this.dataSource = new MatTableDataSource<HisEntry>([]);
    this.hisEntryService.v1PatientPathPatientPathIdHisEntryGet(this.patientPathId).subscribe({
      next: (v) => {
        this.hisEntrys = v;
        this.dataSource.data = this.hisEntrys;
        this.dataSource.sort = this.sort;

        // Update the paginator
        if (this.hisEntrys.length != 0 && this.paginator != undefined) {
          this.dataSource.paginator = this.paginator;
          this.paginator.length = this.hisEntrys.length;
          this.paginator.pageIndex = 0;
          this.paginator.pageSize = 15;
          this.paginator.page.emit();
        }
        // set date for default onput
        this.hisEntrys.forEach(hisEntry => {
          this.ownPathService.sethisEntryDates(hisEntry.date);
        });
      },
      error: (e) => { console.error(e) },
      complete: () => { }
    });

  }

  loadActivity(activity: HisEntry) {
    this.ownPathService.isNewActivitySubject.next(false);
    this.ownPathService.setHisEntry(activity);
    this.router.navigate(['create-activity']);
  }

  deleteRow(hisEntryId: string) {
    this.hisEntryService.v1PatientPathPatientPathIdHisEntryHisEntryIdDelete(this.patientPathId, hisEntryId).subscribe({
      next: (v) => {
        this.hisEntryService.v1PatientPathPatientPathIdHisEntryGet(this.patientPathId).subscribe({
          next: (v) => {
            this.hisEntrys = v;
            this.dataSource.data = this.hisEntrys;
            this.dataSource.sort = this.sort;
          },
          error: (e) => { console.error(e) },
          complete: () => { }
        });
      },
      error: (e) => { console.error('v1PatientPathPatientPathIdHisEntryHisEntryIdDelete ' + e) },
      complete: () => { }
    });
  }

  onNewActivityClick() {
    this.ownPathService.isNewActivitySubject.next(true);
  }

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string) {
    this.popupText = "";
    this.showPopup = false;
  }

  deleteActivity() {
    this.deleteRow(this.elementToDelete);
  }

}
