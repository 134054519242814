import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core'
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatOptionModule } from '@angular/material/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { AsyncPipe, NgForOf } from '@angular/common'
import { map, Observable, startWith } from 'rxjs'
import { InformationCarrier } from '../../../swagger-typescript'

@Component({
  selector: 'app-information-carrier-auto-complete',
  standalone: true,
  imports: [MatAutocompleteModule, MatFormFieldModule, MatInputModule, MatOptionModule,
    ReactiveFormsModule, NgForOf, AsyncPipe],
  templateUrl: './information-carrier-auto-complete.component.html',
  styleUrls: ['./information-carrier-auto-complete.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformationCarrierAutoCompleteComponent implements OnInit {
  @Input() public informationCarriers: Array<InformationCarrier> = [];
  @Input() public informationCarrierId: string = '';

  @Output() public informationCarrierChange: EventEmitter<InformationCarrier> = new EventEmitter<InformationCarrier>();

  public informationCarrierCtrl = new FormControl();
  public informationCarrierFilteredOptions!: Observable<InformationCarrier[]>;
  public informationCarrier!: InformationCarrier;

  public valueChange(value: MatAutocompleteSelectedEvent): void {
    this.informationCarrierChange.emit(value.option.value);
  }

  private _filterInformationCarrierStart(value: string): InformationCarrier[] {
    const filterValue = value.toLowerCase();
    return this.informationCarriers.filter(informationCarrier => informationCarrier.name?.toLowerCase().includes(filterValue));
  }

  public displayFn(informationCarrier?: InformationCarrier): string {
    return informationCarrier && informationCarrier.name ? informationCarrier.name : '';
  }

  ngOnInit(): void {
    this.informationCarrier = this.informationCarriers.find((informationCarrier) => informationCarrier.id === this.informationCarrierId) || { id: '', name: '' };
    this.informationCarrierFilteredOptions = this.informationCarrierCtrl.valueChanges.pipe(
      startWith(this.informationCarrier || ''),
      map(value => typeof value === 'string' ? value : value.name),
      map(value => this._filterInformationCarrierStart(value || ''))
    );
    this.informationCarrierCtrl.setValue(this.informationCarrier);
  }
}