import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MandantDTO, MandantService, State, StateService } from 'src/swagger-typescript';
import { GeneralService } from '../general.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-create-user-account',
  templateUrl: './create-user-account.component.html',
  styleUrls: ['./create-user-account.component.css']
})
export class CreateUserAccountComponent implements OnInit {

  /** user information how many accounts have been created */
  public accCount: string = '';

  public saved_hint: string = '';

  /** information about uploaded excel sheet */
  public uploaded: string = '';

  /** check if user input was saved */
  public formSaved: boolean = false;

  /** check if user tries to add a user account or an excel sheet */
  public rightSectionClicked: boolean = false;

  /** used to indicate if popup is shown */
  public showPopup: boolean = false;
  public show: boolean = false;

  /** text which is shown on popup */
  public popupText: string = "";

  /** array of states */
  public states = [] as Array<State>;

  /** todo endpoint backend */
  lands = ['Deutschland'];

  /** id, created and userCount from mandat that was created */
  private idMandant: string = '';

  /** date pipe to format date */
  pipe = new DatePipe('de-DE');

  /** to check if form was send */
  public disabled: boolean = true;

  /** to check if form was send */
  public disabledUpload: boolean = true;

  /** empty mandant object to save mandant by id */
  public mandant: any = {};

  @ViewChild('mandantForm')
  mandantForm!: NgForm;

  /** array of MandantDTO to share mandant between user orga an create mandant component */
  private data: MandantDTO[] = [];

  /** check if the user clicked on a field */
  public fieldClicked: boolean = false

  constructor(private router: Router, private generalService: GeneralService, private mandantService: MandantService, private stateService: StateService) {
    this.mandant.state = ''
  }

  ngOnInit(): void {
    this.generalService.setTitle('Benutzerorganisationen verwalten');

    this.stateService.v1StateGet().subscribe(data => {
      this.states = data;
    })

    this.rightClicked(true);

    //check if add new profile button was clicked
    if (this.generalService.getIsNewProfile()) {
      return;
    }

    // load data if mandant on table was clicked
    this.data = this.generalService.getData();
    if (this.data) {
      this.saved(true);
      this.mandant = this.data;
      this.mandant.expires = this.pipe.transform(this.mandant.expires, 'yyyy-MM-dd', 'GMT')
      if (this.mandant.id) {
        this.idMandant = this.mandant.id;
      }
      this.disabled = false;
      const section2 = document.getElementById("section2");
      if (section2) {
        section2.style.opacity = "1";
        this.rightClicked(false);
      }
      const upload1 = document.getElementById("upload1");
      if (upload1) {
        upload1.style.opacity = "0.5";
        this.rightClicked(false);
      }
      if (this.mandant.userCount > 0) {
        this.accCount = 'Es wurden insgesamt ' + this.mandant.userCount + ' Benutzeraccounts angelegt.';
      } else {
        this.accCount = 'Es wurden noch keine Benutzeraccounts angelegt'
      }
    }

  }

  /** Send Mandant post request on submit button or edit it if data is loaded */
  onSubmit(form: NgForm) {

    // update mandant
    if (this.mandant && !this.generalService.getIsNewProfile()) {

      const accountPrefixForm = form.value.accountPrefix;
      const expiresForm = form.value.expires;
      const nameForm = form.value.name;
      const streetForm = form.value.street;
      const streetNumberForm = form.value.streetNumber;
      const zipForm = form.value.zip;
      const cityForm = form.value.city;
      const stateForm = form.value.state;
      const legalFormForm = form.value.legalForm;

      const request = {
        accountPrefix: accountPrefixForm,
        expires: expiresForm,
        name: nameForm,
        street: streetForm,
        streetNumber: streetNumberForm,
        zip: zipForm,
        city: cityForm,
        state: stateForm,
        legalForm: legalFormForm,
      }
      this.mandantService.v1MandantIdPut(request, this.idMandant).subscribe({
        next: (v) => { },
        error: (e) => { console.error("v1MandantIdPut " + e) },
        complete: () => { this.saved_hint = 'Die Organisation wurde erfolgreich aktualisiert.'; }
      });

    } else {

      // create Object Mandant
      const accountPrefixForm = form.value.accountPrefix;
      const expiresForm = form.value.expires;
      const nameForm = form.value.name;
      const streetForm = form.value.street;
      const streetNumberForm = form.value.streetNumber;
      const zipForm = form.value.zip;
      const cityForm = form.value.city;
      const stateForm = form.value.state;
      const legalFormForm = form.value.legalForm;

      const request = {
        accountPrefix: accountPrefixForm,
        expires: expiresForm,
        name: nameForm,
        street: streetForm,
        streetNumber: streetNumberForm,
        zip: zipForm,
        city: cityForm,
        state: stateForm,
        legalForm: legalFormForm,
      }

      this.mandantService.v1MandantPost(request).subscribe({
        next: (v) => {
          if (v.id) {
            this.idMandant = v.id;
          }
        },
        error: (e) => console.error("v1MandantPost " + e),
        complete: () => {
          this.saved_hint = 'Die Organisation wurde erfolgreich angelegt.';
          this.disabled = false;
          this.disabledUpload = false;
          // show create user form and enable buttons
          const section2 = document.getElementById("section2");
          if (section2) {
            section2.style.opacity = "1";
          }
        }
      });

    }
  }

  saved(click: boolean) {
    this.formSaved = click;
  }

  rightClicked(click: boolean) {
    this.rightSectionClicked = click;
  }

  checkFormSaved() {
    // check if mandant form was saved or if it is empty or if data is loaded
    if (this.formSaved || !this.mandantForm.valid) {
      this.router.navigate(['/user-organization']);
    }
    this.popupText = "Ihre Daten wurden noch nicht gespeichert.";
    this.showPopup = true;
  }

  checkrightClicked() {
    if (this.rightSectionClicked) {
      if (!this.mandantForm.valid || !this.formSaved) {
        this.popupText = "Für das Anlegen von Benutzeraccounts und den Upload von Daten müssen zuerst die Accountdaten der Organisation auf der linken Seite eingetragen und gespeichert werden.";
        this.show = true;
      }
    }
  }

  /** create user accounts */
  onSubmitAcc(form: NgForm) {
    const usersCountForm = form.value.amount;
    const request = { amount: usersCountForm };
    this.mandantService.v1MandantIdUserPost(request, this.idMandant).subscribe({
      next: (v) => {
        this.accCount = 'Es wurden insgesamt ' + v.userCount + ' Benutzeraccounts angelegt.';
      },
      error: (e) => console.error("v1MandantIdUserPost " + e),
      complete: () => { }
    });
  }

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string) {
    this.popupText = "";
    this.showPopup = false;
    this.show = false;
  }

  /** methods for popup buttons */

  // navigate to overview without saving the form
  navToOverview() {
    this.router.navigate(['/user-organization']);
  }

  // save mandant and naviagte to overview
  saveMandantAndNavToOverview() {
    // create Object Mandant
    const accountPrefixForm = this.mandantForm.value.accountPrefix;
    const expiresForm = this.mandantForm.value.expires;
    const nameForm = this.mandantForm.value.name;
    const streetForm = this.mandantForm.value.street;
    const streetNumberForm = this.mandantForm.value.streetNumber;
    const zipForm = this.mandantForm.value.zip;
    const cityForm = this.mandantForm.value.city;
    const stateForm = this.mandantForm.value.state;
    const legalFormForm = this.mandantForm.value.legalForm;

    const request = {
      accountPrefix: accountPrefixForm,
      expires: expiresForm,
      name: nameForm,
      street: streetForm,
      streetNumber: streetNumberForm,
      zip: zipForm,
      city: cityForm,
      state: stateForm,
      legalForm: legalFormForm,
    }

    this.mandantService.v1MandantPost(request).subscribe({
      next: (v) => {
        if (v.id) {
          this.idMandant = v.id;
        }
      },
      error: (e) => console.error("v1MandantPost " + e),
      complete: () => {
        this.router.navigate(['/user-organization']);
      }
    });
  }

  /**
   *  blur event to check if the user clicked on a field and leaved the form empty
   * @param field
   */
  validateField(field: string) {
    if (field == 'accountPrefix' || field == 'expires' || field == 'name' || field == 'street' || field == 'streetNumber' || field == 'zip' || field == 'city' || field == 'state' || field == 'legalForm') {
      this.fieldClicked = true;
    }
  }

  handleFileInputOrg(event: any) {
    const fileListOrg: FileList = event.target.files;
    if (fileListOrg.length > 0) {
      const fileOrg: File = fileListOrg[0];
      const blob = new Blob([fileOrg], { type: fileOrg.type });
      this.mandantService.v1MandantIdImportOrgUnitsPostForm(blob, this.idMandant).subscribe({
        next: (v) => { this.uploaded = 'Datei ' + fileOrg.name + ' hochgeladen ' + v },
        error: (e) => { console.error(e) },
        complete: () => { }
      });
    }
  }

  openFileInputOrg() {
    // Trigger the hidden file input element
    const fileInputOrg = document.getElementById('fileInputOrg') as HTMLInputElement;
    fileInputOrg.click();
  }

  handleFileInput(event: any) {
    const fileListOrg: FileList = event.target.files;
    if (fileListOrg.length > 0) {
      const file: File = fileListOrg[0];
      const blob = new Blob([file], { type: file.type });
      this.mandantService.v1MandantIdImportPostForm(blob, this.idMandant).subscribe({
        next: (v) => { this.uploaded = 'Datei ' + file.name + ' hochgeladen ' + v },
        error: (e) => { console.error(e) },
        complete: () => { }
      });
    }
  }

  openFileInput() {
    // Trigger the click event of the file input element
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

}