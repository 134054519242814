<nav>
  <ul>
    <!-- <li><a (click)="router.navigate(['./path-view'])">PFADANSICHT</a></li> -->
    <li><a (click)="router.navigate(['./new-path'])">STAMMDATEN</a></li>
    <li><a style="background-color: #6b8294;" (click)="router.navigate(['./entry-kis'])">EINTRAG KIS</a></li>
    <li><a style="width: 117%;" (click)="router.navigate(['./patient-archive'])">EINTRAG
        PATIENTENARCHIV</a></li>
  </ul>
</nav>

<form #activityForm="ngForm" name="activityForm">
  <div class="container">

    <!-- date -->
    <mat-form-field>
      <mat-label>Datum</mat-label>
      <input required (keydown.enter)="$event.preventDefault()" [matDatepicker]="date" [min]="path.admissionDate"
        [max]="getCurrentDate()" id="date" name="date" matInput [(ngModel)]="hisEntry.date">
      <mat-datepicker-toggle matIconSuffix [for]="date"></mat-datepicker-toggle>
      <mat-datepicker #date></mat-datepicker>
    </mat-form-field>

    <!-- facultyStartId -->
    <mat-form-field id="facultyStartId" class="example-full-width">
      <mat-label>Start Organisationseinheit</mat-label>
      <input required id="facultyStartId" type="text" placeholder="Start Organisationseinheit"
        aria-label="facultyStartId" matInput [formControl]="facultyStartIdCtrl" [matAutocomplete]="facultyStartId"
        [value]="facultyStartName">
      <mat-autocomplete autoActiveFirstOption #facultyStartId="matAutocomplete">
        <mat-option *ngFor="let option of facultyStartIdfilteredOptions | async" [value]="option.name">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <!-- facultyTargetId -->
    <mat-form-field id="facultyTargetId" class="example-full-width">
      <mat-label>Ziel Organisationseinheit</mat-label>
      <input required id="facultyTargetId" type="text" placeholder="Ziel Organisationseinheit"
        aria-label="facultyTargetId" matInput [formControl]="facultyTargetIdCtrl" [matAutocomplete]="facultyTargetId"
        [value]="facultyTargetName">
      <mat-autocomplete autoActiveFirstOption #facultyTargetId="matAutocomplete">
        <mat-option *ngFor="let option of facultyTargetIdfilteredOptions | async" [value]="option.name">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <!-- hisDocumentFilterId -->
    <mat-form-field id="hisDocumentId">
      <mat-label>Dokumentenfilter</mat-label>
      <mat-select id="hisDocumentId" required name="hisDocumentFilterId"
        [(ngModel)]="hisEntry.hisDocument.hisDocumentFilter.id" (ngModelChange)="onHisDocumentFilterIdSelected()">
        <mat-option *ngFor="let hisDocumentFilter of hisDocumentFilters" [value]="hisDocumentFilter.id">
          {{hisDocumentFilter.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- hisDocumentId -->
    <mat-form-field id="hisDocumentIdName" class="example-full-width">
      <mat-label>Dokumentenname in Orbis</mat-label>
      <input required (ngModelChange)="onHisDocumentIdSectionGet()" id="hisDocumentIdName" type="text"
        placeholder="Dokumentenname in Orbis" aria-label="hisDocumentIdName" matInput [formControl]="hisDocumentCtrl"
        [matAutocomplete]="hisDocumentIdName" [value]="hisDocumentName">
      <mat-autocomplete autoActiveFirstOption #hisDocumentIdName="matAutocomplete">
        <mat-option *ngFor="let option of hisDocumentfilteredOptions | async" [value]="option.name">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field *ngIf="diagnostics.length > 0" id="docu_name_form">
      <mat-label for="diagnosticId">Art der Diagnostik</mat-label>
      <mat-select id="diagnosticId" required name="diagnosticId" [disabled]="!hisEntry.hisDocument.hisDocumentFilter.id"
        [(ngModel)]="hisEntry.diagnostic.id">
        <mat-option *ngFor="let diagnostic of diagnostics" [value]="diagnostic.id">{{diagnostic.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- number repetitions -->
    <mat-form-field>
      <mat-label>Dokumentenwiederholung</mat-label>
      <input [min]="1" required (keydown.enter)="$event.preventDefault()" matInput type="number" name="repetitions"
        [(ngModel)]="repetitions">
    </mat-form-field>
    <!-- </ng-container> -->

  </div>

  <!-- section
      hisDocumentSectionId
      name -->
  <div *ngFor="let sectionInfo of sectionInfoCreateRequest; let i = index">
    <p style="margin-right: 74%; text-align: right;">{{sectionInfo.name}}</p>
    <mat-divider></mat-divider>
    <div class="section" style="padding-top: 1%;">

      <div *ngIf="sectionInfo.isNotFilled">

        <!-- informationCarrierId -->
        <mat-form-field id="info_form">
          <mat-label for="info">Informationsträger (Start)</mat-label>
          <mat-select id="info" disabled="disabled" style="opacity: 0.5;" name="informationCarrierId-{{i}}"
            [(ngModel)]="sectionInfo.informationCarrierId">
            <mat-option *ngFor="let informationCarrier of informationCarriers" [value]="informationCarrier.id"
              (onSelectionChange)="informationCarrier.id === sectionInfo.informationCarrierId">
              {{informationCarrier.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- dataQualityStartId -->
        <mat-form-field id="quality_form">
          <mat-label for="quality">Datenqualität (Start)</mat-label>
          <mat-select id="quality" disabled="disabled" style="opacity: 0.5;" name="dataQualityStartId-{{i}}"
            [(ngModel)]="sectionInfo.dataQualityStartId">
            <mat-option *ngFor="let dataQuality of dataQualities" [value]="dataQuality.id">
              {{dataQuality.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- dataTransferId -->
        <mat-form-field id="transfer_form">
          <mat-label for="transfer">Datenübertragung</mat-label>
          <mat-select id="transfer" disabled="disabled" style="opacity: 0.5;" name="dataTransferId-{{i}}"
            [(ngModel)]="sectionInfo.dataTransferId">
            <mat-option *ngFor="let dataTransfer of dataTransfers" [value]="dataTransfer.id">
              {{dataTransfer.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- isNotFilled -->
        <mat-checkbox style="bottom: 40px;" #isNotFilledCheckbox [(ngModel)]="sectionInfo.isNotFilled"
          name="isNotFilled-{{i}}" class="filled">
          nicht ausgefüllt
        </mat-checkbox>
      </div>

      <div *ngIf="!sectionInfo.isNotFilled">
        <!-- informationCarrierId -->

        <app-information-carrier-auto-complete [informationCarriers]="informationCarriers"
          [informationCarrierId]="sectionInfo.informationCarrierId ||''"
          (informationCarrierChange)="onInformationCarrierChange($event, sectionInfo.name)">
        </app-information-carrier-auto-complete>

        <!-- dataQualityStartId -->
        <mat-form-field id="quality_form">
          <mat-label for="quality">Datenqualität (Start)</mat-label>
          <mat-select id="quality" name="dataQualityStartId-{{i}}" [(ngModel)]="sectionInfo.dataQualityStartId">
            <mat-option *ngFor="let dataQuality of dataQualities" [value]="dataQuality.id">
              {{dataQuality.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- dataTransferId -->
        <mat-form-field id="transfer_form">
          <mat-label for="transfer">Datenübertragung</mat-label>
          <mat-select id="transfer" name="dataTransferId-{{i}}" [(ngModel)]="sectionInfo.dataTransferId">
            <mat-option *ngFor="let dataTransfer of dataTransfers" [value]="dataTransfer.id">
              {{dataTransfer.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- isNotFilled -->
        <mat-checkbox style="bottom: 40px;" [(ngModel)]="sectionInfo.isNotFilled" name="isNotFilled-{{i}}"
          class="filled" #isNotFilledCheckbox>
          nicht ausgefüllt
        </mat-checkbox>
      </div>

    </div>
  </div>

  <div class="button-group">
    <button (click)="router.navigate(['./entry-kis'])" style="color: white; background-color: #6b8294;"
      mat-flat-button>ABBRECHEN
    </button>

    <button (click)="saveActivityAndNavToOverview()" type="submit" style="color: white; background-color: #6b8294;"
      mat-flat-button>SPEICHERN + <br> ZUR
      ÜBERSICHT
    </button>

    <button (click)="saveActivityAndNavToNewActivity()" type="submit" style="color: white; background-color: #6b8294;"
      mat-flat-button>SPEICHERN + NÄCHSTER
      EINTRAG
    </button>

  </div>
</form>

<app-popup class="popup" *ngIf="showPopup == true" (answerEvent)="answerEvent($event)" [popupText]="popupText"
  [buttonLeftText]="''" [buttonRightText]="''" [buttonCenterText]="'OK'"> </app-popup>