import { Component, ViewChild, OnInit, Input } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import { catchError } from "rxjs/operators";
import { AnalysisService } from "src/swagger-typescript";
import { of } from "rxjs";

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.css']
})
export class AreaChartComponent implements OnInit {

  @Input() client: string = '';
  @Input() mandantId: string[] = [];
  @Input() dataType: string = '';
  @Input() aggregate: string = '';
  @Input() icdCode: string[] = [];
  @Input() locationName: string[] = [];
  @Input() unitType: string[] = [];

  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions: any;

  constructor(private analysisService: AnalysisService) {
    this.chartOptions = {
      series: [],
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      title: {
        text: "Einzelaktivität Datentransformation Ursprung-Ziel",
        align: "left"
      },
      xaxis: {
        type: 'numeric',
        tickAmount: 6
      },
      yaxis: {
        opposite: false,
        labels: {
          show: false
        },
        title: {
          text: 'Verteilung des Transformationswertes'
        }
      },
      markers: {
        hover: {
          sizeOffset: 5
        }
      },
      legend: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }: { series: any, seriesIndex: any, dataPointIndex: any, w: any }) {
          let seriesName = w.globals.seriesNames[seriesIndex];
          let value = series[seriesIndex][dataPointIndex];
          if (seriesName === 'mirrored_transformation_score') {
            return ''; // Return empty string for mirrored series
          }
          return '<div class="arrow_box">' +
            '<span>' + seriesName + ': ' + value + '</span>' +
            '</div>';
        },
        y: {
          formatter: (val: number) => {
            if (val < 0) {
              return '';
            }
            return val;
          }
        }
      }
    };
  }

  ngOnInit(): void {
    const filter = {
      icd_code: this.icdCode,
      location_name: this.locationName,
      unit_type: this.unitType
    };

    this.analysisService.v1AnalysisGetDataPost({
      client: this.client,
      mandantId: this.mandantId,
      data_type: this.dataType,
      aggregate: this.aggregate,
      filter: filter,
      create_network: 0
    }).pipe(
      catchError(error => {
        console.error("Error fetching data:", error);
        return of([]); // Use 'of' to return an observable with an empty array
      })
    ).subscribe((targetData) => {
      this.handleData(targetData);
    });
  }

  handleData(targetData: any): void {
    const parsedTargetData = JSON.parse(targetData);

    // Extracting transformation scores and mirrored scores
    const transformationScores = parsedTargetData.transformation_score;
    const mirroredTransformationScores = Object.keys(transformationScores).map(key => ({
      x: parseFloat(key), // Ensure x-values are numeric
      y: transformationScores[key]
    }));

    const mirroredData = mirroredTransformationScores.map(point => ({
      x: point.x,
      y: -point.y
    }));

    this.chartOptions.series = [
      {
        name: 'Transformation Score', data: mirroredTransformationScores, color: '#6b8294',
      },
      {
        name: 'mirrored_transformation_score', data: mirroredData, color: '#6b8294',
      }
    ];
    this.updateChart();
  }

  updateChart(): void {
    if (this.chart) {
      this.chart.updateOptions(this.chartOptions, true, true, true);
    }
  }
}