<nav>
    <ul>
        <li><a (click)="router.navigate(['./new-path'])">STAMMDATEN</a></li>
        <li><a style="background-color: #6b8294;" (click)="router.navigate(['./entry-kis'])">EINTRAG KIS</a></li>
        <li><a (click)="router.navigate(['./patient-archive'])">EINTRAG
                PATIENTENARCHIV</a></li>
    </ul>
</nav>

<div class="container">

    <button class="activities-button" (click)="onNewActivityClick()" style="color: white;" mat-button
        (click)="router.navigate(['./create-activity'])" id="create-activity">
        <mat-icon style="margin-right: 5%;" class="material-symbols-rounded">add_circle</mat-icon>
        Aktivität anlegen
    </button>

    <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
            <ng-container matColumnDef="hisDocument">
                <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef mat-sort-header
                    sortActionDescription="Sort by hisDocument">AKTIVITÄT</th>
                <td mat-cell *matCellDef="let element">
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span style="width: 200px;">{{element.hisDocument.name}}</span>
                        <button (click)="loadActivity(element)" style="color: white;" mat-button id="loadActivity">
                            AKTIVITÄT EINSICHT
                        </button>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef mat-sort-header
                    sortActionDescription="Sort by date">DATUM DER AKTIVITÄT</th>
                <td mat-cell *matCellDef="let element">{{this.pipe.transform(element.date, 'dd.MM.yyyy')}}
                </td>
            </ng-container>

            <ng-container matColumnDef="facultyStart">
                <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef mat-sort-header
                    sortActionDescription="Sort by facultyStart">ORGANISATIONSEINHEIT</th>
                <td mat-cell *matCellDef="let element">{{element.facultyStart.name}}
                </td>
            </ng-container>

            <ng-container matColumnDef="updated">
                <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef mat-sort-header
                    sortActionDescription="Sort by updated">LETZTE ÄNDERUNG</th>
                <td mat-cell *matCellDef="let element">{{this.pipe.transform(element.updated, 'dd.MM.yyyy hh:mma',
                    'GMT')}}
                </td>
            </ng-container>

            <!-- Delete Column -->
            <ng-container matColumnDef="delete">
                <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef>AKTION</th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <!-- edit -->
                    <button style="margin-left: 0%; background-color: white;" mat-icon-button
                        (click)="loadActivity(element)">
                        <mat-icon style="color: #6b8294;">edit</mat-icon>
                    </button>
                    <!-- delete -->
                    <button style="margin-left: 0%; background-color: white;" mat-icon-button
                        (click)="popupText='Möchten sie die Aktivität ' + element.hisDocument.name + ' wirklich löschen?'; showPopup = true; elementToDelete = element.id;">
                        <mat-icon style="color: #6b8294;">delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <mat-paginator [length]="hisEntrys.length" [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]"></mat-paginator>

    <app-popup class="popup" *ngIf="showPopup == true" (buttonRightClicked)="deleteActivity()"
        (answerEvent)="answerEvent($event)" [popupText]="popupText" [buttonLeftText]="'ABBRECHEN'"
        [buttonRightText]="'OK'"></app-popup>

</div>

<!-- <div class="no-activities-container" *ngIf="hisEntrys.length === 0">

    <button (click)="onNewActivityClick()" style="color: white;" mat-button
        (click)="router.navigate(['./create-activity'])" id="no-activities-create-activity">
        <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
        Aktivitäten anlegen
    </button>

</div> -->