import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    constructor(private http: HttpClient) { }

    url = 'http://127.0.0.1:5000/get_data'

    getData(client: string, idList: string[], dataType: string, aggregate: string): Observable<any> {

        const requestData = {
            client: client,
            id_list: idList,
            data_type: dataType,
            aggregate: aggregate
        };

        return this.http.post<any>(this.url, requestData);
    }

}