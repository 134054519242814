import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoggedInService } from './logged-in.service';

@Injectable()
export class HttpRequestCookieInterceptor implements HttpInterceptor {

  constructor(private loggedInService: LoggedInService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    // this.loggedInService.lastUserAction = Date.now();
    this.loggedInService.resetSessionTimer();

    // Add withCredentials-option to every outgoing request
    // Needed for cookie usage
    request = request.clone({
      withCredentials: true
    });
    return next.handle(request);
  }
}