import { ChangeDetectorRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../general.service';
import { MatDialog } from '@angular/material/dialog';
import { MovableDialogComponent } from '../movable-dialog/movable-dialog.component';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LiveAnnouncer } from '@angular/cdk/a11y';

import { AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, switchMap } from 'rxjs';
import { AdmissionTypeService, IcdService, PatientPathService, PatientPath, PatientPathCreateRequest, PatientPathDTO, UnitService, TreatmentUnitDTO, TreatmentUnitCreateRequest, DiagnosisCreateRequest, ICD, TreatmentUnitUpdateRequest, UnitDTO, AdmissionType, UserService, MandantService, MandantDTO, AnalysisService } from 'src/swagger-typescript';
import { PathService } from '../path.service';
import { DatePipe, registerLocaleData } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatRadioChange } from '@angular/material/radio';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver'; // Ensure you import saveAs from 'file-saver'
import { MatTooltip } from '@angular/material/tooltip';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NetworkComponent } from '../network/network.component';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.css']
})

export class AnalysisComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];
  clinicCtrl = new FormControl('');
  filteredCLinics$: Observable<string[]>;
  clinics: string[] = [''];
  allClinics: MandantDTO[] = [];
  allClinicsNames: string[] = [];
  @ViewChild('clinicInput')
  clinicInput!: ElementRef<HTMLInputElement>;
  announcer = inject(LiveAnnouncer);

  client: string = '';
  mandantId: string = '';

  aggregate: string = '';
  // Datenqualität
  target_quality_index_dataType: string = 'target_quality_index';
  origin_quality_index_dataType: string = 'origin_quality_index';
  // Digitalisierungsgrad
  target_interoperability_index_dataType: string = 'target_interoperability_index';
  origin_interoperability_index_dataType: string = 'origin_interoperability_index';
  // Datenübertragung
  transmission_index_dataType: string = 'transmission_index';
  transmission_dataType: string = 'DATENÜBERTRAGUNG';
  // Datentransformation
  transformation_index_dataType: string = 'transformation_index';
  transformation_score_dataType: string = 'transformation_score';
  // gender
  gender_dataType: string = 'gender';
  // age group
  age_group_dataType: string = 'age_group';

  selectedAnalysis: string = '';
  selectedClinic: string = '';
  selectedLocations: string[] = [];
  selectedCodes: string[] = [];
  selectedUnitTypes: string[] = [];

  analyses: any[] = [];

  location_names: string[] = [];
  icd_codes: string[] = [];
  unit_types: string[] = [];

  allLocaltionsSelected = false;
  selectAllLocaltionsLabel = 'alle auswählen';
  allUnitTypesSelected = false;
  selectAllUnitTypesLabel = 'alle auswählen';
  allCodesSelected = false;
  selectAllCodesLabel = 'alle auswählen';
  showInfo: boolean = false;

  constructor(private sanitizer: DomSanitizer, private changeDetectorRef: ChangeDetectorRef, private analysisService: AnalysisService, private userService: UserService, public router: Router, public generalService: GeneralService, public dialog: MatDialog, private mandantService: MandantService) {
    this.filteredCLinics$ = this.clinicCtrl.valueChanges.pipe(
      startWith(null),
      map((clinic: string | null) => clinic ? this._filter(clinic) : this.allClinicsNames.slice()));
  }

  ngOnInit(): void {
    this.generalService.setTitle('Analyse');

    // Fetch user details
    this.userService.v1UserOwnGet().subscribe({
      next: (user) => this.handleUserDetails(user),
      error: (e) => this.handleError('Error fetching user details', e)
    });

  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
  }

  toggleSelectAll() {
    this.allLocaltionsSelected = !this.allLocaltionsSelected;
    if (this.allLocaltionsSelected) {
      this.selectedLocations = [...this.location_names];
      this.selectAllLocaltionsLabel = 'alle abwählen';
    } else {
      this.selectedLocations = [];
      this.selectAllLocaltionsLabel = 'alle auswählen';
    }
  }

  toggleSelectAllUnitTypes() {
    this.allUnitTypesSelected = !this.allUnitTypesSelected;
    if (this.allUnitTypesSelected) {
      this.selectedUnitTypes = [...this.unit_types];
      this.selectAllUnitTypesLabel = 'alle abwählen';
    } else {
      this.selectedUnitTypes = [];
      this.selectAllUnitTypesLabel = 'alle auswählen';
    }
  }

  toggleSelectAllCodes() {
    this.allCodesSelected = !this.allCodesSelected;
    if (this.allCodesSelected) {
      this.selectedCodes = [...this.icd_codes];
      this.selectAllCodesLabel = 'alle abwählen';
    } else {
      this.selectedCodes = [];
      this.selectAllCodesLabel = 'alle auswählen';
    }
  }

  private handleUserDetails(user: any): void {
    this.client = user.username;
    if (user.role === 'steward' || user.role === 'admin') {
      this.fetchAllClinics();
    } else if (user.role === 'mandant') {
      this.setupMandant(user);
    }
  }

  private fetchAllClinics(): void {
    this.mandantService.v1MandantGet().subscribe({
      next: (clinics) => {
        this.allClinics = clinics;
        this.allClinicsNames = clinics.map(clinic => clinic.name);
      },
      error: (err) => this.handleError('Error fetching clinics', err)
    });
  }

  private setupMandant(user: any): void {
    this.allClinicsNames.push(user.mandant.name);
    this.clinics = [user.mandant.name];
    this.mandantId = user.mandant.id;

    this.analysisService.v1AnalysisDropdownUpdatePost({
      "client": this.client,
      "mandantId": this.mandantId,
      "location_name": [],
      "unit_type": [],
      "icd_code": []
    }).subscribe({
      next: (v) => { this.handleAnalysisResponse(v) },
      error: (e) => this.handleError('Error updating analysis dropdown', e)
    });
  }

  private handleAnalysisResponse(response: any): void {
    this.location_names = response.location_name;
    this.icd_codes = response.icd_code;
    this.unit_types = response.unit_type;
  }

  selectAll() {
    this.selectedLocations = this.location_names;
    this.selectedCodes = this.icd_codes;
    this.selectedUnitTypes = this.unit_types;
  }

  private handleError(message: string, error: any): void {
    console.error(message, error);
  }

  onLocationSelected() {
    this.analysisService.v1AnalysisDropdownUpdatePost({
      "client": this.client,
      "mandantId": this.mandantId,
      "location_name": this.selectedLocations,
      "unit_type": [],
      "icd_code": []
    }).subscribe({
      next: (v) => this.handleAnalysisResponse(v),
      error: (e) => this.handleError('Error updating analysis dropdown', e)
    });
  }

  onUnitTypeSelected() {
    this.analysisService.v1AnalysisDropdownUpdatePost({
      "client": this.client,
      "mandantId": this.mandantId,
      "location_name": this.selectedLocations,
      "unit_type": this.selectedUnitTypes,
      "icd_code": []
    }).subscribe({
      next: (v) => this.handleAnalysisResponse(v),
      error: (e) => this.handleError('Error updating analysis dropdown', e)
    });
  }

  onRadioChange(event: any): void {
    this.aggregate = event.value;
  }

  onAnalysisClick(index: number): void {
    const analysis = this.analyses[index];
    this.openDialog(analysis, index);
  }

  openDialog(analysis: any, i: number): void {
    const dialogRef = this.dialog.open(MovableDialogComponent, {
      hasBackdrop: false,
      disableClose: true,
      data: {
        title: `Analyse ${i + 1} - ${this.getAnalysisName(analysis.selectedAnalysis)}`,
        xAxisLabel: this.getxAxisLabel(analysis.selectedAnalysis),
        yAxisLabel: this.getyAxisLabel(analysis.selectedAnalysis),
        tooltip: this.getTooltip(analysis.selectedAnalysis),
        analysis: analysis.selectedAnalysis,
        client: this.client,
        mandantId: this.mandantId,
        target_quality_index_dataType: this.target_quality_index_dataType,
        origin_quality_index_dataType: this.origin_quality_index_dataType,
        target_interoperability_index_dataType: this.target_interoperability_index_dataType,
        origin_interoperability_index_dataType: this.origin_interoperability_index_dataType,
        transmission_index_dataType: this.transmission_index_dataType,
        transmission_dataType: this.transmission_dataType,
        transformation_index_dataType: this.transformation_index_dataType,
        transformation_score_dataType: this.transformation_score_dataType,
        gender_dataType: this.gender_dataType,
        age_group_dataType: this.age_group_dataType,
        aggregate: analysis.aggregate,
        filter: {
          icd_code: analysis.selectedCodes,
          location_name: analysis.selectedLocations,
          unit_type: analysis.selectedUnitTypes
        }
      }
    });

    dialogRef.afterOpened().subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  getTooltip(selectedAnalysisValue: string): string {
    const analysisTooltips: { [key: string]: string } = {
      target_interoperability_index_vs_origin_interoperability_index: `
    <p>
    Diese Analyse zeigt den Digitalisierungsgrad des Datenursprungs (\\(DG^U\\)) gegen den des Datenziels (\\(DG^Z\\)).
    Der Digitalisierungsgrad wird aus der Strukturiertheit der übermittelten Daten (\\( S_i \\)) an Ursprung bzw. Ziel,
    sowie dem Automatisierungsgrad der Datenübertragung (\\( A_i \\)) gebildet.
  </p>
  <p>
    $$ DG = \\frac{50}{n} \\sum_{i=1}^{n} \\frac{S_i - S_{min}}{S_{max} - S_{min}} + \\frac{A_i - A_{min}}{A_{max} - A_{min}} $$
  </p>
  <p>
    Hierbei ist:
    \\begin{align}
    DG\\text{:} &&& \\text{Digitalisierungsgrad} \\\\
    n\\text{:} &&& \\text{Anzahl der Datenübermittlungen} \\\\
    i\\text{:} &&& i\\text{-te Datenübermittlungen} \\\\
    S_i\\text{:} &&& \\text{Strukturiertheit der Daten} \\\\
    A_i\\text{:} &&& \\text{Automatisierungsgrad der Datenübermittlung} \\\\
    S_{min/max}\\text{:} &&& \\text{Minimal/Maximal mögliche Strukturiertheit der Daten} \\\\
    A_{min/max}\\text{:} &&& \\text{Minimal/Maximal möglicher Automatisierungsgrad der Datenübermittlung} \\\\
    \\end{align}
  </p>
  `,
      target_interoperability_index: `
    <p>
    Diese Analyse zeigt den Digitalisierungsgrad des Datenursprungs (\\(DG^U\\)) gegen den des Datenziels (\\(DG^Z\\)).
    Der Digitalisierungsgrad wird aus der Strukturiertheit der übermittelten Daten (\\( S_i \\)) an Ursprung bzw. Ziel,
    sowie dem Automatisierungsgrad der Datenübertragung (\\( A_i \\)) gebildet.
  </p>
  <p>
    $$ DG = \\frac{50}{n} \\sum_{i=1}^{n} \\frac{S_i - S_{min}}{S_{max} - S_{min}} + \\frac{A_i - A_{min}}{A_{max} - A_{min}} $$
  </p>
  <p>
    Hierbei ist:
    \\begin{align}
    DG\\text{:} &&& \\text{Digitalisierungsgrad} \\\\
    n\\text{:} &&& \\text{Anzahl der Datenübermittlungen} \\\\
    i\\text{:} &&& i\\text{-te Datenübermittlungen} \\\\
    S_i\\text{:} &&& \\text{Strukturiertheit der Daten} \\\\
    A_i\\text{:} &&& \\text{Automatisierungsgrad der Datenübermittlung} \\\\
    S_{min/max}\\text{:} &&& \\text{Minimal/Maximal mögliche Strukturiertheit der Daten} \\\\
    A_{min/max}\\text{:} &&& \\text{Minimal/Maximal möglicher Automatisierungsgrad der Datenübermittlung} \\\\
    \\end{align}
  </p>
  `,
      origin_interoperability_index: `
    <p>
    Diese Analyse zeigt den Digitalisierungsgrad des Datenursprungs (\\(DG^U\\)) gegen den des Datenziels (\\(DG^Z\\)).
    Der Digitalisierungsgrad wird aus der Strukturiertheit der übermittelten Daten (\\( S_i \\)) an Ursprung bzw. Ziel,
    sowie dem Automatisierungsgrad der Datenübertragung (\\( A_i \\)) gebildet.
  </p>
  <p>
    $$ DG = \\frac{50}{n} \\sum_{i=1}^{n} \\frac{S_i - S_{min}}{S_{max} - S_{min}} + \\frac{A_i - A_{min}}{A_{max} - A_{min}} $$
  </p>
  <p>
    Hierbei ist:
    \\begin{align}
    DG\\text{:} &&& \\text{Digitalisierungsgrad} \\\\
    n\\text{:} &&& \\text{Anzahl der Datenübermittlungen} \\\\
    i\\text{:} &&& i\\text{-te Datenübermittlungen} \\\\
    S_i\\text{:} &&& \\text{Strukturiertheit der Daten} \\\\
    A_i\\text{:} &&& \\text{Automatisierungsgrad der Datenübermittlung} \\\\
    S_{min/max}\\text{:} &&& \\text{Minimal/Maximal mögliche Strukturiertheit der Daten} \\\\
    A_{min/max}\\text{:} &&& \\text{Minimal/Maximal möglicher Automatisierungsgrad der Datenübermittlung} \\\\
    \\end{align}
  </p>
  `,
      transformation_index: `<p>
  Die Datentransformation (\\(DF\\)) drückt aus wie sich die Strukturiertheit der Daten bei der Datentransformation verändert.<br>
  Sie wird in einer Skala von -3 bis 3 angegeben, wobei negative Zahlen eine Verringerung, positive Werte eine Zunahme und 0 keine Veränderung der Strukturiertheit anzeigen.<br>
  Die entsprechenden Werte hängen hierbei auch von der Art der Übertragun ab und sind in den folgenden Tabellen festgelegt.<br>
  Hierbei kommt es im speziellen darauf an, in welcher Form die Daten zunächst bei der Datenquelle oder dem Sender (Ursprung), und später beim Datenempfänger (Ziel) vorliegen.
</p>
  <table border="1" style="padding: 10px;">
  <tr><th colspan="5">Datenübertragung: manuell</th></tr>
  <tr><th colspan="2" rowspan="2">Strukturiertheit</th><th colspan="3">Ziel</th></tr>
  <tr>                                                   <th>unstrukturiert</th> <th>semi-strukturiert</th>  <th>strukturiert</th></tr>
  <tr><th rowspan="4">Ursprung</th> <th>immateriell</th> <td>1</td>              <td>2</td>                  <td>3</td></tr>
  <tr>                              <th>analog</th>      <td>0</td>              <td>1</td>                  <td>2</td></tr>
  <tr>                              <th>unstrukturiert</th><td>0</td>            <td>1</td>                  <td>2</td></tr>
  <tr>                              <th>strukturiert</th>   <td>-2</td>           <td>-1</td>                  <td>0</td></tr>
  </table>
<table border="1" style="padding: 10px;">
  <tr><th colspan="5">Datenübertragung: teilautomatisiert</th></tr>
  <tr><th colspan="2" rowspan="2">Strukturiertheit</th><th colspan="3">Ziel</th></tr>
  <tr>                                                   <th>unstrukturiert</th> <th>semi-strukturiert</th>  <th>strukturiert</th></tr>
  <tr><th rowspan="4">Ursprung</th> <th>immateriell</th> <td>1</td>              <td>2</td>                  <td>3</td></tr>
  <tr>                              <th>analog</th>      <td>0</td>              <td>1</td>                  <td>2</td></tr>
  <tr>                              <th>unstrukturiert</th><td>0</td>            <td>1</td>                  <td>2</td></tr>
  <tr>                              <th>strukturiert</th>   <td>-2</td>           <td>-1</td>                  <td>0</td></tr>
</table>
<table border="1" style="padding: 10px;">
  <tr><th colspan="5">Datenübertragung: vollautomatisiert</th></tr>
  <tr><th colspan="2" rowspan="2">Strukturiertheit</th><th colspan="3">Ziel</th></tr>
  <tr>                                                   <th>unstrukturiert</th> <th>semi-strukturiert</th>  <th>strukturiert</th></tr>
  <tr><th rowspan="3">Ursprung</th> <th>analog</th>      <td>0</td>              <td>1</td>                  <td>2</td></tr>
  <tr>                              <th>unstrukturiert</th><td>0</td>            <td>1</td>                  <td>2</td></tr>
  <tr>                              <th>strukturiert</th>   <td>-3</td>           <td>-2</td>                  <td>0</td></tr>
</table>`,
      transformation_score: `<p>
  Die Datentransformation (\\(DF\\)) drückt aus wie sich die Strukturiertheit der Daten bei der Datentransformation verändert.<br>
  Sie wird in einer Skala von -3 bis 3 angegeben, wobei negative Zahlen eine Verringerung, positive Werte eine Zunahme und 0 keine Veränderung der Strukturiertheit anzeigen.<br>
  Die entsprechenden Werte hängen hierbei auch von der Art der Übertragun ab und sind in den folgenden Tabellen festgelegt.<br>
  Hierbei kommt es im speziellen darauf an, in welcher Form die Daten zunächst bei der Datenquelle oder dem Sender (Ursprung), und später beim Datenempfänger (Ziel) vorliegen.
</p>
<table border="1" style="padding: 10px;">
  <tr><th colspan="5">Datenübertragung: manuell</th></tr>
  <tr><th colspan="2" rowspan="2">Strukturiertheit</th><th colspan="3">Ziel</th></tr>
  <tr>                                                   <th>unstrukturiert</th> <th>semi-strukturiert</th>  <th>strukturiert</th></tr>
  <tr><th rowspan="4">Ursprung</th> <th>immateriell</th> <td>1</td>              <td>2</td>                  <td>3</td></tr>
  <tr>                              <th>analog</th>      <td>0</td>              <td>1</td>                  <td>2</td></tr>
  <tr>                              <th>unstrukturiert</th><td>0</td>            <td>1</td>                  <td>2</td></tr>
  <tr>                              <th>strukturiert</th>   <td>-2</td>           <td>-1</td>                  <td>0</td></tr>
</table>

<table border="1" style="padding: 10px;">
  <tr><th colspan="5">Datenübertragung: teilautomatisiert</th></tr>
  <tr><th colspan="2" rowspan="2">Strukturiertheit</th><th colspan="3">Ziel</th></tr>
  <tr>                                                   <th>unstrukturiert</th> <th>semi-strukturiert</th>  <th>strukturiert</th></tr>
  <tr><th rowspan="4">Ursprung</th> <th>immateriell</th> <td>1</td>              <td>2</td>                  <td>3</td></tr>
  <tr>                              <th>analog</th>      <td>0</td>              <td>1</td>                  <td>2</td></tr>
  <tr>                              <th>unstrukturiert</th><td>0</td>            <td>1</td>                  <td>2</td></tr>
  <tr>                              <th>strukturiert</th>   <td>-2</td>           <td>-1</td>                  <td>0</td></tr>
</table>

<table border="1" style="padding: 10px;">
  <tr><th colspan="5">Datenübertragung: vollautomatisiert</th></tr>
  <tr><th colspan="2" rowspan="2">Strukturiertheit</th><th colspan="3">Ziel</th></tr>
  <tr>                                                   <th>unstrukturiert</th> <th>semi-strukturiert</th>  <th>strukturiert</th></tr>
  <tr><th rowspan="3">Ursprung</th> <th>analog</th>      <td>0</td>              <td>1</td>                  <td>2</td></tr>
  <tr>                              <th>unstrukturiert</th><td>0</td>            <td>1</td>                  <td>2</td></tr>
  <tr>                              <th>strukturiert</th>   <td>-3</td>           <td>-2</td>                  <td>0</td></tr>
</table>`,
      target_quality_index_vs_origin_quality_index: `<p>
    Die Datenqualität gibt die Strukturiertheit der Daten an. Hierbei ist jeder Strukturierheitsstufe ein Wert zwischen
    0 (keine Struktur/Daten) und 1 (digital strukturierte Daten) zugeordnet.<br>
    Hierbei sind verschiedene Formen der Strukturiertheit bei der Datenquelle oder dem Sender (Ursprung) und dem
    Datenempfänger (Ziel) möglich.
  </p>
  <table border="1" style="padding: 10px;">
    <tr>
      <th colspan="3">Datenqualität</th>
    </tr>
    <tr>
      <th></th>
      <th>Ursprung</th>
      <th>Ziel</th>
    </tr>
    <tr>
      <th>immateriell</th>
      <td>0</td>
      <td>-</td>
    </tr>
    <tr>
      <th>analog</th>
      <td>0.33</td>
      <td>-</td>
    </tr>
    <tr>
      <th>unstrukturiert</th>
      <td>0.66</td>
      <td>0</td>
    </tr>
    <tr>
      <th>semi-strukturiert</th>
      <td>-</td>
      <td>0.5</td>
    </tr>
    <tr>
      <th>strukturiert</th>
      <td>1</td>
      <td>1</td>
    </tr>
  </table>`,
      origin_quality_index_vs_transmission_index: `  <p>
    Die Datenqualität gibt die Strukturiertheit der Daten an. Hierbei ist jeder Strukturierheitsstufe ein Wert zwischen
    0 (keine Struktur/Daten) und 1 (digital strukturierte Daten) zugeordnet.<br>
    Hierbei sind verschiedene Formen der Strukturiertheit bei der Datenquelle oder dem Sender (Ursprung) und dem
    Datenempfänger (Ziel) möglich.
  </p>
  <table border="1" style="padding: 10px;">
    <tr>
      <th colspan="3">Datenqualität</th>
    </tr>
    <tr>
      <th></th>
      <th>Ursprung</th>
      <th>Ziel</th>
    </tr>
    <tr>
      <th>immateriell</th>
      <td>0</td>
      <td>-</td>
    </tr>
    <tr>
      <th>analog</th>
      <td>0.33</td>
      <td>-</td>
    </tr>
    <tr>
      <th>unstrukturiert</th>
      <td>0.66</td>
      <td>0</td>
    </tr>
    <tr>
      <th>semi-strukturiert</th>
      <td>-</td>
      <td>0.5</td>
    </tr>
    <tr>
      <th>strukturiert</th>
      <td>1</td>
      <td>1</td>
    </tr>
  </table>`,
      transmission_index: `<p>
  Die Datenübertragung gibt die Art und Weise in der die Daten transferiert werden an. Hierbei ist jeder Art des Transfers ein Wert zugeordnet.
</p>
<table border="1" style="padding: 10px;">
    <tr><th colspan="2">Datenübertragung</th></tr>
    <tr><th>manuell</th>      <td>0.0</td>
    <tr><th>teilautomatisiert</th>  <td>0.5</td>
    <tr><th>vollautomatisiert</th>  <td>1.0</td>
  </table>`,
      DATENÜBERTRAGUNG: `<p>
  Die Datenübertragung gibt die Art und Weise in der die Daten transferiert werden an. Hierbei ist jeder Art des Transfers ein Wert zugeordnet.
</p>
<table border="1" style="padding: 10px;">
    <tr><th colspan="2">Datenübertragung</th></tr>
    <tr><th>manuell</th>      <td>0.0</td>
    <tr><th>teilautomatisiert</th>  <td>0.5</td>
    <tr><th>vollautomatisiert</th>  <td>1.0</td>
  </table>`,
      gender: 'Verteilung der Patienten nach Geschlecht',
      age_group: 'Altersgruppe'
    };

    return analysisTooltips[selectedAnalysisValue] || selectedAnalysisValue;
  }

  getAnalysisName(selectedAnalysisValue: string): string {
    const analysisNames: { [key: string]: string } = {
      target_interoperability_index_vs_origin_interoperability_index: 'Digitalisierungsgrad Ursprung-Ziel',
      target_interoperability_index: 'Digitalisierungsgrad Ziel',
      origin_interoperability_index: 'Digitalisierungsgrad Ursprung',
      transformation_index: 'Durchschnittliche Datentransformation Ursprung-Ziel',
      transformation_score: 'Einzelaktivität Datentransformation Ursprung-Ziel',
      target_quality_index_vs_origin_quality_index: 'Durchschnittliche Datenqualität Ursprung-Ziel',
      origin_quality_index_vs_transmission_index: 'Datenqualität Ursprung-Datenübertragung',
      transmission_index: 'Datenübertragung',
      DATENÜBERTRAGUNG: 'Einzelaktivität Datenübertragung',
      gender: 'Geschlecht',
      age_group: 'Altersgruppe'
    };

    return analysisNames[selectedAnalysisValue] || selectedAnalysisValue;
  }

  getxAxisLabel(selectedAnalysisValue: string): string {
    const analysisNames: { [key: string]: string } = {
      target_interoperability_index_vs_origin_interoperability_index: 'Digitalisierungsgrad Ursprung',
      target_interoperability_index: 'Digitalisierungsgrad Ziel',
      origin_interoperability_index: 'Digitalisierungsgrad Ursprung',
      transformation_index: 'Durchschnittliche Datentransformation Ursprung-Ziel',
      transformation_score: 'Einzelaktivität Datentransformation Ursprung-Ziel',
      target_quality_index_vs_origin_quality_index: 'Datenqualität Ursprung',
      origin_quality_index_vs_transmission_index: 'Datenübertragung',
      transmission_index: 'Datenübertragung',
      DATENÜBERTRAGUNG: 'Einzelaktivität Datenübertragung',
      gender: 'Geschlecht',
      age_group: 'Altersgruppe'
    };

    return analysisNames[selectedAnalysisValue] || selectedAnalysisValue;
  }

  getyAxisLabel(selectedAnalysisValue: string): string {
    const analysisNames: { [key: string]: string } = {
      target_interoperability_index_vs_origin_interoperability_index: 'Digitalisierungsgrad Ziel',
      target_interoperability_index: 'Digitalisierungsgrad Ziel',
      origin_interoperability_index: 'Digitalisierungsgrad Ursprung',
      transformation_index: 'Durchschnittliche Datentransformation Ursprung-Ziel',
      transformation_score: 'Einzelaktivität Datentransformation Ursprung-Ziel',
      target_quality_index_vs_origin_quality_index: 'Datenqualität Ziel',
      origin_quality_index_vs_transmission_index: 'Datenqualität Ursprung',
      transmission_index: 'Datenübertragung',
      DATENÜBERTRAGUNG: 'Einzelaktivität Datenübertragung',
      gender: 'Anzahl der Patienten (n)',
      age_group: 'Altersgruppe'
    };

    return analysisNames[selectedAnalysisValue] || selectedAnalysisValue;
  }

  getHighlightingName(aggregate: string): string {
    const aggregateNames: { [key: string]: string } = {
      primary_location: 'Standort',
      age_group: 'Altersgruppe',
      gender: 'Geschlecht',
      primary_unit: 'Stationstyp'
    };

    return aggregateNames[aggregate] || aggregate;
  }

  addAnalysis(): void {
    const newAnalysis = {
      selectedAnalysis: this.selectedAnalysis,
      clinics: this.clinics,
      selectedLocations: this.selectedLocations,
      selectedCodes: this.selectedCodes,
      selectedUnitTypes: this.selectedUnitTypes,
      aggregate: this.aggregate
    };

    this.analyses.push(newAnalysis);

    const index = this.analyses.length - 1;

    this.openDialog(newAnalysis, index);
  }


  resetFilters(): void {
    this.selectedAnalysis = '';
    this.selectedClinic = '';
    this.selectedLocations = [];
    this.selectedCodes = [];
    this.selectedUnitTypes = [];
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add clinic
    if (value) {
      this.clinics.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
    this.clinicCtrl.setValue(null);
  }

  remove(clinic: string): void {
    const index = this.clinics.indexOf(clinic);
    if (index >= 0) {
      this.clinics.splice(index, 1);
      this.announcer.announce(`Removed ${clinic}`);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.clinics.push(event.option.viewValue);
    this.clinicInput.nativeElement.value = '';
    this.clinicCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allClinicsNames.filter(clinic => clinic.toLowerCase().includes(filterValue));
  }

  downloadSpec(index: number) {
    const analysis = this.analyses[index];
    this.downloadAnalysisSpec(analysis, index);
  }

  downloadAnalysisSpec(analysis: any, index: number) {
    const fileContent = `
      Analyse ${index + 1} - ${this.getAnalysisName(analysis.selectedAnalysis)}\n
      Kliniken: ${analysis.clinics}\n
      Standorte: ${analysis.selectedLocations}\n
      ICD-10 Codes: ${analysis.selectedCodes}\n
      Stationstyp: ${analysis.selectedUnitTypes}\n
      Farbliche Hervorhebung nach: ${this.getHighlightingName(analysis.aggregate)}
    `;
    const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `analysis_${index + 1}.txt`);
  }

  isGenderSelected(): boolean {
    return this.selectedAnalysis === 'gender';
  }

  isAgeSelected(): boolean {
    return this.selectedAnalysis === 'age_group';
  }

  isDATENUEBERTRAGUNG(): boolean {
    return this.selectedAnalysis === 'DATENÜBERTRAGUNG';
  }

  showNetwork(index: number) {
    const analysis = this.analyses[index];
    const filter = {
      icd_code: analysis.selectedCodes,
      location_name: analysis.selectedLocations,
      unit_type: analysis.selectedUnitTypes
    };
    const filterString = filter;
    this.analysisService.v1AnalysisGetDataPost({
      "client": this.client,
      "mandantId": this.mandantId,
      "data_type": "origin_interoperability_index",
      "aggregate": this.getHighlightingName(analysis.aggregate),
      "filter": filterString,
      "create_network": 1
    }).pipe(
      catchError(error => {
        console.error("Error fetching network:", error);
        return [];
      })
    ).subscribe({
      next: (v) => { },
      error: (e) => { console.error(e) },
      complete: () => {
        this.openNetworkComponent();
      }
    });
  }

  openNetworkComponent() {
    const dialogRef = this.dialog.open(NetworkComponent, {
      height: '90%',
      data: {
        title: `Netzwerkanalyse`,
        tooltip: `Tooltip`,

      }
    });

    dialogRef.afterOpened().subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

}