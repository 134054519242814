import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MathJaxService } from '../math-jax.service';

@Component({
  selector: 'app-math-jax-paragraph',
  templateUrl: './math-jax-paragraph.component.html',
  styleUrls: ['./math-jax-paragraph.component.css']
})
export class MathJaxParagraphComponent {
  @ViewChild('mathParagraph') paragraphElement: any;
  @Input() mathString!: string;

  constructor(private mathJaxService: MathJaxService) { }

  ngOnInit() {
    this.mathJaxService.getMathJaxLoadedPromise().then(() => {

      // Insert the input string
      this.paragraphElement.nativeElement.innerHTML = this.mathString;

      // Render the Latex
      this.mathJaxService.render(this.paragraphElement.nativeElement);
    });
  }
}