<div class="container">
    <mat-card>
        <mat-card-content>
            <!-- header -->
            <mat-icon class="material-symbols-rounded">account_circle</mat-icon>
            <p>{{mandant.accountPrefix}}</p><br>
            <p style="font-size: 80%; margin-left: 3%; margin-top: 0;">{{mandant.name}}</p>

            <mat-divider></mat-divider>
            <!-- create mandant-->
            <section style="float: left; width: 60%;">
                <form #mandantForm="ngForm" name="mandantForm" (ngSubmit)="onSubmit(mandantForm)">
                    <!-- accountPrefix -->
                    <mat-form-field id="accName">
                        <mat-label for="accountName">Accountname</mat-label>
                        <input required id="accountName" matInput placeholder="Accountname eintragen"
                            name="accountPrefix" [(ngModel)]="mandant.accountPrefix"
                            [ngClass]="{'invalid-field': fieldClicked && !mandant.accountPrefix}"
                            (blur)="validateField('accountPrefix')">
                    </mat-form-field>

                    <!-- expires -->
                    <mat-form-field id="expirDate">
                        <mat-label>Ablaufdatum Accounts</mat-label>
                        <input [matDatepicker]="expires" required id="expirationDate" matInput
                            [(ngModel)]="mandant.expires" name="expires" placeholder="Ablaufdatum eintragen"
                            [ngClass]="{'invalid-field': fieldClicked && !mandant.expires}"
                            (blur)="validateField('expires')">
                        <mat-datepicker-toggle matIconSuffix [for]="expires"></mat-datepicker-toggle>
                        <mat-datepicker #expires></mat-datepicker>
                    </mat-form-field>

                    <!-- name -->
                    <mat-form-field id="nameOE">
                        <mat-label for="oeName">Name der Organsation</mat-label>
                        <input required id="oeName" matInput [(ngModel)]="mandant.name" name="name"
                            placeholder="Name der Organsation eintragen"
                            [ngClass]="{'invalid-field':fieldClicked && !mandant.name}" (blur)="validateField('name')">
                    </mat-form-field>

                    <!-- legalForm -->
                    <mat-form-field id="legalForm">
                        <mat-label for="legalform">Rechtsform</mat-label>
                        <input required id="legalform" matInput [(ngModel)]="mandant.legalForm" name="legalForm"
                            placeholder="Rechtsform eintragen Bsp. GmbH"
                            [ngClass]="{'invalid-field': fieldClicked &&!mandant.legalForm}"
                            (blur)="validateField('legalForm')">
                    </mat-form-field>

                    <!-- adress -->
                    <!-- street -->
                    <label style="margin-left: 6%; margin-bottom: 10%; margin-top: 0%;" for="adress">Adresse der
                        Organsation</label>

                    <div class="inline-form-fields">

                        <mat-form-field style="width: 34%;" id="street">
                            <input required id="strasse" matInput name="street" [(ngModel)]="mandant.street"
                                placeholder="Straße" [ngClass]="{'invalid-field':fieldClicked && !mandant.street}"
                                (blur)="validateField('street')">
                        </mat-form-field>

                        <!-- streetNumber -->
                        <mat-form-field style="width: 20%;" id="house-number">
                            <input required id="hausnummer" matInput name="streetNumber"
                                [(ngModel)]="mandant.streetNumber" placeholder="Haus-Nr."
                                [ngClass]="{'invalid-field':fieldClicked && !mandant.streetNumber}"
                                (blur)="validateField('streetNumber')">
                        </mat-form-field>

                    </div>

                    <!-- zip -->
                    <div class="inline-form-fields">

                        <mat-form-field style="width: 20%;" id="postal-code">
                            <input required id="plz" matInput name="zip" [(ngModel)]="mandant.zip" placeholder="PLZ"
                                [ngClass]="{'invalid-field':fieldClicked && !mandant.zip}"
                                (blur)="validateField('zip')">
                        </mat-form-field>

                        <!-- city -->
                        <mat-form-field style="width: 34%;" id="city">
                            <input required id="stadt" matInput name="city" [(ngModel)]="mandant.city"
                                placeholder="Stadt" [ngClass]="{'invalid-field': fieldClicked &&!mandant.city}"
                                (blur)="validateField('city')">
                        </mat-form-field>

                    </div>

                    <!-- state -->
                    <mat-form-field id="state">
                        <mat-label>Bundesland auswählen</mat-label>
                        <mat-select required id="bundesland" matNativeControl name="state" [(ngModel)]="mandant.state"
                            [ngClass]="{'invalid-field': fieldClicked && !mandant.state}"
                            (blur)="validateField('state')">
                            <mat-option *ngFor="let state of states" [value]="state.name">{{ state.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div style="display: block;" class="button-hint">
                        <button id="saveMandantButton" [disabled]="!mandantForm.valid" (click)="saved(true)"
                            type="submit" mat-flat-button>ORGANISATION SPEICHERN</button>
                        <mat-hint style="margin-left: 35%;">{{saved_hint}}</mat-hint>
                    </div>

                </form>

            </section>

            <section (click)="checkrightClicked()" id="section2" style="float: left; width: 40%; opacity: 0.5;">
                <!-- create accounts -->
                <form #accCountForm="ngForm" (ngSubmit)="onSubmitAcc(accCountForm)">
                    <div class="form-group userAccountsCount" id="userAccountsCount">
                        <label>Anzahl Benutzeraccounts</label>
                        <input id="amount" [disabled]="disabled" style="width: 6.1%; padding: 10px 20px;" matInput
                            [(ngModel)]="mandant.amount" name="amount" type="number">
                    </div>
                    <button [disabled]="!accCountForm.valid" [disabled]="disabled" id="createAcc" type="submit"
                        mat-flat-button>ACCOUNTS
                        ERSTELLEN</button>
                </form>

                <p style="margin-bottom: 17%;">{{accCount}}</p><br>

                <label for="fileInputOrg" style="display: inline;">Upload Excel-Liste Organisationsstruktur</label>
                <input type="file" id="fileInputOrg" (change)="handleFileInputOrg($event)" style="display: none;">
                <button [disabled]="disabledUpload" id="upload1" mat-flat-button
                    (click)="openFileInputOrg()">UPLOAD</button>
                <mat-hint id="fileInputOrghint">{{uploaded}}</mat-hint><br>

                <label for="fileInput" style="display: inline; margin-top: 7%;">Upload Excel-Liste Informationsstruktur</label>
                <input type="file" id="fileInput" (change)="handleFileInput($event)" style="display: none;">
                <button [disabled]="disabled" id="upload2" mat-flat-button (click)="openFileInput()">UPLOAD</button>
                <mat-hint id="fileInputhint">{{uploaded}}</mat-hint><br>

            </section>
        </mat-card-content>
    </mat-card>

    <button (click)="checkFormSaved()"
        style="color: white; background-color: #6b8294; width: 17%; padding: 2%; margin-top: 2%; float: right;"
        mat-flat-button>ZUR ÜBERSICHT</button>

    <app-popup class="popup" *ngIf="show == true" (answerEvent)="answerEvent($event)" [popupText]="popupText"
        [buttonLeftText]="''" [buttonRightText]="''" [buttonCenterText]="'OK'"> </app-popup>

    <app-popup class="popup" *ngIf="showPopup == true" (buttonCenterClicked)="navToOverview()"
        (buttonRightClicked)="saveMandantAndNavToOverview()" (answerEvent)="answerEvent($event)" [popupText]="popupText"
        [buttonLeftText]="'ABBRECHEN'" [buttonRightText]="'SPEICHERN + ZUR ÜBERSICHT'"
        [buttonCenterText]="'OHNE SPEICHERN ZUR ÜBERSICHT'"> </app-popup>

</div>