import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, throwError } from "rxjs";
import { LoggedInService } from "./logged-in.service";

@Injectable()
export class HttpServerErrorInterceptor implements HttpInterceptor {
    constructor(private loggedInService: LoggedInService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.loggedInService.getAppStarted()) {
            return next.handle(request);
        }
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                /** unauthorized */
                if (error.status == 401) {
                    this.loggedInService.setError(error);
                    this.loggedInService.logout();
                }
                /** internal server error */
                else if (error.status >= 500) {
                    this.loggedInService.setError(error);
                }
                /** conflict */
                else if (error.status == 409) {
                    this.loggedInService.setError(error);
                }
                return throwError(() => error);
            })
        );
    }

}