import { Component } from '@angular/core';
import { GeneralService } from '../general.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent {

  constructor(private generalService: GeneralService) {

  }

  ngOnInit() {

    this.generalService.setTitle('Datenschutz');

  }

}