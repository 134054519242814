import { Component, Input, ViewChild, OnInit } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { AnalysisService } from "src/swagger-typescript";

// Define an interface for the series data
interface SeriesData {
  name: string;
  data: number[];
}

@Component({
  selector: 'app-stacked-column-chart',
  templateUrl: './stacked-column-chart.component.html',
  styleUrls: ['./stacked-column-chart.component.css']
})
export class StackedColumnChartComponent implements OnInit {

  @Input() client: string = '';
  @Input() mandantId: string[] = [];
  @Input() dataType: string = '';
  @Input() aggregate: string = '';
  @Input() icdCode: string[] = [];
  @Input() locationName: string[] = [];
  @Input() unitType: string[] = [];

  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions: any;

  constructor(public analysisService: AnalysisService) {
    this.chartOptions = {
      series: [],
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
        type: "category",
        categories: ['m', 'w'],
        title: {
          text: "Geschlecht"
        },
      },
      yaxis: {
        title: {
          text: "Anzahl der Patienten (n)"
        }
      },
      legend: {
        position: "right",
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    };
  }

  ngOnInit(): void {
    const filter = {
      icd_code: this.icdCode,
      location_name: this.locationName,
      unit_type: this.unitType
    };

    this.analysisService.v1AnalysisGetDataPost({
      "client": this.client,
      "mandantId": this.mandantId,
      "data_type": this.dataType,
      "aggregate": this.aggregate,
      "filter": filter
    }).pipe(
      catchError(error => {
        console.error("Error fetching data:", error);
        return of([]);
      })
    ).subscribe((targetData) => {
      this.handleDataForHistogram(targetData);
    });
  }

  handleDataForHistogram(targetData: any): void {
    // Parse JSON data
    const parsedTargetData = JSON.parse(targetData);

    // Check if parsedTargetData is in the simple format
    if (typeof parsedTargetData["m"] === 'number') {
      // Simple format with direct counts for age groups
      const categories = Object.keys(parsedTargetData);
      const data = Object.values(parsedTargetData);

      // Set the series data for the histogram
      this.chartOptions.series = [{
        name: 'Counts',
        data: data,
        color: '#6b8294',
      }];
      this.chartOptions.xaxis.categories = categories;

    } else {
      // Complex format with nested aggregate
      const categories = Object.keys(parsedTargetData);
      const seriesData: any = {};

      categories.forEach(category => {
        const genderData = parsedTargetData[category];
        Object.keys(genderData).forEach(aggregate => {
          if (!seriesData[aggregate]) {
            seriesData[aggregate] = { name: aggregate, data: new Array(categories.length).fill(0) };
          }

          // Ensure the data array matches the number of categories
          const index = categories.indexOf(category);
          seriesData[aggregate].data[index] = genderData[aggregate];
        });
      });

      // Convert seriesData object to an array for ApexCharts
      const series = Object.values(seriesData);

      // Set the categories and series data for the histogram
      this.chartOptions.series = series;
      this.chartOptions.xaxis.categories = categories;
    }

    // Update the chart to reflect new data
    if (this.chart) {
      this.chart.updateOptions(this.chartOptions);
    }
  }

}