/**
 * Sodiaph Backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { HisDocumentSectionInfoCreateRequest } from '../model/hisDocumentSectionInfoCreateRequest';
import { HisEntry } from '../model/hisEntry';
import { HisEntryCreateRequest } from '../model/hisEntryCreateRequest';
import { HisEntryUpdateRequest } from '../model/hisEntryUpdateRequest';
import { PatientArchiveCreateRequest } from '../model/patientArchiveCreateRequest';
import { PatientArchiveDTO } from '../model/patientArchiveDTO';
import { PatientArchiveUpdateRequest } from '../model/patientArchiveUpdateRequest';
import { PatientPathCreateRequest } from '../model/patientPathCreateRequest';
import { PatientPathDTO } from '../model/patientPathDTO';
import { PatientPathUpdateRequest } from '../model/patientPathUpdateRequest';
import { TreatmentUnitCreateRequest } from '../model/treatmentUnitCreateRequest';
import { TreatmentUnitDTO } from '../model/treatmentUnitDTO';
import { TreatmentUnitUpdateRequest } from '../model/treatmentUnitUpdateRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from 'src/environment/environment';


@Injectable()
export class PatientPathService {

    protected basePath = environment.apiURL;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1MandantIdPatientPathGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PatientPathDTO>>;
    public v1MandantIdPatientPathGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PatientPathDTO>>>;
    public v1MandantIdPatientPathGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PatientPathDTO>>>;
    public v1MandantIdPatientPathGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling v1MandantIdPatientPathGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PatientPathDTO>>('get',`${this.basePath}/v1/mandant/${encodeURIComponent(String(id))}/patientPath`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hisDocumentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathDefaultSectionInfoHisDocumentIdGet(hisDocumentId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<HisDocumentSectionInfoCreateRequest>>;
    public v1PatientPathDefaultSectionInfoHisDocumentIdGet(hisDocumentId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HisDocumentSectionInfoCreateRequest>>>;
    public v1PatientPathDefaultSectionInfoHisDocumentIdGet(hisDocumentId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HisDocumentSectionInfoCreateRequest>>>;
    public v1PatientPathDefaultSectionInfoHisDocumentIdGet(hisDocumentId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hisDocumentId === null || hisDocumentId === undefined) {
            throw new Error('Required parameter hisDocumentId was null or undefined when calling v1PatientPathDefaultSectionInfoHisDocumentIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<HisDocumentSectionInfoCreateRequest>>('get',`${this.basePath}/v1/patientPath/defaultSectionInfo/${encodeURIComponent(String(hisDocumentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param search 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathFilterSearchGet(search: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PatientPathDTO>>;
    public v1PatientPathFilterSearchGet(search: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PatientPathDTO>>>;
    public v1PatientPathFilterSearchGet(search: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PatientPathDTO>>>;
    public v1PatientPathFilterSearchGet(search: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (search === null || search === undefined) {
            throw new Error('Required parameter search was null or undefined when calling v1PatientPathFilterSearchGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PatientPathDTO>>('get',`${this.basePath}/v1/patientPath/filter/${encodeURIComponent(String(search))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathGet(observe?: 'body', reportProgress?: boolean): Observable<Array<PatientPathDTO>>;
    public v1PatientPathGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PatientPathDTO>>>;
    public v1PatientPathGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PatientPathDTO>>>;
    public v1PatientPathGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PatientPathDTO>>('get',`${this.basePath}/v1/patientPath`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<PatientPathDTO>;
    public v1PatientPathIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PatientPathDTO>>;
    public v1PatientPathIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PatientPathDTO>>;
    public v1PatientPathIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling v1PatientPathIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PatientPathDTO>('get',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathIdPut(body: PatientPathUpdateRequest, id: string, observe?: 'body', reportProgress?: boolean): Observable<PatientPathDTO>;
    public v1PatientPathIdPut(body: PatientPathUpdateRequest, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PatientPathDTO>>;
    public v1PatientPathIdPut(body: PatientPathUpdateRequest, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PatientPathDTO>>;
    public v1PatientPathIdPut(body: PatientPathUpdateRequest, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling v1PatientPathIdPut.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling v1PatientPathIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PatientPathDTO>('put',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param patientPathId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPatientPathIdHisEntryGet(patientPathId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<HisEntry>>;
    public v1PatientPathPatientPathIdHisEntryGet(patientPathId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HisEntry>>>;
    public v1PatientPathPatientPathIdHisEntryGet(patientPathId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HisEntry>>>;
    public v1PatientPathPatientPathIdHisEntryGet(patientPathId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (patientPathId === null || patientPathId === undefined) {
            throw new Error('Required parameter patientPathId was null or undefined when calling v1PatientPathPatientPathIdHisEntryGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<HisEntry>>('get',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(patientPathId))}/hisEntry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param patientPathId 
     * @param hisEntryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPatientPathIdHisEntryHisEntryIdDelete(patientPathId: string, hisEntryId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public v1PatientPathPatientPathIdHisEntryHisEntryIdDelete(patientPathId: string, hisEntryId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public v1PatientPathPatientPathIdHisEntryHisEntryIdDelete(patientPathId: string, hisEntryId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public v1PatientPathPatientPathIdHisEntryHisEntryIdDelete(patientPathId: string, hisEntryId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (patientPathId === null || patientPathId === undefined) {
            throw new Error('Required parameter patientPathId was null or undefined when calling v1PatientPathPatientPathIdHisEntryHisEntryIdDelete.');
        }

        if (hisEntryId === null || hisEntryId === undefined) {
            throw new Error('Required parameter hisEntryId was null or undefined when calling v1PatientPathPatientPathIdHisEntryHisEntryIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(patientPathId))}/hisEntry/${encodeURIComponent(String(hisEntryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param patientPathId 
     * @param hisEntryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPatientPathIdHisEntryHisEntryIdGet(patientPathId: string, hisEntryId: string, observe?: 'body', reportProgress?: boolean): Observable<HisEntry>;
    public v1PatientPathPatientPathIdHisEntryHisEntryIdGet(patientPathId: string, hisEntryId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HisEntry>>;
    public v1PatientPathPatientPathIdHisEntryHisEntryIdGet(patientPathId: string, hisEntryId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HisEntry>>;
    public v1PatientPathPatientPathIdHisEntryHisEntryIdGet(patientPathId: string, hisEntryId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (patientPathId === null || patientPathId === undefined) {
            throw new Error('Required parameter patientPathId was null or undefined when calling v1PatientPathPatientPathIdHisEntryHisEntryIdGet.');
        }

        if (hisEntryId === null || hisEntryId === undefined) {
            throw new Error('Required parameter hisEntryId was null or undefined when calling v1PatientPathPatientPathIdHisEntryHisEntryIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HisEntry>('get',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(patientPathId))}/hisEntry/${encodeURIComponent(String(hisEntryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param patientPathId 
     * @param hisEntryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPatientPathIdHisEntryHisEntryIdPut(body: HisEntryUpdateRequest, patientPathId: string, hisEntryId: string, observe?: 'body', reportProgress?: boolean): Observable<HisEntry>;
    public v1PatientPathPatientPathIdHisEntryHisEntryIdPut(body: HisEntryUpdateRequest, patientPathId: string, hisEntryId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HisEntry>>;
    public v1PatientPathPatientPathIdHisEntryHisEntryIdPut(body: HisEntryUpdateRequest, patientPathId: string, hisEntryId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HisEntry>>;
    public v1PatientPathPatientPathIdHisEntryHisEntryIdPut(body: HisEntryUpdateRequest, patientPathId: string, hisEntryId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling v1PatientPathPatientPathIdHisEntryHisEntryIdPut.');
        }

        if (patientPathId === null || patientPathId === undefined) {
            throw new Error('Required parameter patientPathId was null or undefined when calling v1PatientPathPatientPathIdHisEntryHisEntryIdPut.');
        }

        if (hisEntryId === null || hisEntryId === undefined) {
            throw new Error('Required parameter hisEntryId was null or undefined when calling v1PatientPathPatientPathIdHisEntryHisEntryIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HisEntry>('put',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(patientPathId))}/hisEntry/${encodeURIComponent(String(hisEntryId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param patientPathId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPatientPathIdHisEntryPost(body: HisEntryCreateRequest, patientPathId: string, observe?: 'body', reportProgress?: boolean): Observable<HisEntry>;
    public v1PatientPathPatientPathIdHisEntryPost(body: HisEntryCreateRequest, patientPathId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HisEntry>>;
    public v1PatientPathPatientPathIdHisEntryPost(body: HisEntryCreateRequest, patientPathId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HisEntry>>;
    public v1PatientPathPatientPathIdHisEntryPost(body: HisEntryCreateRequest, patientPathId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling v1PatientPathPatientPathIdHisEntryPost.');
        }

        if (patientPathId === null || patientPathId === undefined) {
            throw new Error('Required parameter patientPathId was null or undefined when calling v1PatientPathPatientPathIdHisEntryPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HisEntry>('post',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(patientPathId))}/hisEntry`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param patientPathId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPatientPathIdPatientArchiveGet(patientPathId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PatientArchiveDTO>>;
    public v1PatientPathPatientPathIdPatientArchiveGet(patientPathId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PatientArchiveDTO>>>;
    public v1PatientPathPatientPathIdPatientArchiveGet(patientPathId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PatientArchiveDTO>>>;
    public v1PatientPathPatientPathIdPatientArchiveGet(patientPathId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (patientPathId === null || patientPathId === undefined) {
            throw new Error('Required parameter patientPathId was null or undefined when calling v1PatientPathPatientPathIdPatientArchiveGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PatientArchiveDTO>>('get',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(patientPathId))}/patientArchive`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param patientPathId 
     * @param patientArchiveId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPatientPathIdPatientArchivePatientArchiveIdDelete(patientPathId: string, patientArchiveId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public v1PatientPathPatientPathIdPatientArchivePatientArchiveIdDelete(patientPathId: string, patientArchiveId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public v1PatientPathPatientPathIdPatientArchivePatientArchiveIdDelete(patientPathId: string, patientArchiveId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public v1PatientPathPatientPathIdPatientArchivePatientArchiveIdDelete(patientPathId: string, patientArchiveId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (patientPathId === null || patientPathId === undefined) {
            throw new Error('Required parameter patientPathId was null or undefined when calling v1PatientPathPatientPathIdPatientArchivePatientArchiveIdDelete.');
        }

        if (patientArchiveId === null || patientArchiveId === undefined) {
            throw new Error('Required parameter patientArchiveId was null or undefined when calling v1PatientPathPatientPathIdPatientArchivePatientArchiveIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(patientPathId))}/patientArchive/${encodeURIComponent(String(patientArchiveId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param patientPathId 
     * @param patientArchiveId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPatientPathIdPatientArchivePatientArchiveIdGet(patientPathId: string, patientArchiveId: string, observe?: 'body', reportProgress?: boolean): Observable<PatientArchiveDTO>;
    public v1PatientPathPatientPathIdPatientArchivePatientArchiveIdGet(patientPathId: string, patientArchiveId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PatientArchiveDTO>>;
    public v1PatientPathPatientPathIdPatientArchivePatientArchiveIdGet(patientPathId: string, patientArchiveId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PatientArchiveDTO>>;
    public v1PatientPathPatientPathIdPatientArchivePatientArchiveIdGet(patientPathId: string, patientArchiveId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (patientPathId === null || patientPathId === undefined) {
            throw new Error('Required parameter patientPathId was null or undefined when calling v1PatientPathPatientPathIdPatientArchivePatientArchiveIdGet.');
        }

        if (patientArchiveId === null || patientArchiveId === undefined) {
            throw new Error('Required parameter patientArchiveId was null or undefined when calling v1PatientPathPatientPathIdPatientArchivePatientArchiveIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PatientArchiveDTO>('get',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(patientPathId))}/patientArchive/${encodeURIComponent(String(patientArchiveId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param patientPathId 
     * @param patientArchiveId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPatientPathIdPatientArchivePatientArchiveIdPut(body: PatientArchiveUpdateRequest, patientPathId: string, patientArchiveId: string, observe?: 'body', reportProgress?: boolean): Observable<PatientArchiveDTO>;
    public v1PatientPathPatientPathIdPatientArchivePatientArchiveIdPut(body: PatientArchiveUpdateRequest, patientPathId: string, patientArchiveId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PatientArchiveDTO>>;
    public v1PatientPathPatientPathIdPatientArchivePatientArchiveIdPut(body: PatientArchiveUpdateRequest, patientPathId: string, patientArchiveId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PatientArchiveDTO>>;
    public v1PatientPathPatientPathIdPatientArchivePatientArchiveIdPut(body: PatientArchiveUpdateRequest, patientPathId: string, patientArchiveId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling v1PatientPathPatientPathIdPatientArchivePatientArchiveIdPut.');
        }

        if (patientPathId === null || patientPathId === undefined) {
            throw new Error('Required parameter patientPathId was null or undefined when calling v1PatientPathPatientPathIdPatientArchivePatientArchiveIdPut.');
        }

        if (patientArchiveId === null || patientArchiveId === undefined) {
            throw new Error('Required parameter patientArchiveId was null or undefined when calling v1PatientPathPatientPathIdPatientArchivePatientArchiveIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PatientArchiveDTO>('put',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(patientPathId))}/patientArchive/${encodeURIComponent(String(patientArchiveId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param patientPathId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPatientPathIdPatientArchivePost(body: PatientArchiveCreateRequest, patientPathId: string, observe?: 'body', reportProgress?: boolean): Observable<PatientArchiveDTO>;
    public v1PatientPathPatientPathIdPatientArchivePost(body: PatientArchiveCreateRequest, patientPathId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PatientArchiveDTO>>;
    public v1PatientPathPatientPathIdPatientArchivePost(body: PatientArchiveCreateRequest, patientPathId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PatientArchiveDTO>>;
    public v1PatientPathPatientPathIdPatientArchivePost(body: PatientArchiveCreateRequest, patientPathId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling v1PatientPathPatientPathIdPatientArchivePost.');
        }

        if (patientPathId === null || patientPathId === undefined) {
            throw new Error('Required parameter patientPathId was null or undefined when calling v1PatientPathPatientPathIdPatientArchivePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PatientArchiveDTO>('post',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(patientPathId))}/patientArchive`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param patientPathId 
     * @param SFID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPatientPathIdPatientArchiveSFIDSFIDGet(patientPathId: string, SFID: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PatientArchiveDTO>>;
    public v1PatientPathPatientPathIdPatientArchiveSFIDSFIDGet(patientPathId: string, SFID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PatientArchiveDTO>>>;
    public v1PatientPathPatientPathIdPatientArchiveSFIDSFIDGet(patientPathId: string, SFID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PatientArchiveDTO>>>;
    public v1PatientPathPatientPathIdPatientArchiveSFIDSFIDGet(patientPathId: string, SFID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (patientPathId === null || patientPathId === undefined) {
            throw new Error('Required parameter patientPathId was null or undefined when calling v1PatientPathPatientPathIdPatientArchiveSFIDSFIDGet.');
        }

        if (SFID === null || SFID === undefined) {
            throw new Error('Required parameter SFID was null or undefined when calling v1PatientPathPatientPathIdPatientArchiveSFIDSFIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PatientArchiveDTO>>('get',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(patientPathId))}/patientArchive/SFID/${encodeURIComponent(String(SFID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param patientPathId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPatientPathIdTreatmentUnitGet(patientPathId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TreatmentUnitDTO>>;
    public v1PatientPathPatientPathIdTreatmentUnitGet(patientPathId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TreatmentUnitDTO>>>;
    public v1PatientPathPatientPathIdTreatmentUnitGet(patientPathId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TreatmentUnitDTO>>>;
    public v1PatientPathPatientPathIdTreatmentUnitGet(patientPathId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (patientPathId === null || patientPathId === undefined) {
            throw new Error('Required parameter patientPathId was null or undefined when calling v1PatientPathPatientPathIdTreatmentUnitGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TreatmentUnitDTO>>('get',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(patientPathId))}/treatmentUnit`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param patientPathId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPatientPathIdTreatmentUnitPost(body: TreatmentUnitCreateRequest, patientPathId: string, observe?: 'body', reportProgress?: boolean): Observable<TreatmentUnitDTO>;
    public v1PatientPathPatientPathIdTreatmentUnitPost(body: TreatmentUnitCreateRequest, patientPathId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TreatmentUnitDTO>>;
    public v1PatientPathPatientPathIdTreatmentUnitPost(body: TreatmentUnitCreateRequest, patientPathId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TreatmentUnitDTO>>;
    public v1PatientPathPatientPathIdTreatmentUnitPost(body: TreatmentUnitCreateRequest, patientPathId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling v1PatientPathPatientPathIdTreatmentUnitPost.');
        }

        if (patientPathId === null || patientPathId === undefined) {
            throw new Error('Required parameter patientPathId was null or undefined when calling v1PatientPathPatientPathIdTreatmentUnitPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TreatmentUnitDTO>('post',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(patientPathId))}/treatmentUnit`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param patientPathId 
     * @param SFID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPatientPathIdTreatmentUnitSFIDSFIDDelete(patientPathId: string, SFID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public v1PatientPathPatientPathIdTreatmentUnitSFIDSFIDDelete(patientPathId: string, SFID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public v1PatientPathPatientPathIdTreatmentUnitSFIDSFIDDelete(patientPathId: string, SFID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public v1PatientPathPatientPathIdTreatmentUnitSFIDSFIDDelete(patientPathId: string, SFID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (patientPathId === null || patientPathId === undefined) {
            throw new Error('Required parameter patientPathId was null or undefined when calling v1PatientPathPatientPathIdTreatmentUnitSFIDSFIDDelete.');
        }

        if (SFID === null || SFID === undefined) {
            throw new Error('Required parameter SFID was null or undefined when calling v1PatientPathPatientPathIdTreatmentUnitSFIDSFIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(patientPathId))}/treatmentUnit/SFID/${encodeURIComponent(String(SFID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param patientPathId 
     * @param SFID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPatientPathIdTreatmentUnitSFIDSFIDGet(patientPathId: string, SFID: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TreatmentUnitDTO>>;
    public v1PatientPathPatientPathIdTreatmentUnitSFIDSFIDGet(patientPathId: string, SFID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TreatmentUnitDTO>>>;
    public v1PatientPathPatientPathIdTreatmentUnitSFIDSFIDGet(patientPathId: string, SFID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TreatmentUnitDTO>>>;
    public v1PatientPathPatientPathIdTreatmentUnitSFIDSFIDGet(patientPathId: string, SFID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (patientPathId === null || patientPathId === undefined) {
            throw new Error('Required parameter patientPathId was null or undefined when calling v1PatientPathPatientPathIdTreatmentUnitSFIDSFIDGet.');
        }

        if (SFID === null || SFID === undefined) {
            throw new Error('Required parameter SFID was null or undefined when calling v1PatientPathPatientPathIdTreatmentUnitSFIDSFIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TreatmentUnitDTO>>('get',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(patientPathId))}/treatmentUnit/SFID/${encodeURIComponent(String(SFID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param patientPathId 
     * @param treatmentUnitId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPatientPathIdTreatmentUnitTreatmentUnitIdDelete(patientPathId: string, treatmentUnitId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public v1PatientPathPatientPathIdTreatmentUnitTreatmentUnitIdDelete(patientPathId: string, treatmentUnitId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public v1PatientPathPatientPathIdTreatmentUnitTreatmentUnitIdDelete(patientPathId: string, treatmentUnitId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public v1PatientPathPatientPathIdTreatmentUnitTreatmentUnitIdDelete(patientPathId: string, treatmentUnitId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (patientPathId === null || patientPathId === undefined) {
            throw new Error('Required parameter patientPathId was null or undefined when calling v1PatientPathPatientPathIdTreatmentUnitTreatmentUnitIdDelete.');
        }

        if (treatmentUnitId === null || treatmentUnitId === undefined) {
            throw new Error('Required parameter treatmentUnitId was null or undefined when calling v1PatientPathPatientPathIdTreatmentUnitTreatmentUnitIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(patientPathId))}/treatmentUnit/${encodeURIComponent(String(treatmentUnitId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param patientPathId 
     * @param treatmentUnitId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPatientPathIdTreatmentUnitTreatmentUnitIdPut(body: TreatmentUnitUpdateRequest, patientPathId: string, treatmentUnitId: string, observe?: 'body', reportProgress?: boolean): Observable<TreatmentUnitDTO>;
    public v1PatientPathPatientPathIdTreatmentUnitTreatmentUnitIdPut(body: TreatmentUnitUpdateRequest, patientPathId: string, treatmentUnitId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TreatmentUnitDTO>>;
    public v1PatientPathPatientPathIdTreatmentUnitTreatmentUnitIdPut(body: TreatmentUnitUpdateRequest, patientPathId: string, treatmentUnitId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TreatmentUnitDTO>>;
    public v1PatientPathPatientPathIdTreatmentUnitTreatmentUnitIdPut(body: TreatmentUnitUpdateRequest, patientPathId: string, treatmentUnitId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling v1PatientPathPatientPathIdTreatmentUnitTreatmentUnitIdPut.');
        }

        if (patientPathId === null || patientPathId === undefined) {
            throw new Error('Required parameter patientPathId was null or undefined when calling v1PatientPathPatientPathIdTreatmentUnitTreatmentUnitIdPut.');
        }

        if (treatmentUnitId === null || treatmentUnitId === undefined) {
            throw new Error('Required parameter treatmentUnitId was null or undefined when calling v1PatientPathPatientPathIdTreatmentUnitTreatmentUnitIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TreatmentUnitDTO>('put',`${this.basePath}/v1/patientPath/${encodeURIComponent(String(patientPathId))}/treatmentUnit/${encodeURIComponent(String(treatmentUnitId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PatientPathPost(body: PatientPathCreateRequest, observe?: 'body', reportProgress?: boolean): Observable<PatientPathDTO>;
    public v1PatientPathPost(body: PatientPathCreateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PatientPathDTO>>;
    public v1PatientPathPost(body: PatientPathCreateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PatientPathDTO>>;
    public v1PatientPathPost(body: PatientPathCreateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling v1PatientPathPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PatientPathDTO>('post',`${this.basePath}/v1/patientPath`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
